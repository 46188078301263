import React from 'react';
import { Text, Modal, Stack, Group, Button, Title } from '@mantine/core';
import { useModalStyles } from '../../MyBooking/components/style';

const SubscriptionModal = ({ opened, close, upgrade }: any) => {
  return (
    <>
      <Modal centered closeOnClickOutside={false} closeOnEscape={false} size='md' opened={opened} onClose={close}>
        <Stack>
          <Title ta='center' mb={20}>
            Subscribe Plan
          </Title>
          <Text size={17} align='center' pt='10px'>
            Do you want to upgrade your current subscribed plan?
          </Text>

          <Group spacing={20} position='center' my='xl' mt='xl'>
            <Button
              size='md'
              w='40%'
              radius={4}
              variant='gradient'
              gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
              onClick={() => {
                close();
                upgrade();
              }}>
              Yes
            </Button>
            <Button size='md' w='40%' variant='light' radius={4} onClick={close} color='grey'>
              No
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
