import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { liveLocation } from '../services/service';
import { langLatModify } from '../utils/constants/map';
import { decodeString } from '../utils/helpers/sendFIle';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: `calc(100vh - 250px)`,
};

const GoogleMapLive = ({ decodedPath, currentLat, currentLng, bookingId }: any) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
  });
  const [pathList, setPathList] = useState<[] | any>([]);
  const [defaultZoom, setDefaultZoom] = useState<any>();
  const [map, setMap] = React.useState(null);
  const [cookie] = useCookies(['role']);

  // useEffect(() => {
  //   const newArray = decodeArrayOfObject(LatLngDecodeLine);
  //   setPathList(decodedPath);
  //   console.log(decodedPath);
  // }, [decodedPath]);

  useEffect(() => {
    if (currentLat && currentLng) {
      // console.log('api call from map');
      fetchLiveLocation();
    }
  }, [bookingId]);

  // fetch live location details using liveLocation api call.
  const fetchLiveLocation = async () => {
    const params = {
      booking_id: bookingId,
      latitude: currentLat,
      longitude: currentLng,
    };
    if (cookie?.role === '1') {
      delete params.latitude;
      delete params.longitude;
    }
    try {
      const locationData = await liveLocation(params);
      if (locationData?.data?.status === 'error') {
        toastMessageUpdate('info', locationData?.data?.message);
      }
      const decodeArray = decodeString(locationData?.data?.route, '1e5');
      setPathList(decodeArray);
    } catch (error: any) {
      console.log('location err=>', error);
      toastMessageUpdate('error', error?.data?.message || error?.data?.error);
    }
  };

  // load googlemap & render through lat & lng bounds.
  const onLoad = React.useCallback(
    function callback(map: any) {
      const bounds = new window.google.maps.LatLngBounds(pathList[0]);
      map.fitBounds(bounds);
      setMap(map);
      setTimeout(() => {
        setDefaultZoom(10);
      }, 1000);
    },
    [pathList],
  );

  const onUnmount = React.useCallback(
    function callback(map: any) {
      setMap(null);
    },
    [pathList],
  );

  return isLoaded && langLatModify().length && pathList.length > 0 ? (
    <GoogleMap mapContainerStyle={containerStyle} zoom={defaultZoom} onLoad={onLoad} onUnmount={onUnmount}>
      <Marker position={{ lat: +currentLat, lng: +currentLng }}></Marker>

      <Polyline
        options={{
          strokeColor: 'orange',
          strokeOpacity: 4.0,
          strokeWeight: 6,
          icons: [
            {
              // icon: lineSymbol,
              offset: '0',
              repeat: '20px',
            },
          ],
        }}
        path={pathList}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleMapLive;
