import { toast } from 'react-toastify';

type toastType = 'success' | 'info' | 'error' | 'warning';

export const toastMessageUpdate = (type: toastType, message?: string) => {
  toast[type](message || 'Something went wrong !', {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 4000,
  });
};
