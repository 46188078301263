import { useQuery } from '@tanstack/react-query';
import { axiosTokenInst } from './axios.config';

export type DefaultSearch = {
  type: 'default';
  service_type_id: string;
};

export type FilteredSearch = {
  type: 'filtered';
  service_type_id: string;
  data: any;
};

export type WithoutRangeSearch = {
  type: 'withoutRange';
  service_type_id: string;
};

type ProviderFilterSearchQuery = DefaultSearch | FilteredSearch | WithoutRangeSearch | undefined;

export const filterProfiles = (paramsObject: Record<string, any>) => {
  return axiosTokenInst.get('/profiles/list', { params: paramsObject });
};

export const useProviderList = (queryObject: ProviderFilterSearchQuery, enabled = false) => {
  return useQuery({
    queryKey: ['get-service-provider-by-type', queryObject],
    queryFn: async () => {
      let objectParamToSend = {} as any;
      if (queryObject) {
        switch (queryObject.type) {
          case 'default':
            objectParamToSend = {
              service_type_id: queryObject.service_type_id,
              range: '20',
            };
            break;
          case 'withoutRange':
            objectParamToSend = {
              service_type_id: queryObject.service_type_id,
            };
            break;
          case 'filtered':
            objectParamToSend = {
              service_type_id: queryObject.service_type_id,
              ...queryObject.data,
            };
            break;
        }
      } else objectParamToSend = undefined;

      return await filterProfiles(objectParamToSend);
    },
    enabled,
    staleTime: 10 * 60 * 1000,
  });
};
