import { Box, Button, Drawer, Flex, Grid, Image, Text, Title, useMantineTheme } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';
import * as Onfido from 'onfido-sdk-ui';
import { useState } from 'react';
import { verificationPlaceholder } from '../../../../../assets';
import { customerVerification, getOnfidoToken } from '../../../../../services/verification';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { useIdentityVerifyStyles } from './style';
import { useCookies } from 'react-cookie';
import { useUserProfile } from '../../../../../services/user.api';

export default function IdentityVerify() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const isTablet = useMediaQuery('(max-width: 991px)');
  const [opened, { open, close }] = useDisclosure(false);
  const [verificationLoding, setVerificationLoding] = useState<boolean>(false);
  const { data: userProfileData, refetch }: any = useUserProfile();

  const [cookie, setCookie] = useCookies(['user_Info']);

  const subMissionOnfidoVerificationForm = async (onfidoToken: string) => {
    try {
      const formUrl: any = await customerVerification({ applicant_id: onfidoToken });
      if (formUrl?.success) {
        toastMessageUpdate('info', formUrl?.message);
        await refetch();
        setCookie('user_Info', userProfileData.data.user, { path: '/' });
        close();
      }
    } catch (error: any) {
      toastMessageUpdate('info', error?.data?.message);
      close();
    }
  };

  const getVerificationForm = async () => {
    open();
    setVerificationLoding(true);
    try {
      const onfidoToken: any = await getOnfidoToken();
      if (onfidoToken?.success) {
        Onfido.init({
          token: onfidoToken?.sdk_token,
          containerId: 'onfido-mount',
          onComplete: () => subMissionOnfidoVerificationForm(onfidoToken?.applicant_id),
          steps: ['welcome', 'document', 'face', 'complete'],
          customUI: {
            colorBackgroundSurfaceModal:
              theme.colorScheme === 'light' ? (isTablet ? 'white' : '#fff7ed') : theme.colors.dark[7],
            borderWidthSurfaceModal: '0',
            colorContentTitle: theme.colorScheme === 'light' ? 'black' : '#f1f1f1',
            colorContentSubtitle: theme.colorScheme === 'light' ? 'black' : '#f1f1f1',
            colorContentBody: theme.colorScheme === 'light' ? 'black' : '#f1f1f1',
            colorBackgroundSelector: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
          },
        });
      }
      setVerificationLoding(false);
    } catch (error: any) {
      console.log(error?.data?.message);

      toastMessageUpdate('info', error?.data?.message);
      setVerificationLoding(false);
    }
  };
  const { classes: identityVerifyClasses } = useIdentityVerifyStyles();

  return (
    <>
      <Box className={identityVerifyClasses.container}>
        {!isMobile && (
          <Title order={2} c='orange.8' fw={600} mb={20}>
            Verify Identity
          </Title>
        )}
        <Grid p={0} m={0} gutter={8}>
          <Grid.Col lg={opened ? 6 : 12} md={opened ? 6 : 12}>
            <Grid gutter={0}>
              <Grid.Col lg={opened ? 12 : 6} md={opened ? 12 : 6}>
                <Image
                  h='100%'
                  fit='cover'
                  src={verificationPlaceholder}
                  styles={(theme) => ({
                    image: {
                      borderRadius: opened ? '12px 12px 0 0' : isTablet ? '12px 12px 0 0' : '12px 0 0 12px',
                      height: '100% !important',
                    },
                    figure: { height: '100%' },
                    imageWrapper: { height: '100%' },
                  })}></Image>
              </Grid.Col>
              <Grid.Col lg={opened ? 12 : 6} md={opened ? 12 : 6}>
                <Box
                  bg={theme.colorScheme === 'light' ? '#F6F6F6' : theme.colors.dark[8]}
                  h='100%'
                  px={16}
                  py={8}
                  m={0}
                  ta='center'
                  style={{
                    borderRadius: opened ? '0 0 12px 12px' : isTablet ? '0 0 12px 12px' : '0 12px 12px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                  }}>
                  <Title order={3}>Lets get you Verified for free</Title>

                  <Text fz={18} my={15}>
                    ID verification helps maintain the safety of subscribers by ensuring that individuals using a
                    service are who they claim to be. This process adds a layer of authentication, reducing the risk of
                    fraudulent activities, impersonation, and unauthorized access. It helps create a more secure
                    environment, protecting both users and the platform from potential risks and misuse.
                  </Text>

                  <Button
                    variant='gradient'
                    loading={verificationLoding}
                    loaderPosition='center'
                    disabled={
                      cookie.user_Info.onfido_result === 'clear' ||
                      cookie.user_Info.onfido_result === 'consider' ||
                      cookie.user_Info.onfido_check_id
                    }
                    w={150}
                    radius={4}
                    gradient={{ from: '#fda223', to: '#fe7720' }}
                    rightIcon={verificationLoding ? null : <IconArrowNarrowRight />}
                    onClick={getVerificationForm}>
                    {!verificationLoding && 'Verify'}
                  </Button>

                  <Box mt={16}>
                    {cookie.user_Info.onfido_result === 'clear' ? (
                      <Text c='green'>You are verified</Text>
                    ) : cookie.user_Info.onfido_result === 'consider' ? (
                      <Text c='green'>You have to contact with admin for consider</Text>
                    ) : (
                      cookie.user_Info.onfido_check_id && <Text c='red'>Your verification is pending</Text>
                    )}
                  </Box>
                </Box>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          {opened && !isTablet && (
            <Grid.Col lg={6} md={6}>
              <Box
                style={{
                  height: '100%',
                  width: '100%',
                  padding: 8,
                  border: '1px dashed #cecece',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 10,
                  backgroundColor: theme.colorScheme === 'light' ? '#fff7ed' : theme.colors.dark[7],
                }}>
                <Box id='onfido-mount'>
                  {verificationLoding && (
                    <Flex justify='center' align='center'>
                      <Text fz={24} c='dimmed'>
                        Loading ...
                      </Text>
                    </Flex>
                  )}
                </Box>
              </Box>
            </Grid.Col>
          )}
        </Grid>

        {isTablet && (
          <Drawer
            onClose={close}
            opened={opened}
            position='bottom'
            size='95%'
            padding={0}
            styles={(theme) => ({
              body: {
                height: '100%',
              },
            })}>
            <Drawer.Header style={{ position: 'relative' }}>
              <IconArrowNarrowLeft className='mobileBackButton' onClick={close} />
            </Drawer.Header>
            <Box style={{ height: '100%', width: '100%' }} mt={10}>
              <Box id='onfido-mount'>
                {verificationLoding && (
                  <Flex justify='center' align='center'>
                    <Text fz={16} c='dimmed'>
                      Loading ...
                    </Text>
                  </Flex>
                )}
              </Box>
            </Box>
          </Drawer>
        )}
      </Box>
    </>
  );
}
