import { Box, Flex, Text, TextInput } from '@mantine/core';
import CustomButton from '../../../Partner/Profile/components/CustomButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { immediateBookingSchema } from '../../../../../utils/schema/formSchema';
import { InferType } from 'yup';
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import { immediateBooking } from '../../../../../services/booking';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import ErrorText from '../../../../../components/ErrorText';
import { useUrgentBookingButtonStyles } from '../style';

interface Props {
  serviceTypeId: string;
  slotId: string;
  onClose: () => void;
}

export default function UrgentBookingRefactored({ serviceTypeId, slotId, onClose }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(immediateBookingSchema),
    defaultValues: { providers_quantity: '1' },
    mode: 'all',
  });
  const [cookies, setCookie] = useCookies(['role']);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<InferType<typeof immediateBookingSchema>> = async (data) => {
    setIsLoading(true);
    const dataToSend = new FormData();
    dataToSend.append('service_type_id', serviceTypeId);
    dataToSend.append('slot_id', slotId);
    dataToSend.append('providers_quantity', data.providers_quantity);

    try {
      await immediateBooking(dataToSend);
      toastMessageUpdate('success', 'Booking request is sent, Please wait while we connect you to service provider.');
      onClose();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      onClose();
      toastMessageUpdate('error', error?.data?.error || error?.data?.message);
    }
    setIsLoading(false);
  };

  const { classes } = useUrgentBookingButtonStyles();

  return (
    <>
      <Box>
        <Text fz={26} fw='bold' ta='center'>
          Urgent Booking
        </Text>
        <Text fz={19} pt='xs' pb='lg' fw={500} ta='center'>
          Are you sure for urgent booking?
        </Text>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          {+cookies.role === 4 && (
            <>
              <TextInput
                label='How many bookings are you looking for?'
                {...register('providers_quantity', { required: true })}
                size='md'
                mb={8}
                placeholder='Enter quantity'
              />
              <ErrorText errorProperty={errors?.providers_quantity} mt={-25} />
            </>
          )}
          <Flex justify='center' gap={4} mt={7}>
            <CustomButton variant='gradient' type='submit' loading={isLoading}>
              Confirm
            </CustomButton>
            <CustomButton variant='outline' onClick={onClose}>
              Cancel
            </CustomButton>
          </Flex>
        </form>
      </Box>
    </>
  );
}
