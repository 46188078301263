import { Button, Drawer, Grid, Group, Modal, Stack, Text, Textarea, Title, createStyles } from '@mantine/core';
import { useState } from 'react';
import { bookingCancel } from '../../../../services/booking';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { useModalStyles } from './style';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowLeft } from '@tabler/icons-react';
import { useCookies } from 'react-cookie';
import { sendNotification } from '../../../../services/websocket.api';

const useStyle = createStyles((theme) => ({
  mobileBackButton: {
    position: 'relative',
    left: '-15px',
    top: '-10px',
  },
}));

// booking cancel modal
const CancelModal = ({ opened, close, bookingId, refetch, bookingData }: any) => {
  const { classes } = useModalStyles();
  const { classes: modalStyle } = useStyle();
  const [cookie, setCookie] = useCookies(['user_Info']);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);

  const isTablet = useMediaQuery('(max-width: 767px)');

  // submit cancel functionality and API call
  const submitCancel = async () => {
    const params = {
      booking_id: bookingId,
      cancel_remarks: cancelReason?.trim(),
    };
    try {
      setCancelLoading(true);
      const cancelData = await bookingCancel(params);
      const notificationParam = {
        to_user: bookingData?.service_from,
        message: `Your service has been cancelled by ${cookie?.user_Info?.name}.`,
        type: 'service',
      };
      await sendNotification(notificationParam);
      toastMessageUpdate('success', 'Booking cancelled successfully.');
      await refetch();
      setCancelLoading(false);
      console.log('cancel-data', cancelData);
    } catch (error) {
      setCancelLoading(false);
      console.log(error);
    }
    close();
  };

  return (
    <>
      <Stack>
        <Title order={2} ta='center'>
          Cancel booking
        </Title>
        <Text size={16} align='left' fw={600} pt='10px' ta='center'>
          Are you sure you want to cancel this booking?
        </Text>

        <Textarea
          size='md'
          onChange={(event: any) => {
            setCancelReason(event.target.value);
          }}
          autosize
          minRows={1}
          maxRows={3}
          placeholder='Enter reason'
          label='Reason for cancellation'
        />
        <Grid gutter={8}>
          <Grid.Col sm={6}>
            <Button
              loading={cancelLoading}
              loaderPosition='center'
              radius={4}
              size='md'
              fullWidth
              variant='gradient'
              gradient={{ from: '#fda223', to: '#fe7720' }}
              onClick={submitCancel}>
              <Text>{cancelLoading ? null : 'Yes'}</Text>
            </Button>
          </Grid.Col>
          <Grid.Col sm={6}>
            <Button
              c='orange'
              size='md'
              fullWidth
              color='gray'
              variant='light'
              radius={4}
              onClick={close}
              sx={(theme) => ({ borderColor: '#e1e1e1' })}>
              No
            </Button>
          </Grid.Col>
        </Grid>
      </Stack>
    </>
  );
};

export default CancelModal;
