import { useQuery } from '@tanstack/react-query';
import { catchHandler } from './../utils/helpers/index';
import { axiosTokenInst } from './axios.config';

export const earningList = catchHandler(async () => {
  return await axiosTokenInst.get(`/dashboard/show`);
});

export const getTotalEarning = catchHandler(async () => {
  return await axiosTokenInst.get(`/dashboard/get-total-earning`);
});

export const useEarningList = () => {
  return useQuery({
    queryKey: ['earning-list'],
    queryFn: earningList,
  });
};

export const useGetTotalEarning = () => {
  return useQuery({
    queryKey: ['total-earning'],
    queryFn: getTotalEarning,
  });
};

export const requestWithdraw = async () => {
  return await axiosTokenInst.post(`/dashboard/request-withdrawal`);
};
