import { Tabs } from '@mantine/core';
import { useCookies } from 'react-cookie';
import { RoleType, providerRole } from '../../../../../utils';

export default function TabsList() {
  const [cookies, setCookie] = useCookies(['role', 'user_Address']);

  return (
    <>
      <Tabs.List>
        <Tabs.Tab value='overview'>Overview</Tabs.Tab>

        {RoleType.organization_service_provider !== +cookies?.role && (
          <Tabs.Tab value='identity-verify' disabled={cookies.user_Address === 'null'}>
            Verify Identity
          </Tabs.Tab>
        )}

        {RoleType.organization_service_provider === +cookies?.role && (
          <Tabs.Tab value='info' disabled={cookies.user_Address === 'null'}>
            Organization Info
          </Tabs.Tab>
        )}

        {providerRole.includes(+cookies.role) && (
          <>
            <Tabs.Tab value='services' disabled={cookies.user_Address === 'null'}>
              Services
            </Tabs.Tab>
            <Tabs.Tab value='verifications' disabled={cookies.user_Address === 'null'}>
              Verifications
            </Tabs.Tab>
          </>
        )}
      </Tabs.List>
    </>
  );
}
