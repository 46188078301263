import { Container, createStyles } from '@mantine/core';
import React from 'react';
import { Outlet } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
export default function NonAuthLayout() {
  const { classes } = useStyles();
  return (
    <Container size='xl' className={classes.wrapper}>
      <Outlet />
    </Container>
  );
}
