import { useDisclosure } from '@mantine/hooks';
import { createContext, useEffect, useState } from 'react';

export const useVideoInfo = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [startVideo, setStartVideo] = useState<any>(false);
  const [chatUser, setChatUser] = useState<any>('');
  const [trackStatus, setTrackStatus] = useState<any>();

  return {
    opened,
    open,
    close,
    startVideo,
    setStartVideo,
    chatUser,
    setChatUser,
    trackStatus,
    setTrackStatus,
  };
};

export const VideoContext = createContext({} as ReturnType<typeof useVideoInfo>);
