import { Group, Text, useMantineTheme, rem, Box, Flex } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';

interface IFileDropZone extends DropzoneProps {
  title: string;
  filename: any;
}

//File drag and drop feature.
export default function FileDropzone(props: Partial<IFileDropZone>) {
  const theme = useMantineTheme();

  return (
    <>
      <Dropzone
        onDrop={(files) => files}
        onReject={(files) => files}
        maxSize={15 * 1024 ** 2}
        multiple={false}
        mih='175px'
        w='100%'
        h='100%'
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        accept={IMAGE_MIME_TYPE}
        {...props}>
        <Group position='center' spacing='xl' style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size='3.2rem'
              stroke={1.5}
              color={theme.colors['green'][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size='3.2rem' stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size='3.2rem' stroke={1.5} />
          </Dropzone.Idle>

          <Box ta='center'>
            <Text mb='xs' inline>
              {props.title}
            </Text>
            <Text color='dimmed' size={14}>
              Drag images here or click to select file.
            </Text>
            {props.filename ? (
              <Text color='dark' size={15}>
                {props.filename}
              </Text>
            ) : (
              <Text size={12} color='dimmed' inline mt={7}>
                Attach a single file as you like, it should not exceed 15MB.
              </Text>
            )}
          </Box>
        </Group>
      </Dropzone>
    </>
  );
}
