import { Flex, Text } from '@mantine/core';
import renderVerificationStatusText from '../../../../../utils/helpers/renderVerificationStatusText';
import { useCookies } from 'react-cookie';

interface Props {
  data: {
    user_verification: boolean;
    background_verification: boolean;
    drug_test_verification: boolean;
    business_license_verification: any;
    type: any;
  };
}

export default function SubmitBusinessLicense({ data }: Props) {
  const [cookies] = useCookies(['role']);

  return (
    <>
      {data ? (
        <>
          <Flex direction='column' style={{ justifyContent: 'center' }}>
            <Text display='flex' ta='center' style={{ justifyContent: 'center', flexDirection: 'column' }} fz={17}>
              Submitted business license for verification
            </Text>
            <Text ta='center' size='sm' mt='md' weight={600} fz={15}>
              Status :{' '}
              <Text weight={600} fz={15} span c={data.business_license_verification ? 'green' : 'red'}>
                {renderVerificationStatusText({ data: data, role: cookies.role }).licenseVerification}
              </Text>
            </Text>
          </Flex>
        </>
      ) : null}
    </>
  );
}
