import { createStyles, rem } from '@mantine/core';

export const useRowElStyles = createStyles((theme) => ({
  buttonContainerForEditAndCreate: {
    height: rem(28),
    minHeight: rem(28),
    width: rem(28),
    minWidth: rem(28),
    backgroundColor: '#fe7720',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: rem(8),
  },

  buttonContainerForDelete: {
    height: rem(28),
    minHeight: rem(28),
    width: rem(28),
    minWidth: rem(28),
    backgroundColor: '#ececec',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: rem(8),
  },

  buttonFlexContainer: {
    display: 'flex',
    gap: '0.625rem',
    flexDirection: 'row',
  },

  doNotShowTheButtonsForParentService: {
    display: 'none',
  },
}));
