import { Cookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

export default function NonAuthGuard({ children }: { children: JSX.Element }) {
  const cookie = new Cookies();
  const access_token = cookie.get('access_token');

  // If find access token then redirect to home page.
  if (access_token) {
    return <Navigate to='/' replace />;
  }

  return children;
}
