import { Card, Flex, Grid, Text, Image } from '@mantine/core';
import { useVerificationInfoStyle } from './style';
import { verificationImage } from '../../../../../assets';

export default function VerificationInfo() {
  const { classes } = useVerificationInfoStyle();
  return (
    <>
      <Card className={classes.container}>
        <Grid m={0}>
          <Grid.Col lg={7} md={7} sm={7} xs={12} className={classes.textGridColumn}>
            <Flex direction='column' justify='center' h={'100%'}>
              <Text mt={10} fz={18} lh={1.5} className={classes.verificationHeaderText}>
                Conducting background checks on service providers is essential for online platforms to maintain a secure
                and trustworthy service provider base.
              </Text>

              <Text mt={10} fz={18} lh={1.5} className={classes.verificationHeaderText}>
                By verifying information, including identity and potentially relevant background details, platforms can
                enhance overall user safety and prevent fraudulent activities.
              </Text>
              <Text mt={10} fz={18} lh={1.5} className={classes.verificationHeaderText}>
                Implementing robust background checks ensures a more secure online community and fostering a positive
                user experience.
              </Text>
              <Text mt={10} fz={18} lh={1.5} className={classes.verificationHeaderText}>
                The general understanding is that passing a background check positively impacts a candidate&apos;s
                chances of being hired. A clean background check can enhance the candidate&apos;s credibility and
                trustworthiness in the eyes of the person hiring them.
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col lg={5} md={5} sm={5} xs={12} className={classes.imageGridColumn}>
            <Flex className={classes.imageFlexContainer}>
              <Image src={verificationImage} maw={400}></Image>
            </Flex>
          </Grid.Col>
        </Grid>
      </Card>
    </>
  );
}

// export {};
