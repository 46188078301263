import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Space, TextInput, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InferType } from 'yup';
import ErrorText from '../../../../../components/ErrorText';
import { updateUserProfile } from '../../../../../services/user.api';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { providerProfileUpdateNameSchema } from '../../../../../utils/schema/formSchema';
import CustomButton from '../../../Partner/Profile/components/CustomButton';

interface Props {
  close: () => void;
  updateUserName: (updatedData: any) => void;
}

export default function UpdateNameRefactored({ updateUserName, close }: Props) {
  const [cookies, setCookie] = useCookies(['user_Info']);
  const [nameLoading, setNameLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(providerProfileUpdateNameSchema),
    mode: 'all',
  });

  useEffect(() => {
    reset({
      firstName: cookies?.user_Info?.name.split(' ')[0] || '',
      lastName: cookies?.user_Info?.name.split(' ')[1] || '',
    });
  }, [cookies?.user_Info]);

  const onSubmit: SubmitHandler<InferType<typeof providerProfileUpdateNameSchema>> = async (data) => {
    setNameLoading(true);
    try {
      const profileParams = {
        name: data.firstName.trim().concat(' ', data.lastName.trim()),
      };

      const updateNameData = await updateUserProfile(profileParams);
      updateUserName(updateNameData?.data.name);
      setCookie('user_Info', updateNameData?.data, { path: '/' });
      toastMessageUpdate('success', 'User name updated successfully.');
      setNameLoading(false);
      close();
    } catch (error: any) {
      setNameLoading(false);
      close();
      toastMessageUpdate('error', error?.data?.message || error?.message);
    }
  };

  return (
    <>
      <Box>
        <Title order={3} ta='center' mb={16}>
          Update name
        </Title>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <TextInput
            placeholder='Enter your first name'
            label='First Name'
            size='md'
            spellCheck={false}
            {...register('firstName')}
          />
          <ErrorText errorProperty={errors.firstName} />
          <Space h='sm' />
          <TextInput
            placeholder='Enter your last name'
            label='Last Name'
            size='md'
            spellCheck={false}
            {...register('lastName')}
          />
          <ErrorText errorProperty={errors.lastName} />
          <Flex gap={8} mt={20} justify='space-evenly'>
            <CustomButton variant='gradient' loading={nameLoading} type='submit'>
              Save
            </CustomButton>
            <CustomButton variant='outline' disabled={nameLoading} onClick={close}>
              Cancel
            </CustomButton>
          </Flex>
        </form>
      </Box>
    </>
  );
}
