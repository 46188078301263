import { Avatar, Flex, Text, createStyles, rem } from '@mantine/core';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { profileImageFile } from '../../../assets';

const useMobileProfileHeaderStyles = createStyles((theme) => ({
  header: {
    display: 'inherit',
    margin: `${rem(36)} ${rem(16)} ${rem(24)}`,
    [theme.fn.largerThan(768)]: {
      display: 'none',
    },
  },
}));

export default function MobileProfileHeader() {
  const [cookies] = useCookies(['user_Info', 'user_Overview', 'role']);
  const { classes } = useMobileProfileHeaderStyles();
  const navigate = useNavigate();
  return (
    <>
      <Flex justify='space-between' className={classes.header}>
        <Flex gap={8}>
          <Avatar
            src={cookies.user_Overview.image || profileImageFile}
            size={50}
            radius={100}
            onClick={() => navigate('/my-profile')}></Avatar>
          <Flex direction='column'>
            <Text c='dimmed'>Hello</Text>
            <Text>{cookies.user_Info.name}</Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
