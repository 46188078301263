import { useQuery } from '@tanstack/react-query';
import { catchHandler } from '../utils/helpers';
import { axiosTokenInst } from './axios.config';

export const addFavouriteProvider = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`/favourite/add`, formData);
});

export const deletegetFavouriteProvider = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`/favourite/remove`, formData);
});

export const getFavouriteProviderDetails = catchHandler(async () => {
  return await axiosTokenInst.get(`/favourite/list`);
});

export const useFavouriteList = () => {
  return useQuery({
    queryKey: ['fav-list'],
    queryFn: getFavouriteProviderDetails,
  });
};
