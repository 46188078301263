import { Avatar, Box, FileButton, LoadingOverlay, Tooltip, UnstyledButton, rem } from '@mantine/core';
import { IconCloudUpload, IconEdit } from '@tabler/icons-react';
import type { RefObject } from 'react';
import { UserImageType } from '../utils/types/ImageType';

type CommonProps = {
  src: string;
  showGradientBackground?: boolean;
  showVerificationBorder?: boolean;
  sizes: {
    xl?: number;
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
  };
};

type RenderWithoutEditProps = CommonProps & {
  type: 'renderWithoutEdit';
};

type RenderWithEditProps = CommonProps & {
  type: 'renderWithEdit';
  loading: boolean;
  profilePhoto: File | null;
  setProfilePhoto: (file: File | null, type: UserImageType) => void;
  uploadProfilePhoto: () => void;
  resetRef: RefObject<() => void>;
};

type Props = RenderWithEditProps | RenderWithoutEditProps;

export default function ProfilePhoto(props: Props) {
  return (
    <>
      <Box
        style={{
          position: 'relative',
        }}>
        {props.type === 'renderWithEdit' ? (
          <LoadingOverlay
            visible={props.loading ?? false}
            m={props.showGradientBackground && props.showVerificationBorder ? 8 : props.showGradientBackground ? 4 : 0}
            radius='50%'
            zIndex={1}
          />
        ) : null}
        <Avatar
          src={props.src}
          radius='50%'
          styles={(theme) => ({
            root: {
              border: props.showVerificationBorder ? `${rem(4)} solid #00cb00` : 'none',
              background: props.showGradientBackground ? theme.fn.linearGradient(180, '#fda223', '#fe7720') : 'none',
              padding: props.showGradientBackground ? rem(4) : 0,
              width: rem(props?.sizes?.xl ?? 100),
              minWidth: rem(props?.sizes?.xl ?? 100),
              height: rem(props?.sizes?.xl ?? 100),

              [theme.fn.smallerThan('xl')]: {
                width: rem(props?.sizes?.lg ?? 90),
                minWidth: rem(props?.sizes?.lg ?? 90),
                height: rem(props?.sizes?.lg ?? 90),
              },

              [theme.fn.smallerThan('lg')]: {
                width: rem(props?.sizes?.md ?? 80),
                minWidth: rem(props?.sizes?.md ?? 80),
                height: rem(props?.sizes?.md ?? 80),
              },

              [theme.fn.smallerThan('md')]: {
                width: rem(props?.sizes?.sm ?? 70),
                minWidth: rem(props?.sizes?.sm ?? 70),
                height: rem(props?.sizes?.sm ?? 70),
              },

              [theme.fn.smallerThan('sm')]: {
                width: rem(props?.sizes?.xs ?? 60),
                minWidth: rem(props?.sizes?.xs ?? 60),
                height: rem(props?.sizes?.xs ?? 60),
              },
            },

            image: {
              borderRadius: '50%',
            },
          })}
        />

        {props.type === 'renderWithEdit' && (
          <FileButton
            onChange={(f) => props.setProfilePhoto(f, 'profile_image')}
            accept='image/png,image/jpeg'
            resetRef={props.resetRef}>
            {(properties) => (
              <UnstyledButton
                disabled={props.loading}
                sx={(theme) => ({
                  position: 'absolute',
                  bottom: `calc(${rem(props.sizes?.xl)} * 0.075)`,
                  right: `calc(${rem(props.sizes?.xl)} * 0.075)`,
                  border: `${rem(2)} solid ${theme.colorScheme === 'light' ? '#e8e8e8' : '#cdcdcd'}`,
                  backgroundColor: theme.colorScheme === 'light' ? '#fe7720' : '#0c0d21',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  padding: rem(2),
                  zIndex: 2,

                  [theme.fn.smallerThan('xl')]: {
                    bottom: `calc(${rem(props.sizes?.lg)} * 0.075)`,
                    right: `calc(${rem(props.sizes?.lg)} * 0.075)`,
                  },

                  [theme.fn.smallerThan('lg')]: {
                    bottom: `calc(${rem(props.sizes?.md)} * 0.075)`,
                    right: `calc(${rem(props.sizes?.md)} * 0.075)`,
                  },

                  [theme.fn.smallerThan('md')]: {
                    bottom: `calc(${rem(props.sizes?.sm)} * 0.075)`,
                    right: `calc(${rem(props.sizes?.sm)} * 0.075)`,
                  },

                  [theme.fn.smallerThan('sm')]: {
                    bottom: `calc(${rem(props.sizes?.xs)} * 0.075)`,
                    right: `calc(${rem(props.sizes?.xs)} * 0.075)`,
                  },

                  '&:disabled': { pointerEvents: 'all', cursor: 'not-allowed' },

                  svg: {
                    height: `calc(${rem(props.sizes?.xl)} * 0.15)`,
                    width: `calc(${rem(props.sizes?.xl)} * 0.15)`,
                    minWidth: `calc(${rem(props.sizes?.xl)} * 0.15)`,
                    minHeight: `calc(${rem(props.sizes?.xl)} * 0.15)`,
                    stroke: theme.colorScheme === 'light' ? '#e8e8e8' : '#ced4da',

                    [theme.fn.smallerThan('xl')]: {
                      height: `calc(${rem(props.sizes?.lg)} * 0.15)`,
                      width: `calc(${rem(props.sizes?.lg)} * 0.15)`,
                      minWidth: `calc(${rem(props.sizes?.lg)} * 0.15)`,
                      minHeight: `calc(${rem(props.sizes?.lg)} * 0.15)`,
                    },

                    [theme.fn.smallerThan('lg')]: {
                      height: `calc(${rem(props.sizes?.md)} * 0.15)`,
                      width: `calc(${rem(props.sizes?.md)} * 0.15)`,
                      minWidth: `calc(${rem(props.sizes?.md)} * 0.15)`,
                      minHeight: `calc(${rem(props.sizes?.md)} * 0.15)`,
                    },

                    [theme.fn.smallerThan('md')]: {
                      height: `calc(${rem(props.sizes?.sm)} * 0.15)`,
                      width: `calc(${rem(props.sizes?.sm)} * 0.15)`,
                      minWidth: `calc(${rem(props.sizes?.sm)} * 0.15)`,
                      minHeight: `calc(${rem(props.sizes?.sm)} * 0.15)`,
                    },

                    [theme.fn.smallerThan('sm')]: {
                      height: `calc(${rem(props.sizes?.xs)} * 0.15)`,
                      width: `calc(${rem(props.sizes?.xs)} * 0.15)`,
                      minWidth: `calc(${rem(props.sizes?.xs)} * 0.15)`,
                      minHeight: `calc(${rem(props.sizes?.xs)} * 0.15)`,
                    },
                  },
                })}>
                {props.profilePhoto ? (
                  <>
                    {/* <Tooltip label={'Disabled when uploading'} position='right' withArrow disabled={!props.loading}> */}
                    <IconCloudUpload onClick={props.uploadProfilePhoto} />
                    {/* </Tooltip> */}
                  </>
                ) : (
                  <IconEdit {...properties} />
                )}
              </UnstyledButton>
            )}
          </FileButton>
        )}
      </Box>
    </>
  );
}
