import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconChevronDown, IconTrash } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useForm } from 'react-hook-form';
import { updateUserProfile, userProfile } from '../../../../../services/user.api';
import { createUserOverview, updateUserOverview, userOverview } from '../../../../../services/userProfiles';
import { CurrencyType } from '../../../../../utils/constants/currencyType';
import { AppEvents } from '../../../../../utils/helpers/sendFIle';
import { orgProfileOverviewSchema, profileOverviewSchema } from '../../../../../utils/schema/formSchema';
import ModalOrDrawer from '../../../customer/Profile/components/ModalOrDrawer';
import Address from './Address';
import CustomButton from './CustomButton';
import DeleteMyAccount from './DeleteMyAccount';
import { useGridColStyle } from './style';
import onlyAllowNumbersInput from '../../../../../utils/helpers/onlyAllowNumbersInput';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';

export default function ProviderOverview() {
  const [cookies, setCookie] = useCookies(['user_Info', 'user_Overview', 'role', 'subscription_status']);
  const [overviewAdd, setOverviewAdd] = useState(false);
  const [profileImage, setProfileImage] = useState<any>();
  const [coverImage, setCoverImage] = useState('');
  const [userProfileData, setUserProfileData] = useState<any>();
  const [enableAddressComponent, setEnableAddressComponent] = useState(false);
  const [showProfileUpdate, setShowProfileUpdate] = useState<any>(false);
  const [checkBackground, setCheckBackground] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { backgroundCheckVerify: true },
    resolver: yupResolver(+cookies?.role !== 3 ? profileOverviewSchema : orgProfileOverviewSchema),
    mode: 'all',
  });

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const { classes: GridColStyle } = useGridColStyle();

  useEffect(() => {
    fetchProfileOverview();
    getUserProfile();
    reset({
      userEmail: cookies.user_Info?.email,
      phoneNumber: cookies.user_Info?.mobile,
    });
  }, []);

  useMemo(() => {
    //init app events only once
    AppEvents.subscribe('uploadProfileImage', (params: any) => {
      console.log('profile from event', params);
      setProfileImage(params);
    });
    AppEvents.subscribe('uploadCoverImage', (params: any) => {
      console.log('cover from event', params);
      setCoverImage(params);
    });
  }, []);

  const getUserProfile = async () => {
    try {
      const userData = await userProfile();
      setCookie('user_Overview', userData?.data.user_profile, { path: '/' });
      if (userData?.data.user_profile) {
        setShowProfileUpdate(true);
        // setShowAddress(true);
        setEnableAddressComponent(true);
      } else {
        setShowProfileUpdate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfileOverview = async () => {
    console.log(cookies.user_Info);
    const profileNeedsToBe: 'created' | 'updated' = cookies.user_Overview === 'null' ? 'created' : 'updated';
    console.log('the profile needs to be: ', profileNeedsToBe);

    await userOverview(cookies?.user_Info?.id)
      .then((res: any) => {
        console.log(res);

        if (res?.success) {
          setUserProfileData(res?.data?.user_profile);
          setOverviewAdd(false);
          reset({
            profileSummery: res.data?.user_profile?.intro,
            currency: res.data?.user_profile?.currency || CurrencyType[0].value,
            gender: res.data?.user_profile?.gender,
            experience: res.data?.user_profile?.experience,
            phoneNumber: res.data?.user?.mobile,
          });
        } else {
          //user overview create api call.
          setOverviewAdd(true);
          console.log('no overview');
          reset({
            userEmail: cookies.user_Info?.email,
            phoneNumber: cookies.user_Info?.mobile,
            currency: CurrencyType[0].value,
          });
        }
      })
      .catch((err) => {
        console.log('call err', err);
        if (err?.status === 404) {
          setOverviewAdd(true);
          // If profile not found then it returns 404 error, then we set currency as a dollar.
          reset({
            currency: CurrencyType[0].value,
          });
        }
      });
  };

  const [duplicateMobile, setDuplicateMobile] = useState<string>('');

  const profileOverviewSubmit = async (data: any) => {
    const profileImageFormData = new FormData();
    profileImageFormData.append('myImage', profileImage);
    setLoading(true);
    const profileData = {
      intro: data.profileSummery.trim(),
      gender: data.gender || '',
      currency: data.currency || '',
      image: profileImageFormData,
      cover_image: coverImage,
      experience: data.experience,
      mobile: data.phoneNumber,
    };

    if (+cookies?.role === 3) {
      delete profileData.gender;
    }
    const profileFormData = new FormData();
    for (const [key, value] of Object.entries(profileData)) {
      profileFormData.append(key, value);
    }
    // if (cookies.user_Info?.mobile !== data.phoneNumber) {
    //   const mobileParams = {
    //     mobile: data.phoneNumber,
    //   };
    //   mobileUpdate(mobileParams);
    // }
    if (overviewAdd) {
      try {
        const userOverview = await createUserOverview(profileFormData);
        setUserProfileData(userOverview);
        setCookie('user_Overview', userOverview, { path: '/' });
        // setShowAddress(true);
        setOverviewAdd(false);
        setEnableAddressComponent(true);
        setShowProfileUpdate(true);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      try {
        const updatedProfile = await updateUserOverview(profileFormData);
        if (updatedProfile.status === 422) {
          // setDuplicateMobile(updatedProfile?.data?.message);
          setLoading(false);
          return;
        }
        setCookie('user_Overview', updatedProfile?.user_profile, { path: '/' });
        setUserProfileData(updatedProfile?.user_profile);
        // setShowAddress(true);
        setEnableAddressComponent(true);
        setShowProfileUpdate(true);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const mobileUpdate = async (params: any) => {
    try {
      const updateMobileData = await updateUserProfile(params);
      setCookie('user_Info', updateMobileData?.data, { path: '/' });
    } catch (error) {
      console.log(error);
    }
  };

  const resetData = () => {
    reset({
      profileSummery: userProfileData?.intro || '',
      currency: userProfileData?.currency || CurrencyType[0].value,
      gender: userProfileData?.gender || '',
      phoneNumber: cookies.user_Info?.mobile,
      experience: cookies.user_Overview?.experience,
    });
    setShowProfileUpdate(true);
    // setDuplicateMobile('');
  };

  const backGroundCheckData = (event: any) => {
    setCheckBackground(event.target.checked);
  };

  const [deleteAccountModalIsOpened, { open: openDeleteAccount, close: closeDeleteAccount }] = useDisclosure(false);
  return (
    <>
      {!isMobile && (
        <Title order={2} c='orange.8' fw={600} mb={20}>
          Overview
        </Title>
      )}
      <Stack pb={20}>
        <form onSubmit={handleSubmit(profileOverviewSubmit)} autoComplete='off'>
          <Grid gutter={8}>
            {/* {overviewAdd && (
              <Grid.Col>
                <Checkbox
                  {...register('backgroundCheckVerify')}
                  label='By clicking this your data will be sent to Background Check.'
                  mt='5px'
                  checked={checkBackground}
                  onChange={(value: any) => {
                    backGroundCheckData(value);
                  }}
                  radius='sm'
                  size='md'
                />
              </Grid.Col>
            )} */}
            <Grid.Col xs={6}>
              <Textarea
                spellCheck={false}
                autosize
                {...register('profileSummery')}
                placeholder='Enter profile summary'
                label='Profile summary'
                disabled={showProfileUpdate}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                size='md'
                {...register('userEmail', { required: true })}
                placeholder='joe@mail.co'
                label='Email address'
                disabled
                withAsterisk
              />
              {errors?.userEmail && (
                <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                  {errors?.userEmail?.message}
                </Text>
              )}
            </Grid.Col>
            {+cookies?.role !== 3 && (
              <Grid.Col xs={6}>
                <Controller
                  name='gender'
                  control={control}
                  render={({ field }) => (
                    <Select
                      label='Gender'
                      size='md'
                      {...field}
                      disabled={showProfileUpdate}
                      rightSection={<IconChevronDown size='1rem' />}
                      placeholder='Pick one'
                      rightSectionWidth={30}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      data={['Male', 'Female', 'Others']}
                      withAsterisk
                    />
                  )}
                />
                {errors?.gender && (
                  <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                    {errors?.gender?.message}
                  </Text>
                )}
              </Grid.Col>
            )}
            <Grid.Col xs={6}>
              <TextInput
                type='number'
                onKeyDown={(e) => {
                  ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                }}
                size='md'
                {...register('phoneNumber', { required: true })}
                placeholder='Your number'
                label='Contact Number'
                withAsterisk
                disabled={showProfileUpdate}
              />
              {/* {!errors?.phoneNumber && duplicateMobile !== '' && (
                <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                  {duplicateMobile}
                </Text>
              )} */}
              {errors?.phoneNumber && (
                <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                  {errors?.phoneNumber?.message}
                </Text>
              )}
            </Grid.Col>
            <Grid.Col xs={6}>
              <Controller
                name='currency'
                control={control}
                render={({ field }) => (
                  <Select
                    label='Currency'
                    size='md'
                    {...field}
                    // disabled={showProfileUpdate}
                    disabled
                    placeholder='Select Currency'
                    rightSection={<IconChevronDown size='1rem' />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={CurrencyType}
                    withAsterisk
                  />
                )}
              />
              {errors?.currency && (
                <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                  {errors?.currency?.message}
                </Text>
              )}
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                size='md'
                {...register('experience', { required: true })}
                disabled={showProfileUpdate}
                type='number'
                onKeyDown={onlyAllowNumbersInput}
                placeholder='Experience in years.'
                label='Experience'
              />
              <Text fz='sm' c='dimmed' mt={3}>
                <span style={{ color: 'red' }}>*</span> Experience in years.
              </Text>
              {errors?.experience && (
                <Text fz={14} pt={8} pl={5} fw={500} color='red'>
                  {errors?.experience?.message}
                </Text>
              )}
            </Grid.Col>
            <Grid.Col>
              <Grid gutter={8}>
                {showProfileUpdate ? (
                  <>
                    <Grid.Col lg={1.5} md={1.5} sm={1.5} xs={3} className={GridColStyle.gridCol}>
                      <Button
                        size='sm'
                        radius={4}
                        fullWidth
                        onClick={(e) => {
                          e.preventDefault();
                          setShowProfileUpdate(false);
                        }}
                        variant='gradient'
                        gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
                        Edit
                      </Button>
                    </Grid.Col>
                  </>
                ) : (
                  <>
                    <Grid.Col lg={1.5} md={1.5} sm={1.5} xs={3} className={GridColStyle.gridCol}>
                      <CustomButton variant='gradient' type='submit' loading={loading}>
                        Save
                      </CustomButton>
                    </Grid.Col>
                    {cookies.user_Overview !== 'null' && (
                      <Grid.Col lg={1.5} md={1.5} sm={1.5} xs={3} className={GridColStyle.gridCol}>
                        <CustomButton variant='outline' disabled={loading} onClick={resetData}>
                          Cancel
                        </CustomButton>
                      </Grid.Col>
                    )}
                  </>
                )}
              </Grid>
            </Grid.Col>
          </Grid>
        </form>
      </Stack>

      <Address enable={enableAddressComponent} />

      <Flex justify='flex-end' mt={12}>
        <Button variant='outline' c='red' compact onClick={openDeleteAccount} radius={4}>
          <Group spacing={4}>
            <Text>Delete my account</Text>
            <IconTrash size={18} />
          </Group>
        </Button>
      </Flex>

      <ModalOrDrawer
        opened={deleteAccountModalIsOpened}
        onClose={closeDeleteAccount}
        closeOnClickOutside={true}
        drawerProps={{ dz: '250', drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <DeleteMyAccount closeDeleteAccount={closeDeleteAccount} />
      </ModalOrDrawer>
    </>
  );
}
