import { catchHandler } from './../utils/helpers/index';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';
import { axiosTokenInst } from './axios.config';

export const addAddress = async (addressData: any) => {
  return await axiosTokenInst
    .post(`/addresses`, addressData)
    .then((response: any) => {
      toastMessageUpdate('success', response?.message);
      return response;
    })
    .catch((error: Error) => {
      console.log('api err', error);
      toastMessageUpdate('error', error?.message);
      return error;
    });
};

export const getAddress = catchHandler(async (addressId: any) => {
  return await axiosTokenInst.get(`/addresses/${addressId}`);
});

export const updateAddress = async (addressId: any, addressData: any) => {
  return await axiosTokenInst
    .put(`/addresses/${addressId}`, addressData)
    .then((response: any) => {
      toastMessageUpdate('success', response?.message);
      return response;
    })
    .catch((error: Error) => {
      console.log('api err', error);
      toastMessageUpdate('error', error?.message);
      return error;
    });
};
