import { Cookies, useCookies } from 'react-cookie';
import { Navigate, useLocation } from 'react-router-dom';

export default function AuthGuard({ children }: { children: JSX.Element }) {
  // const cookie = new Cookies();
  const [cookie] = useCookies(['access_token', 'subscription_status']);
  const location = useLocation();
  const access_token = cookie.access_token;

  // If access token was not found, then redirect to login page.(unauthenticated user)
  if (!access_token) {
    return <Navigate to='/login' replace state={{ path: location.pathname }} />;
  }
  return children;
}
