import { Button } from '@mantine/core';
import { useFilterButtonStyles } from '../style';
import { IconFilter } from '@tabler/icons-react';

interface Props {
  showFilter: boolean;
  onClick: () => void;
}

export default function FilterButton({ showFilter, onClick }: Props) {
  const { classes, cx } = useFilterButtonStyles();
  return (
    <>
      <Button
        variant='default'
        className={cx(classes.button, { [classes.selectedButton]: showFilter })}
        onClick={onClick}
        leftIcon={<IconFilter />}>
        Filter
      </Button>
    </>
  );
}
