import { catchHandler } from './../utils/helpers/index';
import { axiosTokenInst } from './axios.config';

export const orgStatus = catchHandler(async (user_id: any) => {
  return await axiosTokenInst.get(`organization/status`, {
    params: {
      user_id,
    },
  });
});

export const createOrg = async (formData: Record<string, any>) => {
  try {
    return await axiosTokenInst.post(`/organization/create`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createSubscription = async (formData: Record<string, any>) => {
  try {
    return await axiosTokenInst.post(`/organization/subscription`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateOrg = async (formData: Record<string, any>) => {
  try {
    return await axiosTokenInst.post(`/organization/update`, formData);
  } catch (error) {
    return Promise.reject(error);
  }
};
