import { createStyles, Global } from '@mantine/core';

export default function GlobalTheme() {
  return (
    <Global
      styles={(theme) => ({
        '*, *::before, *::after': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },
        '.heading-font': {
          fontFamily: 'Playfair Display, sans-serif',
        },
        '.borderColored': {
          // border: `5px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[3]}`,
        },
        '.verifiedBorderColored': {
          border: `5px solid #88e388 `,
        },
        '.agora_video_player': {
          position: 'relative!important' as any,
          width: '445px',
          height: '360px',
        },
        '.avatar_card_visible': {
          boxShadow: `-10px 0px 10px rgba(153, 153, 153, 0.1)`,
          height: '100%',
          display: `flex`,
          alignContent: `flex-start`,
          flexDirection: `column`,
          flexWrap: `wrap`,
          '@keyframes moveInRight': {
            '0%': { opacity: 1, transform: 'translateX(100%)' },
          },
          animation: 'moveInRight',
          animationDuration: '0.3s',
          animationTimingFunction: 'ease-in',
        },
        '.avatar_card_container': {
          height: '100%',
          '@media only screen and (max-width: 750px)': {
            animation: 'none',
            maxWidth: '100%',
            boxShadow: 'none',
          },
        },

        '.mobileBackButton': {
          position: 'relative',
          left: '10px',
          top: '10px',
          margin: '5px 0 5px 0',
        },
      })}
    />
  );
}

export const useToastContainerStyles = createStyles((theme) => ({
  toastContainer: {
    width: '40% !important',
  },
  mobiletoastContainer: {
    width: '100% !important',
  },
}));
