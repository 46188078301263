import {
  ActionIcon,
  Box,
  Button,
  Drawer,
  Flex,
  Grid,
  Group,
  Paper,
  ScrollArea,
  Skeleton,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconArrowLeft, IconArrowRight, IconBorderAll, IconFilter, IconList } from '@tabler/icons-react';
import { stagger, useAnimate } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useServiceProviderList } from '../../../../services/service_types.api';
import { AvatarCom } from '../../Home/components/ProviderCard';
import ModalOrDrawer from '../Profile/components/ModalOrDrawer';
import { GridCard } from './components/GridCard';
import SearchFilter from './components/SearchFilter';
import UrgentBooking from './components/UrgentBooking';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { useSubscriptionLimitCheck } from '../../../../services/subscription.api';
import { ThemeContext } from '../../../../context';
import ListCardRefactored from './components/ListCardRefactored';

export default function FreelancerList({ setShowContent }: any) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(min-width: 768px)');
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [opened, { open, close }] = useDisclosure(false);
  const { serviceId } = useParams();
  const [urlParams] = useSearchParams();
  const [curretView, setCurrentView] = useState<'grid' | 'list'>('list');
  const [scope, animate] = useAnimate();
  const [showFilterProfile, setShowFilterProfile] = useState(false);
  const [profileList, setProfileList] = useState([]);
  const [userMills, setUserMills] = useState('20');
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [showImmediateBooking, setShowImmediateBooking] = useState(false);
  const { data: subscriptionLimitData } = useSubscriptionLimitCheck(true);
  const { subscriptionExhausted, setSubscriptionExhausted } = useContext(ThemeContext);
  const { data, error, isLoading, isFetching, refetch } = useServiceProviderList({
    range: `${userMills}`,
    service_type_id: serviceId || '',
  });

  useEffect(() => {
    if (data?.data?.length) {
      animate(
        'div',
        { transform: ['scale(.8)', 'scale(1)'], opacity: [0, 0.75, 1] },
        { delay: stagger(0.005), type: 'tween' },
      );
      setShowImmediateBooking(true);
    } else {
      setShowImmediateBooking(false);
    }
  }, [data]);

  const filterProfile = (list: any) => {
    if (list?.length > 0) {
      setShowImmediateBooking(true);
    } else {
      setShowImmediateBooking(false);
    }
    setProfileList(list);
    setShowFilterProfile(true);
  };

  const resetData = () => {
    setShowFilterProfile(false);
    if (data?.data?.length) {
      setShowImmediateBooking(true);
    }
  };

  useEffect(() => {
    if (subscriptionLimitData) {
      setSubscriptionExhausted(subscriptionLimitData);
    }
  }, [subscriptionLimitData]);

  const checkBookingexceed = () => {
    if (subscriptionLimitData?.data?.service_exausted) {
      toastMessageUpdate('info', subscriptionExhausted?.message);
      return;
    } else {
      setOpenBookingModal(true);
    }
  };

  return (
    <>
      <Grid m={0} mt={matches ? 40 : 0}>
        <Drawer opened={opened} onClose={close} size={isMobile ? 'sm' : '75%'} position='bottom' padding={0}>
          <Drawer.Header style={{ height: 50 }}>
            <IconArrowLeft size={25} className='mobileBackButton' onClick={close}></IconArrowLeft>
          </Drawer.Header>
          <Box px={5} py={0} m={0}>
            <SearchFilter
              setShowFilterProfile={setShowFilterProfile}
              userMills={userMills}
              setUserMills={setUserMills}
              refetch={refetch}
              serviceTypeId={serviceId}
              getProfileList={filterProfile}
              resetData={resetData}></SearchFilter>
          </Box>
        </Drawer>
        <Grid.Col md={9}>
          <Paper bg='gray.1' p='sm'>
            <Flex align='center' justify='space-between'>
              <Text lineClamp={1}>{`${urlParams.get('service')}`.toUpperCase()}</Text>
              <Flex>
                <ActionIcon
                  onClick={() => setCurrentView('list')}
                  size='lg'
                  variant={curretView === 'grid' ? 'subtle' : 'filled'}
                  bg={curretView === 'grid' ? 'gray.3' : ''}
                  color='orange.6'>
                  <IconList size='1.5rem' />
                </ActionIcon>
                <ActionIcon
                  ml={5}
                  onClick={() => setCurrentView('grid')}
                  size='lg'
                  color='orange.6'
                  variant={curretView === 'list' ? 'subtle' : 'filled'}
                  bg={curretView === 'list' ? 'gray.3' : ''}>
                  <IconBorderAll size='1.5rem' />
                </ActionIcon>
                {!isMobile && (
                  <ActionIcon ml={5} size='lg' color='orange.6' variant='subtle' bg='gray.3' onClick={open}>
                    <IconFilter size='1.3rem' />
                  </ActionIcon>
                )}
                {/* <Select
                w={150}
                placeholder='Relevance'
                rightSection={<IconChevronDown size='1rem' />}
                rightSectionWidth={30}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                data={['React', 'Angular', 'Svelte', 'Vue']}
              /> */}
              </Flex>
            </Flex>
          </Paper>
          {showImmediateBooking && (
            <Button
              radius={4}
              size='sm'
              variant='gradient'
              gradient={{ from: '#fda223', to: '#fe7720' }}
              my={15}
              onClick={() => setOpenBookingModal(true)}
              rightIcon={<IconArrowRight size={rem(18)} />}>
              URGENT BOOKING
            </Button>
          )}
          <ScrollArea.Autosize mah={matches ? '100%' : `calc(100svh * 0.7)`} offsetScrollbars={matches ? false : true}>
            <Grid gutter='xl' m={0} my='xl' ref={scope}>
              {(isLoading || isFetching) && (
                <Grid.Col>
                  <Skeleton visible={true} h={200} />
                </Grid.Col>
              )}
              {showFilterProfile ? (
                <>
                  {profileList?.length > 0 ? (
                    <>
                      {profileList.map((item: any, index: number) => {
                        if (curretView === 'grid') {
                          return (
                            <Grid.Col md={4} xs={6} key={index}>
                              <GridCard {...item} />
                            </Grid.Col>
                          );
                        }
                        return (
                          <Grid.Col key={index}>
                            <ListCardRefactored {...item} />
                          </Grid.Col>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <Box mr={12} ml={12} p={10} style={{ border: '1px solid #dee2e6', borderRadius: '5px' }}>
                        <Text pl={18} fz={20} c='dimmed' weight={600} size='md'>
                          No profile founds.
                        </Text>
                        <Text pl={15} pt={10} fz={18} c='dimmed' weight={500} size='md'>
                          It appears that there are no profiles to display at the moment. We understand your interest
                          and are actively working to bring you a diverse and engaging selection of profiles. Please
                          check back soon as we continue to update and expand our user listings. If you have any
                          questions or specific preferences, feel free to contact our support team – we are here to
                          help! Thank you for your understanding.
                        </Text>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!isFetching && data?.data?.length
                    ? data?.data?.map((item: any, index: number) => {
                        if (curretView === 'grid') {
                          return (
                            <Grid.Col md={4} xs={6} key={index}>
                              <GridCard {...item} />
                            </Grid.Col>
                          );
                        }
                        return (
                          <Grid.Col key={index}>
                            {/* <ListCard {...item} /> */}
                            <ListCardRefactored {...item} />
                          </Grid.Col>
                        );
                      })
                    : null}

                  {!isLoading && !data?.data?.length && !isFetching && (
                    <>
                      <Box mr='12px' ml='12px' p={10} style={{ border: '1px solid #dee2e6', borderRadius: '5px' }}>
                        <Text pl={18} fz={20} c='dimmed' weight={600} size='md'>
                          No profile found.
                        </Text>
                        <Text pl={15} pt={10} fz={18} c='dimmed' weight={500} size='md'>
                          It appears that there are no profiles to display at the moment. We understand your interest
                          and are actively working to bring you a diverse and engaging selection of profiles. Please
                          check back soon as we continue to update and expand our user listings. If you have any
                          questions or specific preferences, feel free to contact our support team – we are here to
                          help! Thank you for your understanding.
                        </Text>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Grid>
          </ScrollArea.Autosize>
        </Grid.Col>

        {isMobile && (
          <Grid.Col md={3}>
            <Paper bg='gray.1' p='sm'>
              <Group position='apart'>
                <Text>Filter</Text>
              </Group>
            </Paper>

            <SearchFilter
              setShowFilterProfile={setShowFilterProfile}
              userMills={userMills}
              setUserMills={setUserMills}
              refetch={refetch}
              serviceTypeId={serviceId}
              getProfileList={filterProfile}
              resetData={resetData}></SearchFilter>
          </Grid.Col>
        )}
      </Grid>
      <ModalOrDrawer
        opened={openBookingModal}
        onClose={() => setOpenBookingModal(false)}
        drawerProps={{ dz: 300, drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <UrgentBooking
          profileData={showFilterProfile ? profileList : data?.data}
          openModal={openBookingModal}
          close={() => setOpenBookingModal(false)}
        />
      </ModalOrDrawer>
    </>
  );
}
