import { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { RoleType, providerRole } from '../utils';
import { useCookies } from 'react-cookie';

interface IRoleGuard {
  role: RoleType[];
}

// based on current role it redirect to role based home page.
export default function RoleGuard({ role }: IRoleGuard) {
  const [cookie] = useCookies(['user_Info', 'subscription_status', 'role']);
  const navigateTo = providerRole.includes(cookie.user_Info?.type) ? '/partner/profile' : '/';

  useEffect(() => {
    // console.log(cookie.user_Info?.type, role.includes(cookie.user_Info?.type));
    // if (cookie.user_Info?.type && !role.includes(cookie.user_Info?.type)) {
    //   navigate(-1);
    // } else {
    //   navigate(navigateTo);
    // }
  }, []);

  // if (
  //   (JSON.parse(cookie?.subscription_status) === true && cookie?.role === '3') ||
  //   (JSON.parse(cookie?.subscription_status) === true && cookie?.role === '4')
  // ) {
  //   return <Navigate to={'/subscription'} replace />;
  // }
  if (cookie.user_Info?.type && !role.includes(cookie.user_Info?.type)) {
    return <Navigate to={navigateTo} replace />;
  }
  return <Outlet />;
}
