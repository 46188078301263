import {
  ActionIcon,
  Avatar,
  Box,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconBriefcase, IconGenderBigender, IconHeartFilled, IconRoute } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { HeaderAvatarIcon } from '../../../../layouts/authLayout/component/styles';
import {
  deletegetFavouriteProvider,
  getFavouriteProviderDetails,
  useFavouriteList,
} from '../../../../services/favourite.api';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router';
import generateRandomString from '../../../../utils/helpers/generateRandomAlphabetStrings';

export default function Favourites() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [favourites, setFavourites] = useState<any>([]);
  const { classes: AvatarStyle } = HeaderAvatarIcon();
  const [loading, setLoading] = useState(false);
  const { data: favList, isLoading: favListLoading, refetch: favRefetch } = useFavouriteList();

  useEffect(() => {
    getFavourites();
  }, [favList]);

  const getFavourites = async () => {
    setLoading(true);
    if (favList?.data?.length > 0) {
      setFavourites(favList?.data);
    } else {
      setFavourites([]);
    }
    setLoading(false);
  };

  const deleteHandler = async (e: string) => {
    const formData = {
      favourite_id: e,
    };
    setLoading(true);
    try {
      const data: any = await deletegetFavouriteProvider(formData);
      favRefetch();
      setLoading(false);
      toastMessageUpdate('success', data?.message);
    } catch (error: any) {
      toastMessageUpdate('error', error?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingOverlay visible={favListLoading || loading} />
      {!favListLoading && favList?.data.length === 0 ? (
        <>
          <Divider
            mt={50}
            label='You do not have any favourites'
            labelPosition='center'
            styles={() => ({ label: { fontSize: 18 } })}
          />
        </>
      ) : (
        !favListLoading && (
          <>
            <ScrollArea.Autosize
              offsetScrollbars={isTablet ? false : true}
              mah={`calc(100vh - 140px)`}
              type='hover'
              mt={30}>
              <Box mt={20} mx={10}>
                <Grid m={0}>
                  {!favListLoading &&
                    favList?.data.map((item: any) => {
                      return (
                        <Grid.Col key={item.favourite_id} xl={3} lg={4} md={4} sm={6} xs={6}>
                          <Paper
                            withBorder
                            style={{ cursor: 'pointer' }}
                            p={8}
                            bg={theme.colorScheme === 'light' ? '#e1e1e1' : theme.colors.dark[6]}
                            onClick={() =>
                              navigate(
                                `/user/${btoa(generateRandomString(16) + item.fav_user_id + generateRandomString(16))}`,
                              )
                            }
                            sx={() => ({ userSelect: 'none' })}>
                            <Flex align='center'>
                              <Avatar classNames={AvatarStyle} src={item.profile.image} radius='xl' ml={15} size={60} />
                              <Flex direction='column' ml={10} w='100%' gap={5}>
                                <Group spacing='lg' pb={5} position='apart'>
                                  <Text size={'md'} weight={600}>
                                    {item.users.name}
                                  </Text>
                                  <ActionIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deleteHandler(item.favourite_id);
                                    }}
                                    color='#fe7720'>
                                    <IconHeartFilled />
                                  </ActionIcon>
                                </Group>
                                <Group spacing='xs'>
                                  <IconGenderBigender />
                                  <Text fw={600}>Gender:</Text>
                                  <Text size={13} color='dimmed'>
                                    {item.profile.gender}
                                  </Text>
                                </Group>
                                <Group spacing='xs'>
                                  <IconBriefcase />
                                  <Text fw={600}>Experience:</Text>
                                  <Text size={13} color='dimmed'>
                                    {item.profile.experience} Years
                                  </Text>
                                </Group>
                              </Flex>
                            </Flex>
                          </Paper>
                        </Grid.Col>
                      );
                    })}
                </Grid>
              </Box>
            </ScrollArea.Autosize>
          </>
        )
      )}
    </>
  );
}
