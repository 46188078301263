import { ColorScheme, ColorSchemeProvider, MantineProvider, useMantineTheme } from '@mantine/core';
import { CustomFonts } from './utils/theme/CustomFonts';
import GlobalTheme, { useToastContainerStyles } from './utils/theme/GlobalTheme';
import { mantinethemeConfig } from './utils/theme/theme';
import Routing from './routes';
import { ThemeContext, useThemeLayout } from './context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHotkeys, useLocalStorage, useMediaQuery } from '@mantine/hooks';

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });
  const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || colorScheme === 'dark' ? 'light' : 'dark');
  useHotkeys([['mod+J', () => toggleColorScheme()]]);
  const mantinetheme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.sm})`);

  const { classes: toastClass } = useToastContainerStyles();

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <ThemeContext.Provider value={useThemeLayout()}>
        <MantineProvider
          theme={{
            ...mantinethemeConfig,
            colorScheme,
          }}
          withGlobalStyles>
          <GlobalTheme />
          <CustomFonts />
          <Routing />
          <ToastContainer className={mobile ? toastClass.mobiletoastContainer : toastClass.toastContainer} />
        </MantineProvider>
      </ThemeContext.Provider>
    </ColorSchemeProvider>
  );
}

export default App;
