import { Box, Text, Rating, Textarea, Group, Button, Divider } from '@mantine/core';
import { useEffect, useState } from 'react';
import { createReview } from '../../../../services/review';
import { ReviewType, customerRole } from '../../../../utils';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { useCookies } from 'react-cookie';
interface IRatingReview {
  bookingId: string;
  close: () => void;
  reviewId: any;
  serviceProviderId: string;
  userId: string | any;
  reviewData?: any;
  providerReviewData?: any;
}

const RatingReview = ({
  bookingId,
  close,
  reviewId,
  serviceProviderId,
  userId,
  reviewData,
  providerReviewData,
}: IRatingReview) => {
  const [cookie, setCookie] = useCookies(['role']);
  const [ratingValue, setRatingValue] = useState(0);
  const [providerRatingValue, setProviderRatingValue] = useState(0);
  const [bookingReview, setBookingReview] = useState('');
  const [providerReview, setProviderReview] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [serviceReviewBtn, setServiceReviewBtn] = useState(false);
  const [providerReviewBtn, setProviderReviewBtn] = useState(false);
  const [userReviewLoading, setUserReviewLoading] = useState(false);
  const [providerReviewLoading, setProviderReviewLoading] = useState(false);
  const [serviceReviewError, setServiceReviewError] = useState('');
  const [providerReviewError, setProviderReviewError] = useState('');

  // submit review and rating and handling its functionality.
  const submitReview = async (reviewType: any, entityId: any, bookingId: any, review: string, rating: any) => {
    switch (reviewType) {
      case 'user':
        if (review.trim()) {
          setProviderReviewLoading(true);
          setServiceReviewError('');
        } else {
          setProviderReviewLoading(false);
          setProviderReviewError('Please review our service provider');
          return;
        }
        break;
      case 'booking':
        if (review.trim()) {
          setUserReviewLoading(true);
          setProviderReviewError('');
        } else {
          setUserReviewLoading(false);
          setServiceReviewError('Please review our service.');
          return;
        }
        break;
      default:
        setServiceReviewError('');
        setProviderReviewError('');
    }
    const reviewParams = {
      review_type: reviewType,
      entity_id: entityId,
      booking_id: bookingId,
      rating: rating,
      review: review.trim(),
    };
    try {
      const saveReviewData = await createReview(reviewParams);
      toastMessageUpdate('success', 'Review submitted successfully.');
      if (reviewType === 'user') {
        setProviderReviewLoading(false);
        setProviderReviewBtn(true);
      }
      if (reviewType === 'booking') {
        setUserReviewLoading(false);
        setServiceReviewBtn(true);
      }
      setErrorMsg('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {customerRole.includes(+cookie.role) &&
        reviewData &&
        reviewData?.filter((item: any) => item?.booking_id === bookingId)?.length === 0 && (
          <Box pt={15}>
            <Text mb={5} fw={600} fz={20}>
              Rate and review our service:
            </Text>
            <Rating size='lg' value={ratingValue} onChange={setRatingValue} readOnly={serviceReviewBtn} />
            <Textarea
              disabled={serviceReviewBtn}
              size='md'
              pt={5}
              onChange={(event: any) => {
                setBookingReview(event.target.value);
                setServiceReviewError('');
              }}
              autosize
              minRows={1}
              maxRows={3}
              placeholder='Enter review'
              label='Review'
            />
            {serviceReviewError && <Text c='red'>{serviceReviewError}</Text>}
            <Group spacing={10} position='right' my='xl' mt='xl'>
              <Button
                radius={4}
                fullWidth
                variant='gradient'
                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                loading={userReviewLoading}
                disabled={serviceReviewBtn}
                onClick={() => {
                  submitReview(ReviewType.booking, bookingId, bookingId, bookingReview, ratingValue);
                }}>
                Submit
              </Button>
            </Group>
          </Box>
        )}

      {providerReviewData &&
        providerReviewData?.filter((item: any) => item?.booking_id === bookingId)?.length === 0 && (
          <Box pt={5}>
            <Divider my={10}></Divider>
            <Text my={5} fw={600} fz={20}>
              Rate and review our {customerRole.includes(+cookie.role) ? 'service provider' : 'customer'}:
            </Text>
            <Rating
              size='lg'
              value={providerRatingValue}
              onChange={setProviderRatingValue}
              readOnly={providerReviewBtn}
            />
            <Textarea
              disabled={providerReviewBtn}
              size='md'
              pt={5}
              onChange={(event: any) => {
                setProviderReview(event.target.value);
                setProviderReviewError('');
              }}
              autosize
              minRows={1}
              maxRows={3}
              placeholder='Enter review'
              label='Review'
            />
            {providerReviewError && <Text c='red'>{providerReviewError}</Text>}
            <Group spacing={10} position='right' my='xl' mt='xl'>
              <Button
                radius={4}
                fullWidth
                variant='gradient'
                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                disabled={providerReviewBtn}
                loading={providerReviewLoading}
                onClick={() => {
                  submitReview(
                    ReviewType.user,
                    customerRole.includes(+cookie.role) ? serviceProviderId : userId,
                    bookingId,
                    providerReview,
                    providerRatingValue,
                  );
                }}>
                Submit
              </Button>
            </Group>
          </Box>
        )}
    </>
  );
};

export default RatingReview;
