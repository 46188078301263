import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Text, TextInput } from '@mantine/core';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { immediateBooking, useSlotList } from '../../../../../services/booking';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { immediateBookingSchema } from '../../../../../utils/schema/formSchema';
import { HomeContext } from '../../../Home/homeContext';
import CustomButton from '../../../Partner/Profile/components/CustomButton';

interface IServiceDetails {
  openModal: boolean;
  close: () => void;
  profileData: any;
}

export default function UrgentBooking({ openModal, close, profileData }: IServiceDetails) {
  const [cookies, setCookie] = useCookies(['role']);
  const [currentSlot, setCurrentSlot] = useState('');

  const { data: slotData, isLoading: isSlotLoading } = useSlotList();
  const { serviceId } = useParams();
  const [loading, setLoading] = useState(false);

  console.log(slotData);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(immediateBookingSchema),
    defaultValues: { providers_quantity: '1' },
    mode: 'all',
  });

  const { selectedServiceTypeId } = useContext(HomeContext);

  useEffect(() => {
    // console.log('slot', slotData, profileData);
    if (slotData?.data) {
      console.log(dayjs(new Date()).format('HH'));

      const filterIn = slotData.data.findIndex(
        (item: any) => item?.time_from.split(':')[0] > dayjs(new Date()).format('HH'),
      );
      console.log(filterIn);

      console.log(slotData.data?.[filterIn], serviceId);
      setCurrentSlot(slotData.data?.[filterIn]?.slot_id);
    }
  }, [openModal]);

  const confirmBooking = async (data: any) => {
    // setLoading(true);
    console.log(data);

    const bookingParams = {
      service_type_id: selectedServiceTypeId,
      slot_id: currentSlot,
      providers_quantity: +data?.providers_quantity,
    };
    console.log(bookingParams);

    // return;
    const formData = new FormData();
    for (const [key, value] of Object.entries(bookingParams)) {
      formData.append(key, value);
    }
    try {
      const bookingData = await immediateBooking(formData);
      //   console.log('data', bookingData);
      toastMessageUpdate('success', 'Booking request is sent, Please wait while we connect you to service provider.');
      close();
      setLoading(false);
    } catch (error: any) {
      console.log('error', error);
      setLoading(false);
      close();
      toastMessageUpdate('error', error?.data?.error || error?.data?.message);
    }
  };
  return (
    <>
      <Box>
        <Text fz={26} fw='bold' ta='center'>
          Urgent Booking
        </Text>
        <Text fz={19} pt='xs' pb='lg' fw={500} c='black' ta='center'>
          Are you sure for urgent booking?
        </Text>

        <form onSubmit={handleSubmit(confirmBooking)} autoComplete='off'>
          {+cookies.role === 4 && (
            <>
              <TextInput
                label='Quantity'
                {...register('providers_quantity', { required: true })}
                size='md'
                placeholder='Enter quantity'
              />
              {errors?.providers_quantity && (
                <Text fz={14} pl={5} fw={500} color='red'>
                  {errors?.providers_quantity?.message}
                </Text>
              )}
            </>
          )}
          <Flex justify='center' gap={4}>
            <CustomButton variant='gradient' type='submit' loading={loading}>
              Confirm
            </CustomButton>
            <CustomButton variant='outline' onClick={close}>
              Cancel
            </CustomButton>
          </Flex>
        </form>
      </Box>
    </>
  );
}
