import {
  Avatar,
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  Popover,
  ScrollArea,
  Skeleton,
  Tabs,
  Text,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { serviceIconHandler } from '../../../../utils/helpers';
import { makeUpperCase } from '../../../../utils/helpers/makeFirstLetterUpper';
import { CommentCard } from './CommentCard';
import { ServiceCard } from './ServiceCard';
import { useTabElementStyle } from './style';
import { useMediaQuery } from '@mantine/hooks';
import { IconDotsVertical } from '@tabler/icons-react';
import { useCookies } from 'react-cookie';
import generateRandomString from '../../../../utils/helpers/generateRandomAlphabetStrings';

const overviewCardStyles = createStyles((theme) => ({
  overviewCard: {
    height: '100%',
    backgroundColor: theme.colorScheme === 'light' ? '#feede5' : theme.colors.dark[7],
    borderStyle: 'none !important',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
      boxShadow: '0 0 8px 2px #FFD8A9',
      transition: '300ms',
      transform: 'scale(1.02)',
    },
  },
}));

const useIconStyles = createStyles((theme) => ({
  image: {
    objectFit: 'contain',
  },
}));

const useGridStyles = createStyles((theme) => ({
  gridCol: {
    [theme.fn.smallerThan(576)]: {
      flexBasis: '50%',
      flexShrink: 0,
      maxWidth: '50%',
      flexGrow: 0,
    },

    [theme.fn.smallerThan(380)]: {
      flexBasis: '100%',
      flexShrink: 0,
      maxWidth: '100%',
      flexGrow: 0,
    },
  },
}));

interface ITabEle {
  services: Record<string, any>;
  userOverview: string;
  userFeedback: any;
  serviceLoading: boolean;
}

// Tab element section and its functionality of service provider and organization service  provider.
export const TabElementDetails = ({ services, userOverview, userFeedback, serviceLoading }: ITabEle) => {
  const { classes } = useTabElementStyle();
  const { classes: overviewClasses } = overviewCardStyles();
  const { classes: iconSyles } = useIconStyles();
  const { classes: gridStyles } = useGridStyles();
  const { id } = useParams();
  const [bookNow, setBookNow] = useState(false);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>('services');
  const selectedTabRenderer = (value: string | null) => {
    switch (value?.trim().toLowerCase()) {
      case 'services':
        return 'Services';
      case 'overview':
        return 'Overview';
      case 'feedback':
        return 'Feedback';
      default:
        undefined;
    }
  };

  return (
    <Tabs
      defaultValue='services'
      orientation={isMobile ? 'vertical' : 'horizontal'}
      placement='right'
      onTabChange={(currentTab) => {
        setActiveTab(currentTab);
        setPopoverOpened((state) => !state);
      }}
      styles={(theme) => ({
        root: {
          flexDirection: 'column',
        },
      })}>
      {isMobile ? (
        <>
          <Flex justify='space-between'>
            <Title order={3}>{selectedTabRenderer(activeTab)}</Title>
            <Popover withArrow opened={popoverOpened} onChange={setPopoverOpened}>
              <Popover.Target>
                <IconDotsVertical onClick={() => setPopoverOpened((state) => !state)} />
              </Popover.Target>

              <Popover.Dropdown>
                <Tabs.List
                  style={{
                    border: 'none',
                  }}>
                  <Tabs.Tab className='heading-font' fw={600} value='overview'>
                    <Text>Overview</Text>
                  </Tabs.Tab>
                  <Tabs.Tab className='heading-font' fw={600} value='services'>
                    <Text>Services</Text>
                  </Tabs.Tab>
                  <Tabs.Tab className='heading-font' fw={600} value='feedback'>
                    <Text>Feedback</Text>
                  </Tabs.Tab>
                </Tabs.List>
              </Popover.Dropdown>
            </Popover>
          </Flex>
        </>
      ) : (
        <>
          <Tabs.List grow>
            <Tabs.Tab className='heading-font' fw={600} value='overview'>
              <Text>Overview</Text>
            </Tabs.Tab>
            <Tabs.Tab className='heading-font' fw={600} value='services'>
              <Text>Services</Text>
            </Tabs.Tab>
            <Tabs.Tab className='heading-font' fw={600} value='feedback'>
              <Text>Feedback</Text>
            </Tabs.Tab>
          </Tabs.List>
        </>
      )}

      <Tabs.Panel value='overview' pt='xs'>
        <Title my='md' order={4}>
          My services includes:
        </Title>

        <Grid>
          {serviceLoading && <Skeleton visible={true} h={150} />}
          {!serviceLoading &&
            services &&
            services?.length &&
            services?.map((item: any) => {
              // this will be rendered if the service has sub-services
              if (Object.hasOwn(item, 'child')) {
                let listItem = item?.child || [];
                if (Object.hasOwn(item, 'created_at')) {
                  listItem = [item, ...listItem];
                }
                return listItem.map((child: any) => {
                  return (
                    <Grid.Col key={child?.service_id} lg={4} md={6} sm={4} xs={6} className={gridStyles.gridCol}>
                      <Card className={overviewClasses.overviewCard}>
                        <Flex direction='column' h='100%' justify='space-between' align='start' gap={7}>
                          <Box>
                            <Avatar
                              size='md'
                              src={serviceIconHandler(child?.has_service_type?.service)}
                              classNames={iconSyles}></Avatar>
                          </Box>
                          <Text fz={15}>{makeUpperCase(child?.has_service_type?.service)}</Text>
                        </Flex>
                      </Card>
                    </Grid.Col>
                  );
                });
              }
              // and this will be rendered if the service does not have any subservice
              return (
                <Grid.Col key={item?.service_id} lg={4} md={6} sm={4} xs={6} className={gridStyles.gridCol}>
                  <Card withBorder className={overviewClasses.overviewCard}>
                    <Flex direction='column' h='100%' justify='space-between' align='start' gap={7}>
                      <Box>
                        <Avatar
                          src={serviceIconHandler(item?.has_service_type?.service)}
                          classNames={iconSyles}></Avatar>
                      </Box>
                      <Text fz={15}>{makeUpperCase(item?.has_service_type?.service)}</Text>
                    </Flex>
                  </Card>
                </Grid.Col>
              );
            })}
        </Grid>
      </Tabs.Panel>

      <Tabs.Panel value='services' pt='xs'>
        <ScrollArea.Autosize mah={isMobile ? '100%' : `calc(100vh - 200px)`} offsetScrollbars={isMobile ? false : true}>
          <Grid p={10}>
            {serviceLoading && <Skeleton visible={true} h={150} />}
            {!serviceLoading &&
              services &&
              services?.length &&
              services?.map((item: any) => {
                // this will be rendered if the service has sub-services
                if (Object.hasOwn(item, 'child')) {
                  let listItem = item?.child || [];
                  if (Object.hasOwn(item, 'created_at')) {
                    listItem = [item, ...listItem];
                  }
                  return listItem.map((child: any, index: number) => {
                    return (
                      <Grid.Col key={child?.service_id} lg={4} md={6} sm={4} xs={6} className={gridStyles.gridCol}>
                        <ServiceCard
                          navigateTo={`/book-services/${btoa(
                            generateRandomString(16) + child?.users_id + generateRandomString(16),
                          )}?service_type=${child?.service_type_id}&service_id=${child?.service_id}&name=${btoa(
                            child?.['has_service_type']?.['service'],
                          )}`}
                          key={child?.['service_id']}
                          image={child?.['image']}
                          title={child?.['has_service_type']?.['service']}
                          description={child?.['description']}
                          rate={child?.['rate']}
                        />
                      </Grid.Col>
                    );
                  });
                }
                // and this will be rendered if the service does not have any subservice
                return (
                  <Grid.Col key={item?.service_id} lg={4} md={6} sm={4} xs={6} className={gridStyles.gridCol}>
                    <ServiceCard
                      navigateTo={`/book-services/${item?.users_id}?service_type=${item?.service_type_id}&service_id=${item?.service_id}`}
                      key={item?.['service_id']}
                      image={item?.['image']}
                      title={item?.['has_service_type']?.['service']}
                      description={item?.['description']}
                      rate={item?.['rate']}
                    />
                  </Grid.Col>
                );
              })}
          </Grid>
        </ScrollArea.Autosize>
      </Tabs.Panel>

      <Tabs.Panel value='feedback' pt='xs'>
        <ScrollArea.Autosize mah={isMobile ? '100%' : `calc(100vh - 200px)`} offsetScrollbars>
          <ul className={classes.listContainer}>
            {userFeedback?.length > 0 ? (
              <>
                {userFeedback?.map((item: any, index: number) => {
                  return (
                    <li key={index}>
                      <CommentCard
                        postedAt={item?.created_at}
                        body={item?.review}
                        rating={+item?.rating}
                        author={{
                          name: item?.has_user?.name,
                          image: item?.has_user?.user_profile?.image || '',
                        }}
                        verifiedUser={item?.has_user?.has_verification}
                      />

                      <Divider my='sm' />
                    </li>
                  );
                })}
              </>
            ) : (
              <Text pl={15} pt={10} fz={15} c='dimmed' weight={600} size='md'>
                No feedback found.
              </Text>
            )}
          </ul>
        </ScrollArea.Autosize>
      </Tabs.Panel>
    </Tabs>
  );
};
