import AgoraRTC, { IAgoraRTCRemoteUser } from 'agora-rtc-react';
import React, { useState, useEffect, useContext } from 'react';
import { appId, useClient, useMicrophoneAndCameraTracks, videoChannelName } from '../../../../utils/setting';
import { useCookies } from 'react-cookie';
import Controls from './Controls';
import Videos from './Videos';
import { Button, Flex, LoadingOverlay, Text } from '@mantine/core';
import { VideoContext } from '../../Chat/videoContext';
import { AppEvents } from '../../../../utils/helpers/sendFIle';
import { useMediaQuery } from '@mantine/hooks';

const VideoCall = (props: any) => {
  const { setInCall, channelName, closeVideo } = props;
  const [cookies, setCookie] = useCookies(['user_Info', 'agora_token', 'peerId', 'bookingDetails']);
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [start, setStart] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<any>('');
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  const [loading, setLoading] = useState(false);
  const { setStartVideo } = useContext(VideoContext);
  const isMobile = useMediaQuery('(max-width: 576px)');

  // handling audio and video controls and filter user list.
  useEffect(() => {
    // function to initialise the SDK
    setLoading(true);
    const init = async (channelname: string) => {
      console.log('init', channelname);
      client.on('user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log('subscribe success', users, mediaType);
        if (mediaType === 'video') {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === 'audio') {
          user.audioTrack?.play();
          setUsers((prevUsers: any) => {
            // return prevUsers.filter((User) => User.uid !== user.uid);
            if (prevUsers.length > 0) {
              return prevUsers.filter((User: any) => User.uid == user.uid);
            } else {
              return [user];
            }
          });
        }
      });

      client.on('user-unpublished', (user: any, type) => {
        console.log('unpublished', user, type);
        if (type === 'audio') {
          user.audioTrack?.stop();
          setUsers((prevUsers) => {
            console.log('prev audio User', prevUsers, user);
            // return prevUsers.filter((User) => User.uid !== user.uid);
            return prevUsers.filter((User: any) => User.uid == user.uid);
          });
        }
        if (type === 'video') {
          console.log('prev user', user);
          setUsers((prevUsers) => {
            console.log('prevUser', prevUsers, user);
            // return prevUsers.filter((User) => User.uid !== user.uid);
            return prevUsers.filter((User: any) => User.uid == user.uid);
            // prevUsers.filter((User, index) => {
            //   return index === prevUsers.findIndex((current) => User.uid == current.uid);
            // });
          });
        }
      });

      client.on('user-left', (user) => {
        console.log('leaving', user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });
      console.log('channel:', channelName);

      await client.join(appId, channelName, cookies?.agora_token?.rtc_token, cookies?.user_Info?.email);
      if (tracks) {
        await client.publish([tracks[0], tracks[1]]);
      } else {
        console.log('** client unpublish');
      }

      setStart(true);
    };

    if (ready && tracks) {
      const device = AgoraRTC.checkSystemRequirements();
      console.log('init ready', ready, tracks, channelName);
      init(channelName);
      setErrorText('');
      setLoading(false);
      AppEvents.dispatch('videoCallStatus', ready);
    } else {
      console.log('device check', ready, tracks);
      setTimeout(() => {
        setLoading(false);
        setErrorText('Please connect your camera & microphone for the video call');
        console.log('Loader off');
      }, 3000);
    }
  }, [channelName, client, ready, tracks]);

  const closeCall = () => {
    setStartVideo(false);
  };

  return (
    <>
      {ready && tracks ? (
        <>
          {start && tracks && (
            <>
              <Videos users={users} tracks={tracks} />
              <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} closeVideoCall={closeVideo} />
            </>
          )}
        </>
      ) : (
        <>
          <LoadingOverlay visible={loading} />
          {!loading && (
            <>
              <Flex justify='center' direction='column'>
                <Text color='dimmed' fz={17} pb='md' ta='center'>
                  {errorText ? errorText : 'Something went wrong, Please check your camera and microphone.'}
                </Text>
                <Button
                  size='md'
                  radius={4}
                  fullWidth={isMobile}
                  mx='auto'
                  type='button'
                  miw='40%'
                  onClick={() => {
                    closeVideo();
                  }}
                  variant='gradient'
                  gradient={{ to: '#fda223', from: '#fe7720' }}>
                  Close
                </Button>
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
};

export default VideoCall;
