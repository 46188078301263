import { Grid, createStyles, Text, ActionIcon, Flex, Box, Avatar, useMantineTheme } from '@mantine/core';
import { AgoraVideoPlayer } from 'agora-rtc-react';
import React, { useContext, useEffect, useState } from 'react';
import { VideoContext } from '../../Chat/videoContext';
import { IconMicrophone, IconMicrophoneOff } from '@tabler/icons-react';
import useAgoraRtm from '../../../../utils/hooks/useAgoraRtm';
import { useCookies } from 'react-cookie';
import { AvatarIcon } from '../../Partner/Profile/components/style';
import { useMediaQuery } from '@mantine/hooks';

const useVideoStyles = createStyles(() => ({
  videoUser: {
    position: 'relative',
    height: '300px',
    maxHeight: '100%',
    width: '400px',
    maxWidth: 'fit-content',
    margin: '0 auto',

    [`@media (max-width: 1440px)`]: {
      width: '400px',
      margin: '0 auto',
    },
    [`@media (max-width: 1200px)`]: {
      width: '322px',
      margin: '0 auto',
    },
    [`@media (max-width: 1024px)`]: {
      width: '322px',
      margin: '0 auto',
    },
    [`@media (max-width: 991px)`]: {
      width: '295px',
      margin: '0 auto',
    },
    [`@media (max-width: 875px)`]: {
      height: '270px',
      width: '280px',
      margin: '0 auto',
    },
    [`@media (max-width: 767px)`]: {
      width: '400px',
      margin: '0 auto',
    },
    [`@media (max-width: 576px)`]: {
      height: '270px',
      maxWidth: '100%',
    },
    [`@media (max-width: 425px)`]: {
      width: '311px',
      margin: '0 auto',
      height: '240px',
    },
    [`@media (max-width: 375px)`]: {
      height: '225px',
      width: '261px',
      margin: '0 auto',
    },
    [`@media (max-width: 320px)`]: {
      height: '225px !important',
      minWidth: '225px',
      margin: '0 auto',
    },
  },

  videoBox: {
    maxWidth: '400px',
    height: '300px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',

    [`@media (max-width: 1440px)`]: {
      width: '400px',
      margin: '0 auto',
    },
    [`@media (max-width: 1200px)`]: {
      width: '322px',
      margin: '0 auto',
    },
    [`@media (max-width: 1024px)`]: {
      width: '322px',
      margin: '0 auto',
    },
    [`@media (max-width: 991px)`]: {
      width: '295px',
      margin: '0 auto',
    },
    [`@media (max-width: 875px)`]: {
      height: '270px',
      width: '280px',
      margin: '0 auto',
    },
    [`@media (max-width: 767px)`]: {
      width: '360px',
      margin: '0 auto',
    },
    [`@media (max-width: 576px)`]: {
      height: '270px',
    },
    [`@media (max-width: 425px)`]: {
      width: '311px',
      margin: '0 auto',
      height: '240px',
    },
    [`@media (max-width: 375px)`]: {
      height: '225px !important',
      width: '261px',
      margin: '0 auto',
    },
    [`@media (max-width: 320px)`]: {
      height: '225px !important',
      width: '225px',
      margin: '0 auto',
    },
  },
}));

// video UI and its functionality.
const Videos = (props: any) => {
  const { users, tracks } = props;
  const [cookies, setCookie] = useCookies(['user_Overview']);
  const { classes: videoClass } = useVideoStyles();
  const [newUser, setNewUser] = useState(users);
  const { classes: AvatarStyle } = AvatarIcon();
  const { trackStatus, chatUser } = useContext(VideoContext);
  const mantinetheme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.sm})`);

  const handleError = (error: any) => {
    console.log('agora video player error', error);
  };

  // useEffect(() => {
  //   console.log('chatUser', chatUser);
  // }, [chatUser]);

  useEffect(() => {
    // same duplicate user remove
    const user = users?.filter((obj: Record<string, any>, index: number) => {
      return index === users.findIndex((current: any) => obj.uid === current.uid);
    });
    setNewUser(user);
    console.log('video user', user, users, tracks);
  }, [users, tracks]);

  return (
    <>
      <Grid gutter='lg' my='xl' justify='center' style={{ flexDirection: 'column' }} align='center'>
        <Grid.Col w='100%' md={12} sm={12} lg={12} display={users.length > 0 ? 'unset' : 'none'}>
          {newUser.length > 0 &&
            newUser.map((user: any, id: number) => {
              if (user.videoTrack) {
                return (
                  <>
                    <AgoraVideoPlayer
                      onError={handleError}
                      className={videoClass.videoUser}
                      videoTrack={user.videoTrack}
                      key={id}
                    />
                    <Flex gap='lg' justify='center'>
                      {user?._audio_muted_ && (
                        <ActionIcon
                          variant='gradient'
                          pos='absolute'
                          mt={-60}
                          style={{
                            cursor: 'auto',
                            border: 'none',
                            background: 'linear-gradient(160deg, #FEC74E 0%, #FE7D49 100%)',
                          }}
                          radius='50%'
                          size={45}>
                          <IconMicrophoneOff size='1.8rem' stroke={1.5} />
                        </ActionIcon>
                      )}
                    </Flex>
                  </>
                );
              } else {
                return (
                  <>
                    <Box
                      bg='black'
                      // w='100%'
                      className={videoClass.videoBox}
                      // h={mobile ? '280px' : '380px'}
                      // style={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Avatar
                        src={chatUser?.data?.user_profile?.image}
                        classNames={AvatarStyle}
                        radius='50%'
                        size={90}
                      />
                    </Box>
                    <Flex gap='lg' justify='center'>
                      {user?._audio_muted_ && (
                        <ActionIcon
                          variant='gradient'
                          pos='absolute'
                          mt={-60}
                          style={{
                            cursor: 'auto',
                            border: 'none',
                            background: 'linear-gradient(160deg, #FEC74E 0%, #FE7D49 100%)',
                          }}
                          radius='50%'
                          size={45}>
                          <IconMicrophoneOff size='1.8rem' stroke={1.5} />
                        </ActionIcon>
                      )}
                    </Flex>
                  </>
                );
              }
            })}{' '}
        </Grid.Col>
        <Grid.Col w='100%' md={12} sm={12} lg={12}>
          {/* <AgoraVideoPlayer onError={handleError} className={videoClass.videoUser} videoTrack={tracks[1]} /> */}
          {tracks[1]._enabled ? (
            <AgoraVideoPlayer onError={handleError} className={videoClass.videoUser} videoTrack={tracks[1]} />
          ) : (
            <Box bg='black' w='100%' className={videoClass.videoBox}>
              {/* src={cookies?.user_Overview?.image} */}
              <Avatar src={cookies?.user_Overview?.image} classNames={AvatarStyle} radius='50%' size={60} />
            </Box>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Videos;
