import React, { useEffect, useState } from 'react';
import { Avatar, Button, Divider, Flex, LoadingOverlay, Paper, rem, Skeleton, Stack, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import { IconCircleCheck } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { acceptBooking, useSlotList } from '../../../../services/booking';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import { covertTimeFormat } from '../../../../utils/helpers';

interface IRequestCard {
  avatar?: string;
  // quantity?: string;
  userAddress: string;
  userInfo: any;
  bookingRequest: any;
  serviceInfo: any;
}

const RequestCard = ({ avatar, bookingRequest, userInfo, userAddress, serviceInfo }: IRequestCard) => {
  const isMobile = useMediaQuery('(min-width: 768px)');
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [slotDetails, setSlotDetails] = useState<Record<string, any> | null>(null);
  const [acceptBookingStatus, setAcceptBookingStatus] = useState(false);
  const { data: slotData, isLoading: isSlotLoading } = useSlotList();

  // based on booking time slot list, set the slot details.
  useEffect(() => {
    if (slotData?.data) {
      const slotIn = slotData.data?.findIndex((item: any) => item?.slot_id === bookingRequest?.slot_id);
      if (slotIn > -1) {
        setSlotDetails(slotData.data[slotIn]);
      }
    }
    if (bookingRequest?.status === 1) {
      setAcceptBookingStatus(false);
    } else {
      setAcceptBookingStatus(true);
    }
  }, [slotData, bookingRequest]);

  // handle accept booking request and handle the functionality.
  const acceptBookingRequest = async () => {
    setLoading(true);
    const params = {
      booking_request_id: id,
    };
    try {
      const acceptData = await acceptBooking(params);
      setLoading(false);
      setAcceptBookingStatus(true);
      toastMessageUpdate('success', 'Booking accepted successgully.');
    } catch (error: any) {
      console.log('error', error);
      setLoading(false);
      toastMessageUpdate('error', error?.data?.message);
    }
  };

  return (
    <>
      <Paper withBorder shadow='xs' p='xs'>
        <LoadingOverlay visible={loading} />
        <Flex c={acceptBookingStatus ? 'green.9' : 'yellow.9'} align='center' mt='md'>
          <IconCircleCheck size={rem(32)} />
          <Title mb={-3} ml='xs' order={2}>
            {acceptBookingStatus ? 'Booking Accepted' : 'Waiting for Booking Acceptence'}
            {/* {'Waiting for Booking Acceptence'} */}
          </Title>
        </Flex>
        <Divider my='md' />
        <Title order={3} mb='xs' ml='xs'>
          {serviceInfo[0]?.service}
        </Title>
        <Flex align='center'>
          <Avatar size={90} radius={100} src={avatar} />
          <Flex direction='column'>
            <Text size={20} c='dimmed' ml='xs'>
              {userInfo?.name}
            </Text>
            <Text size={20} c='dimmed' ml='xs'>
              <a style={{ textDecoration: 'none', color: '#868e96' }} href={`mailto:${userInfo?.email}`}>
                {userInfo?.email}
              </a>
            </Text>
            <Text size={20} c='dimmed' ml='xs'>
              <a style={{ textDecoration: 'none', color: '#868e96' }} href={`tel:${userInfo?.mobile}`}>
                {userInfo?.mobile}
              </a>
            </Text>
            <Text size={20} c='dimmed' ml='xs'>
              {userAddress}
            </Text>
          </Flex>
        </Flex>
        <Divider my='xl'></Divider>
        <Flex align='flex-end' justify='space-between' mx='xs' mb='xl'>
          <Flex direction='column'>
            <Title order={3} mb='xs'>
              Booking Time
            </Title>
            {!isSlotLoading ? (
              <Text fw={500} size='md' c='dimmed'>
                {covertTimeFormat(slotDetails?.time_from)} - {covertTimeFormat(slotDetails?.time_to)},&nbsp;
                {'  '}
                {dayjs(bookingRequest?.booking_date).format('MMM DD, YYYY')}
              </Text>
            ) : (
              <Skeleton h={15} visible={true} />
            )}
          </Flex>
          {!acceptBookingStatus && (
            <Button size='md' onClick={acceptBookingRequest} variant='filled'>
              Accept
            </Button>
          )}
        </Flex>
      </Paper>
    </>
  );
};

export default RequestCard;
