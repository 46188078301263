import { createStyles } from '@mantine/core';
import { loginImage } from '../../../assets';

export const useWrapperStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: 25,
    // border: `2px solid ${theme.fn.primaryColor()}`,
    borderRadius: theme.spacing.xl,
    overflow: 'auto',
    minHeight: 700,
    width: '80%',
    backgroundColor: theme.colorScheme === 'light' ? `#F6F6F6` : theme.colors.dark[6],

    [theme.fn.smallerThan(992)]: {
      width: '100%',
      borderRadius: 0,
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
    },
  },
  info: {
    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(80,80,181,0.7642244397759104) 57%, rgba(143,164,170,1) 100%) ,url(${loginImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: 350,
  },

  children: {
    padding: 0,
    backgroundColor: theme.colorScheme === 'light' ? `#F6F6F6` : theme.colors.dark[6],
    [theme.fn.smallerThan(992)]: {
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
    },

    [theme.fn.largerThan(992)]: {
      margin: 'auto',
    },
  },

  largeScreenLogo: {
    [theme.fn.smallerThan(992)]: {
      display: 'none',
    },
  },

  smallScreenLogo: {
    [theme.fn.largerThan(991)]: {
      display: 'none',
    },
  },
}));

export const useLoginStyles = createStyles((theme) => ({
  paper: {
    backgroundColor: theme.colorScheme === 'light' ? `#F6F6F6` : theme.colors.dark[6],
    [theme.fn.smallerThan(992)]: {
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
    },
  },

  submitBtn: {
    fontSize: '25px',
    fontWeight: 'bold',
  },

  controls: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column-reverse',
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },

  pininput: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
