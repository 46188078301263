import { Box, Grid } from '@mantine/core';
import { useContext } from 'react';
import MobileProfileHeader from '../../components/MobileProfileHeader';
import ProviderProfilesContainer from './components/ProviderProfilesContainer';
import ProviderProfilesDrawer from './components/ProviderProfilesDrawer';
import ServiceCardGrid from './components/ServiceCardGrid';
import ServiceImageCarousel from './components/ServiceImageCarousel';
import { HomeContextRefactored } from './home.context';
import { useHomeStyles } from './style';

export default function HomeRefactored() {
  const { showProviderProfilesContainer, showFilter } = useContext(HomeContextRefactored);
  const { classes, cx } = useHomeStyles();

  return (
    <>
      <MobileProfileHeader />

      <Grid p={0} m={0}>
        <Grid.Col lg={showFilter ? 4 : 6} md={showFilter ? 4 : 6} className={classes.leftGridContainer}>
          <ServiceCardGrid />
        </Grid.Col>

        {/* this will appear on desktop devices */}
        {!showProviderProfilesContainer && (
          <Grid.Col lg={showFilter ? 8 : 6} md={showFilter ? 8 : 6} className={cx(classes.carouselGridContainer)} p={0}>
            <Box>
              <ServiceImageCarousel />
            </Box>
          </Grid.Col>
        )}

        {/* this will appear on desktop devices */}
        {showProviderProfilesContainer && (
          <Grid.Col
            p={0}
            m={0}
            className={cx(classes.profilesGridContainer)}
            lg={showFilter ? 8 : 6}
            md={showFilter ? 8 : 6}>
            <ProviderProfilesContainer />
          </Grid.Col>
        )}
      </Grid>

      {/* this will appear on mobile devices */}
      <ProviderProfilesDrawer />
    </>
  );
}
