import { createContext, useState } from 'react';

export const useHomeContext = () => {
  const [showProviderProfilesContainer, setShowProviderProfilesContainer] = useState(false);

  /*
    DESCRIPTION: this will render the filter for providers
  */
  const [showFilter, setShowFilter] = useState(false);

  /*
    DESCRIPTION: these 2 states below will hold the `service_type_id` of
    parent and child services respectively.
    if it holds an empty string, i.e. '' then that means currently no services are selected
  */
  const [selectedParentServiceId, setSelectedParentServiceId] = useState('');
  const [selectedChildServiceId, setSelectedChildServiceId] = useState('');

  const [selectedServiceName, setSelectedServiceName] = useState('');
  const [profilesViewType, setProfilesViewType] = useState<'list' | 'grid'>('list');
  const [withoutRangeSearch, setWithoutRangeSearch] = useState(false);

  return {
    showProviderProfilesContainer,
    setShowProviderProfilesContainer,
    selectedParentServiceId,
    setSelectedParentServiceId,
    selectedChildServiceId,
    setSelectedChildServiceId,
    selectedServiceName,
    setSelectedServiceName,
    showFilter,
    setShowFilter,
    profilesViewType,
    setProfilesViewType,
    withoutRangeSearch,
    setWithoutRangeSearch,
  };
};

export const HomeContextRefactored = createContext({} as ReturnType<typeof useHomeContext>);
