import { createClient as rtcClient, createMicrophoneAndCameraTracks } from 'agora-rtc-react';
import { ClientConfig } from 'agora-rtc-sdk-ng';
import { createClient as rtmClient } from 'agora-rtm-react';

export const appId: any = process.env.REACT_APP_AGORA_ID || 'bd1fb476463f4449b6ed2758a378c87d';
export const appKey: any = '411023380#1195334';
export const appCertificate: any = '172c6a7d4ee04b0e8a06045e109f76d0';
export const config: ClientConfig = { mode: 'rtc', codec: 'vp8' };
export const channelName = 'aidby_chat';
export const videoChannelName = 'aidby_video';
export const useRTMClient = rtmClient(appId);

export const useClient = rtcClient(config);
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

export const token =
  '007eJxTYNj//IWX2z//uB7nK4veLj/7unSF8kRJVq173KeENL3sVQMVGAzMzYwsjJIMzdIsUk2MjQ0sUyyNLExTzAwtTEwsk5PMSoo5UxsCGRkmrlnMwAiFID4rQ2JmSlIlAwMATAAebw==';

export const Chat_App_Temporary_Token =
  '007eJxTYLBxkHfycpN5ttbzprX3BlvjK50mrb/FD0+UFBLOfPJg/ysFBgNzMyMLoyRDszSLVBNjYwPLFEsjC9MUM0MLExPL5CSzvzq8qQ2BjAw3F/ozMjKwMjAyMDGA+AwMAPGmG6o=';

export const Chat_User_Temporary_Token =
  '007eJxTYLimtnZL2J0XB7tWiQhY/Tboq+Y9pBPpUfXY5dj2S3nMnv8UGAzMzYwsjJIMzdIsUk2MjQ0sUyyNLExTzAwtTEwsk5PM7urzpjYEMjL4mtUyMDKwAjEjA4ivwmCWYmBgnGRqoGtqaZCqa2iYmqprmZRkoZuUlmJqapiakmKeYgIARBcmgw==';
