import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, TextInput, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InferType } from 'yup';
import ErrorText from '../../../../../components/ErrorText';
import { createUserOverview, updateUserOverview } from '../../../../../services/userProfiles';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { providerProfileUpdateSkillSchema } from '../../../../../utils/schema/formSchema';
import CustomButton from './CustomButton';
import { useUpdateSkillsStyles } from './style';

export default function UpdateSkills({ updateProviderSkills, close }: any) {
  const [cookies, setCookie] = useCookies(['user_Overview']);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(providerProfileUpdateSkillSchema),
    mode: 'all',
  });

  useEffect(() => {
    reset({
      skills: cookies.user_Overview.skills || '',
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<InferType<typeof providerProfileUpdateSkillSchema>> = async (data) => {
    if (!data.skills) return;
    // console.log(data);

    setIsLoading(true);
    if (data.skills?.slice(-1) === ',') {
      data.skills = data.skills.trim().substring(0, data.skills.length - 1);
    }

    const splittedSkills = data.skills?.split(',');

    if (splittedSkills && splittedSkills.length > 5) {
      setIsLoading(false);
      toastMessageUpdate('error', 'User can add maximum 5 skills');
      return;
    }

    // console.log(splittedSkills);

    const updatedSkillsFormData = new FormData();
    updatedSkillsFormData.append('skills', data.skills);

    if (cookies.user_Overview === 'null') {
      try {
        const userOverview = await createUserOverview(updatedSkillsFormData);

        updateProviderSkills(userOverview.user_profile.skills);
        setCookie('user_Overview', userOverview, { path: '/' });
        close();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const updateData = await updateUserOverview(updatedSkillsFormData);
        console.log(updateData);
        updateProviderSkills(updateData.user_profile.skills.split(','));
        close();
        setCookie('user_Overview', updateData.user_profile, { path: '/' });
      } catch (error) {
        console.log(error);
      }
    }

    setIsLoading(false);
  };

  const { classes: updateSkillsClasses } = useUpdateSkillsStyles();

  return (
    <>
      <Box>
        <Title order={3} ta='center' mb={16}>
          Update skills
        </Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            {...register('skills')}
            spellCheck={false}
            size='md'
            placeholder='Add multiple skills(maximum 5) after adding comma'
            label='Skills'></TextInput>
          <ErrorText errorProperty={errors.skills}></ErrorText>
          <Grid p={0} m={0} mt={20} gutter={0}>
            <Grid.Col pr={2} lg={6} md={6} sm={6} xs={6} className={updateSkillsClasses.buttonGridContainer}>
              <CustomButton variant='gradient' loading={isLoading} type='submit'>
                Save
              </CustomButton>
            </Grid.Col>
            <Grid.Col pl={2} lg={6} md={6} sm={6} xs={6} className={updateSkillsClasses.buttonGridContainer}>
              <CustomButton variant='outline' onClick={close}>
                Cancel
              </CustomButton>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </>
  );
}
