import { useDisclosure } from '@mantine/hooks';
import { createContext, useEffect, useState } from 'react';

export const useServiceInformation = () => {
  const [selectedServiceTypeId, setSelectedServiceTypeId] = useState<any>();
  const [selectedServiceName, setSelectedServiceName] = useState<any>();
  const [filterProfileList, setFilterProfileList] = useState<any>();
  const [showContentFilter, setShowContentFilter] = useState<any>(false);

  /*
  These two states will be used to keep track of which
  service & sub-service (respectively) has been clicked in order to apply styles to them.
  */
  const [activeServiceId, setActiveServiceId] = useState('');
  const [activeSubserviceId, setActiveSubserviceId] = useState('');

  return {
    selectedServiceTypeId,
    setSelectedServiceTypeId,
    selectedServiceName,
    setSelectedServiceName,
    filterProfileList,
    setFilterProfileList,
    showContentFilter,
    setShowContentFilter,
    activeServiceId,
    setActiveServiceId,
    activeSubserviceId,
    setActiveSubserviceId,
  };
};

export const HomeContext = createContext({} as ReturnType<typeof useServiceInformation>);
