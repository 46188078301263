import { Drawer, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useContext } from 'react';
import { HomeContextRefactored } from '../home.context';
import { useProviderProfilesDrawerStyles } from '../style';
import ProviderProfilesContainer from './ProviderProfilesContainer';
import { IconArrowLeft } from '@tabler/icons-react';

export default function ProviderProfilesDrawer() {
  const { selectedChildServiceId, setSelectedChildServiceId, showProviderProfilesContainer } =
    useContext(HomeContextRefactored);
  const theme = useMantineTheme();
  const drawerScreenSize = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const { classes, cx } = useProviderProfilesDrawerStyles();
  return (
    <>
      <Drawer
        className={classes.drawer}
        opened={drawerScreenSize && selectedChildServiceId !== ''}
        onClose={() => {
          setSelectedChildServiceId('');
        }}
        withCloseButton={false}
        position='bottom'
        size='90%'
        overlayProps={{ opacity: 0.5, blur: 4 }}>
        <UnstyledButton onClick={() => setSelectedChildServiceId('')} className={cx(classes.backIconButtonContainer)}>
          <IconArrowLeft className={cx(classes.backIconButton)} />
        </UnstyledButton>
        <ProviderProfilesContainer />
      </Drawer>
    </>
  );
}
