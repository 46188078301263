import { Box, Title } from '@mantine/core';
import UpdateNameRefactored from '../../../customer/Profile/components/UpdateNameRefactored';
import UpdateSkills from './UpdateSkills';

interface Props {
  updateType: 'name' | 'skills' | null;
  close: () => void;
  sendDataToParent: (value: any, type: 'name' | 'skills') => void;
}

/*
    This is a wrapper/container component for the modal and drawer content (not the modal or drawer).
    This will only render the respective component based on the `updateType` prop
*/
export default function EditUserRefactored({ close, sendDataToParent, updateType }: Props) {
  const updateProvider = (updatedData: any) => {
    // console.log(updatedData);

    if (updatedData) {
      switch (updateType) {
        case 'name':
          sendDataToParent(updatedData, 'name');
          break;
        case 'skills':
          sendDataToParent(updatedData, 'skills');
          break;
      }
    }
  };

  return (
    <>
      <Box>
        {updateType === 'name' && <UpdateNameRefactored updateUserName={updateProvider} close={close} />}

        {updateType === 'skills' && <UpdateSkills updateProviderSkills={updateProvider} close={close} />}
      </Box>
    </>
  );
}
