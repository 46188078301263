import { createStyles } from '@mantine/core';

export const useServiceStyles = createStyles((theme) => ({
  list_container: {
    display: 'flex',
    justifyContent: 'space-between',
    listStyle: 'none',
    padding: 0,
    '& > li': {
      cursor: 'pointer',
      margin: 10,
      width: '100%',
    },
  },
  activeDate: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      content: `''`,
      width: 6,
      height: '100%',
      backgroundColor: theme.fn.primaryColor(),
    },
  },
  slot_container: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    li: {
      margin: 10,
      border: `1px solid ${theme.colors.gray[2]}`,
      padding: '7px 12px',
      borderRadius: 8,
      transition: 'all .5s linear',
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.fn.primaryColor(),
        color: 'white',
      },
      '&:hover': {
        backgroundColor: theme.fn.primaryColor(),
        color: 'white',
      },
    },
  },
}));
