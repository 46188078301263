import { Badge, Box, Flex, Group, Text, Title } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import { IconDiscountCheckFilled } from '@tabler/icons-react';
import { useCookies } from 'react-cookie';
import ProfilePhoto from '../../../../components/ProfilePhoto';
import { RoleType } from '../../../../utils';
import renderVerificationStatusText from '../../../../utils/helpers/renderVerificationStatusText';

// profile card ui handling by props.
export default function ProfileCard(props: Record<string, any>) {
  const [cookie, setCookie] = useCookies(['role', 'user_Info']);

  const userIsViewingOwnProfile = +props.userId === +cookie.user_Info.id;
  console.log('user is viewing own profile: ', userIsViewingOwnProfile);

  const renderVerificationBorder = () => {
    switch (props?.user?.type) {
      case 1:
        return props.verification?.onfido_result === 'clear' ? true : false;
      case 2:
        return props.verification?.background_verification ? true : false;
      case 3:
        return props?.verification?.business_license_verification ? true : false;
    }
  };

  const renderBlueTick = () => {
    switch (props?.user?.type) {
      case 1:
        return props.verification?.onfido_result === 'clear' ? true : false;
      case 2:
        return props.verification?.background_verification ? true : false;
      case 3:
        return props?.verification?.business_license_verification ? true : false;
    }
  };

  console.log(props?.verification);

  return (
    <>
      <Box ta='center'>
        <Flex justify='center' mt={40}>
          <ProfilePhoto
            type='renderWithoutEdit'
            src={props?.user_profile?.image}
            showGradientBackground
            showVerificationBorder={renderVerificationBorder()}
            sizes={{ xl: 160, lg: 150, md: 100, sm: 120, xs: 120 }}
          />
        </Flex>
        <Flex justify='center' align='center' gap={5}>
          <Title order={4} fz='lg' fw={600}>
            {upperFirst(props['name'])}
          </Title>
          {renderBlueTick() && <IconDiscountCheckFilled size={20} style={{ color: '#39A7FF' }} />}
        </Flex>

        {props?.user?.type === RoleType.service_provider && (
          <Text ta='center' size='sm' weight={600} fz={14} color='dimmed'>
            Background Verification :{' '}
            <Text weight={500} fz={14} span c={props?.verification?.background_verification ? 'green' : 'dimmed'}>
              {
                renderVerificationStatusText({
                  data: props?.verification,
                  // data: {
                  //   background_verification: false,
                  //   business_license_verification: null,
                  //   drug_test_verification: true,
                  //   onfido_result: 'clear',
                  //   type: '1',
                  //   user_verification: true,
                  // },
                  role: props?.user?.type,
                  userIsViewingOwnProfile,
                }).backgroundVerification
              }
            </Text>
          </Text>
        )}

        {props?.user?.type !== RoleType.organization_service_provider && (
          <Text ta='center' size='sm' weight={600} fz={14} color='dimmed'>
            Identity Verification :{' '}
            <Text
              weight={500}
              fz={14}
              span
              c={
                userIsViewingOwnProfile
                  ? cookie.user_Info.onfido_result === 'clear'
                    ? 'green'
                    : 'dimmed'
                  : props?.verification?.onfido_result === 'clear'
                  ? 'green'
                  : 'dimmed'
              }>
              <>
                {userIsViewingOwnProfile ? (
                  <>
                    {cookie.user_Info.onfido_result === 'clear'
                      ? 'Verified'
                      : cookie.user_Info.onfido_result === 'consider'
                      ? 'N/A'
                      : cookie.user_Info.onfido_check_id
                      ? 'Pending'
                      : 'N/A'}
                  </>
                ) : (
                  <>
                    {
                      renderVerificationStatusText({
                        data: props?.verification,
                        // data: {
                        //   background_verification: false,
                        //   business_license_verification: null,
                        //   drug_test_verification: true,
                        //   onfido_result: 'clear',
                        //   type: '1',
                        //   user_verification: true,
                        // },
                        role: props?.user?.type,
                      }).identityVerification
                    }
                  </>
                )}
              </>
            </Text>
          </Text>
        )}

        {props?.user?.type == RoleType.organization_service_provider && (
          <>
            <Text ta='center' size='sm' weight={600} fz={14} color='dimmed'>
              License verification :{' '}
              <Text
                weight={500}
                fz={14}
                span
                c={props?.verification?.business_license_verification ? 'green' : 'dimmed'}>
                {
                  renderVerificationStatusText({
                    data: props.verification,
                    // data: {
                    //   background_verification: false,
                    //   business_license_verification: false,
                    //   drug_test_verification: true,
                    //   onfido_result: 'clear',
                    //   type: null,
                    //   user_verification: true,
                    // },
                    role: props?.user?.type,
                    userIsViewingOwnProfile,
                  }).licenseVerification
                }
              </Text>
            </Text>
          </>
        )}

        <Text size='sm' c='dimmed'>
          {props?.['user_profile']?.['intro']}
        </Text>

        <Group position='center' mb='md'>
          <Text size='sm' color='dimmed'>
            {props?.['user_profile']?.['skills']?.split(',')?.map((item: string, index: number) => (
              <Badge key={index} m={5}>
                {item}
              </Badge>
            ))}
          </Text>
        </Group>
      </Box>
    </>
  );
}
