import { useQuery } from '@tanstack/react-query';
import { catchHandler } from './../utils/helpers/index';
import { axiosTokenInst } from './axios.config';
import { useContext } from 'react';

export const subscriptionList = catchHandler(async (userType: any) => {
  return await axiosTokenInst.get(`/subscription`, {
    params: { user_type: userType },
  });
});

export const subscriptionLimit = catchHandler(async () => {
  return await axiosTokenInst.get(`/subscription/limit`);
});

export const useSubscriptionList = (userType: any) => {
  return useQuery({
    queryKey: ['subscription'],
    queryFn: async () => await subscriptionList(userType),
  });
};

export const useSubscriptionLimitCheck = (enabled: boolean) => {
  return useQuery({
    queryKey: ['subscriptionLimit'],
    queryFn: async () => await subscriptionLimit(),
    enabled: enabled,
    // refetchInterval: 5000,
  });
};
