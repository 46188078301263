import { Checkbox, Flex, Text } from '@mantine/core';
import renderVerificationStatusText from '../../../../../utils/helpers/renderVerificationStatusText';
import { useCookies } from 'react-cookie';

interface Props {
  data: {
    user_verification: boolean;
    background_verification: boolean;
    drug_test_verification: boolean;
    business_license_verification: any;
    type: any;
  };
  backgroundCheckType: string;
}

/*
  This is a representational (view) component and this is only applicable for regular service providers.
*/
export default function SubmitEmail({ data, backgroundCheckType }: Props) {
  const [cookies] = useCookies(['role']);
  return (
    <>
      {data ? (
        <>
          <Flex direction='column' style={{ justifyContent: 'center' }}>
            <Text display='flex' ta='center' style={{ justifyContent: 'center', flexDirection: 'column' }} fz={17}>
              Submitted email for background check.
            </Text>
            <Text ta='center' size='sm' mt='md' weight={600} fz={15}>
              Status :{' '}
              <Text weight={600} fz={15} span c={data.background_verification ? 'green' : 'red'}>
                {renderVerificationStatusText({ data: data, role: cookies.role }).backgroundVerification}
              </Text>
            </Text>
          </Flex>
        </>
      ) : (
        <>
          <Flex direction='column' style={{ justifyContent: 'center' }}>
            <Checkbox
              label='Submit email for background check.'
              mt='10px'
              disabled
              checked={+backgroundCheckType === 1}
              radius='sm'
              size='sm'
            />
            <Text pt='sm' fz={12}>
              <span style={{ color: 'red' }}>*</span> By checking the check box you consent to background checks and to
              receive written communications, emails, phone calls and text messages.
            </Text>
          </Flex>
        </>
      )}
    </>
  );
}
