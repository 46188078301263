import { Box, LoadingOverlay } from '@mantine/core';

export default function GlobalLoader() {
  return (
    <>
      <Box style={{ minHeight: '100svh', position: 'fixed', inset: 0, zIndex: 500 }}>
        <LoadingOverlay visible={true} overlayBlur={1} />
      </Box>
    </>
  );
}
