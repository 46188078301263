import { useQuery } from '@tanstack/react-query';
import { axiosTokenInst } from './axios.config';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';

interface IServiceProvider {
  range: string;
  service_type_id: string;
}

export const servicesTypeList = async (parent_id?: string) => {
  return await axiosTokenInst.get('/service-types', {
    params: { parent_id },
  });
};

export const useServiceTypeList = (parentfn: (data: any) => void, parent_id?: string, isEnable = true) => {
  return useQuery({
    queryKey: ['service-list', { parent_id }],
    queryFn: async () => await servicesTypeList(parent_id),
    enabled: isEnable,
    onSuccess: (data) => {
      console.log(data);
      parentfn(data);
    },
  });
};

export const useServicesList = (parent_id?: string, isEnable = true) => {
  return useQuery({
    queryKey: parent_id === '' ? ['service-list'] : ['service-list', { parent_id }],
    queryFn: async () => await servicesTypeList(parent_id),
    enabled: isEnable,
    cacheTime: Infinity,
  });
};

export const servicePoviderList = async (data: IServiceProvider) => {
  return await axiosTokenInst.post('/geolocation', data);
};

export const serviceByUser = async (id: string) => {
  return await axiosTokenInst.get(`/service/user-specific/${id}`);
};

// export function useServicesType(id?: string) {
//   return useQuery({
//     queryKey: ['service-types'],
//     queryFn: async () => {
//       return await servicesTypeList();
//     },
//   });
// }

export const useServiceProviderList = (data: IServiceProvider) => {
  return useQuery({
    queryKey: ['service_provider_by_type', data.service_type_id],
    queryFn: async () => {
      const response: any = await servicePoviderList(data);
      // if (!response?.['success']) {
      //   if (response?.['message'] === 'user profile not found') {
      //     toastMessageUpdate('info', 'Please Complete your profile details.');
      //   } else {
      //     toastMessageUpdate('info', response?.['message']);
      //   }
      // }
      return response;
    },
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useServiceByUser = (id: string) => {
  return useQuery({
    queryKey: ['service-by-user', id],
    queryFn: async () => await serviceByUser(id),
  });
};
