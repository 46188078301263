import { Avatar, Button, Card, Flex, Grid, Group, Text } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import { IconCurrencyDollar, IconRoute, IconUserCircle, IconUserStar } from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { NavLink, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../../../context/themeContext';
import { useSubscriptionLimitCheck } from '../../../../../services/subscription.api';
import { customerRole } from '../../../../../utils';
import generateRandomString from '../../../../../utils/helpers/generateRandomAlphabetStrings';
import { ICard } from '../utils/Card.interface';
import { useFreelanceStyles, useGridCardStyles } from './style';
import ProfilePhoto from '../../../../../components/ProfilePhoto';

export function GridCard(props: ICard) {
  const {
    image,
    name,
    description,
    email_verified_at,
    city,
    rate,
    service_id,
    service_type_id,
    users_id,
    total_reviews,
    distance_in_miles,
    service,
  } = props;
  const [cookie, setCookie] = useCookies(['role', 'user_Info']);
  const { classes } = useFreelanceStyles();
  const navigate = useNavigate();
  const { setChatType, setChannelId, setChatRecieverId, subscriptionExhausted, setSubscriptionExhausted } =
    useContext(ThemeContext);
  const { data: subscriptionLimitData } = useSubscriptionLimitCheck(true);

  useEffect(() => {
    if (subscriptionLimitData) {
      setSubscriptionExhausted(subscriptionLimitData);
    }
  }, [subscriptionLimitData]);

  const sendChatInfo = (serviceCreatorId: any) => {
    // if (subscriptionLimitData?.data?.chat_exausted) {
    //   toastMessageUpdate('info', subscriptionExhausted?.message);
    //   return;
    // }
    if (customerRole.includes(+cookie.role)) {
      console.log('peerId', '' + serviceCreatorId + cookie?.user_Info?.id);
      setChatRecieverId(serviceCreatorId);
      setChannelId('' + serviceCreatorId + cookie?.user_Info?.id);
      setChatType('userChat');
      navigate('/chat');
    }
  };

  const navigateBookingPage = () => {
    // if (subscriptionLimitData?.data?.service_exausted) {
    //   toastMessageUpdate('info', subscriptionExhausted?.message);
    //   return;
    // }
    navigate(
      `/book-services/${encodedIdWithString}?service_type=${service_type_id}&service_id=${service_id}&name=${btoa(
        service,
      )}`,
    );
  };

  const [encodedIdWithString, setEncodedIdWithString] = useState('');
  useEffect(() => {
    setEncodedIdWithString(btoa(generateRandomString(16) + users_id + generateRandomString(16)));
  }, []);

  const { classes: gridCardClasses } = useGridCardStyles();

  return (
    <Card withBorder className={classes.card} h={'100%'}>
      <Flex direction='column' h={'100%'} justify='space-between' gap={6}>
        <Card.Section ta='center'>
          {/* <Avatar
            color='cyan'
            radius='200px'
            size={100}
            m='auto'
            src={image ? process.env.REACT_APP_IMAGE_URL + 'service_images/' + image : ''}></Avatar> */}
          <Flex justify='center'>
            <ProfilePhoto
              src={image ? process.env.REACT_APP_IMAGE_URL + 'service_images/' + image : ''}
              type='renderWithoutEdit'
              sizes={{
                xs: 80,
                sm: 120,
                md: 100,
                lg: 100,
                xl: 100,
              }}
            />
          </Flex>
          <Text fz={18} fw={600} ta='center'>
            {upperFirst(name)}
          </Text>
        </Card.Section>

        <Card.Section>
          <Flex justify='center' align='center' direction='column' gap={4} className={gridCardClasses.flexContainerOne}>
            <Group spacing={2}>
              {distance_in_miles !== null && (
                <Flex align='center' gap={4}>
                  <IconRoute className='routeIcon' />
                  <Text className='distanceText'>
                    {parseFloat(distance_in_miles).toFixed(2) === '0.00'
                      ? '0.00'
                      : parseFloat(distance_in_miles).toFixed(2)}{' '}
                    Miles Distance
                  </Text>
                </Flex>
              )}
            </Group>
            <Flex align='center' gap={4}>
              {props.type === 2 ? <IconUserCircle size={16} stroke={'#c1c1c1'} /> : <IconUserStar size={16} />}

              <Text lh={1} className='profileTypeText'>
                {props.type === 2 ? 'Individual' : 'Organization'}
              </Text>
            </Flex>

            <Flex align='center'>
              <IconCurrencyDollar className='currencyIcon' />
              <Text lh={1} className='priceRateText'>
                {rate}/hr
              </Text>
            </Flex>
          </Flex>
        </Card.Section>

        <Card.Section>
          <Grid gutter={3} px={5}>
            <Grid.Col xs={6}>
              <Button
                fullWidth
                variant='light'
                size='xs'
                radius='sm'
                component={NavLink}
                to={`/user/${encodedIdWithString}?service_type=${service_type_id}&service_id=${service_id}&name=${btoa(
                  service,
                )}`}
                className={classes.button}>
                View Profile
              </Button>
            </Grid.Col>
            <Grid.Col xs={6}>
              <Button
                fullWidth
                variant='light'
                size='xs'
                radius='sm'
                className={classes.button}
                onClick={() => {
                  navigateBookingPage();
                }}
                // component={NavLink}
                // to={`/book-services/${users_id}?service_type=${service_type_id}&service_id=${service_id}`}
              >
                Book Now
              </Button>
            </Grid.Col>
            <Grid.Col>
              <Button
                fullWidth
                variant='light'
                size='xs'
                radius='sm'
                className={classes.button}
                onClick={() => {
                  sendChatInfo(users_id);
                }}>
                Interview
              </Button>
            </Grid.Col>
          </Grid>
        </Card.Section>
      </Flex>
    </Card>
  );
}
