import { ColorScheme, createStyles, keyframes, rem } from '@mantine/core';

export const useProviderProfilesStyles = createStyles((theme) => ({
  container: {
    paddingInline: 8,
    marginTop: 8,

    [theme.fn.smallerThan(992)]: {
      padding: 0,
    },
  },

  serviceHeaderText: {
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'center',
      marginBottom: 4,
    },
  },

  urgentBookingButton: {
    width: rem(160),
  },

  dividerGridContainer: {
    display: 'none',
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  urgentBookingButtonGridContainer: {
    marginTop: 4,
    [theme.fn.smallerThan('md')]: {
      marginTop: 16,
    },
  },

  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    color: '#747474',
    backgroundColor: '#0000000a',
    fontSize: 20,
  },
}));

export const useFilterButtonStyles = createStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    borderRadius: rem(4),
    // color: '#9e9e9e',
    backgroundColor: theme.colorScheme === 'light' ? '#feede5' : theme.colors.dark[6],
    fontSize: 18,
    color: theme.colorScheme === 'light' ? 'black' : '#959595',

    '&:hover': {
      // color: '#feede5',
      backgroundColor: theme.colorScheme === 'light' ? '#feede5' : theme.colors.dark[7],
    },

    '& svg': {
      width: 20,
      height: 20,
    },

    [theme.fn.smallerThan(1170)]: {
      fontSize: 16,

      '& svg': {
        width: 18,
        height: 18,
      },
    },
  },
  selectedButton: {
    color: 'rgb(254 125 73 / 80%)',
    '&:hover': {
      color: 'rgb(254 125 73 / 100%)',
    },
  },
}));

export const useProfileViewButtonStyles = createStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    borderRadius: rem(4),
    // color: theme.colorScheme === 'light' ? 'black' : '#959595',
    backgroundColor: theme.colorScheme === 'light' ? '#feede5' : theme.colors.dark[6],
    fontSize: 18,
    cursor: 'default',

    '&:hover': {
      color: 'black',
      backgroundColor: theme.colorScheme === 'light' ? '#feede5' : theme.colors.dark[7],
    },

    '& svg': {
      width: 24,
      height: 24,
      // stroke: theme.colorScheme === 'light' ? 'black' : '#959595',
      cursor: 'pointer',
    },

    [theme.fn.smallerThan(1170)]: {
      fontSize: 16,

      '& svg': {
        width: 18,
        height: 18,
      },
    },
  },
}));

export const useUrgentBookingButtonStyles = createStyles((theme) => ({
  button: {
    width: rem(160),
    height: rem(40),
    borderRadius: rem(4),
    fontSize: rem(16),

    [theme.fn.smallerThan('sm')]: {
      fontSize: 14,
    },
  },

  headerText: {
    color: theme.colorScheme === 'light' ? 'black' : '#ececec',
  },
}));

const renderServiceCardBoxShadow = (colorScheme: ColorScheme, selected?: boolean) => {
  return {
    offset: colorScheme === 'light' ? 2 : 2,
    blur: colorScheme === 'light' ? 4 : 4,
    spread: colorScheme === 'light' ? 0 : 0,
    color:
      colorScheme === 'light'
        ? selected
          ? 'rgb(254 119 32 / 80%)'
          : 'rgb(254 119 32 / 45%)'
        : selected
        ? 'rgb(254 119 32 / 60%)'
        : 'rgb(254 119 32 / 20%)',
  };
};

export const useNoProfileFoundStyles = createStyles((theme) => ({
  container: {
    padding: rem(10),
    marginTop: rem(5),
    border: theme.colorScheme === 'light' ? '1px solid #dee2e6' : '1px solid #4d4d4d',
    borderRadius: rem(5),
    userSelect: 'none',
    color: '#868e96',
  },

  headerText: {
    fontSize: rem(20),
    fontWeight: 600,
  },

  contentText: {
    fontSize: rem(16),
    marginTop: rem(10),
  },

  emailText: {
    transition: '250ms',
    color: '#fda223',

    '&:hover': {
      color: '#fe7720',
      transition: '250ms',
    },
  },
}));

export const useServiceCardStyles = createStyles((theme) => ({
  card: {
    height: '100%',
    backgroundColor: theme.colorScheme === 'light' ? '#feede5' : theme.colors.dark[5],
    cursor: 'pointer',
    transition: '300ms',
    userSelect: 'none',

    '&:hover': {
      transition: '300ms',
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
      boxShadow: `
      0 -${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} ${rem(
        renderServiceCardBoxShadow(theme.colorScheme).blur,
      )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
        renderServiceCardBoxShadow(theme.colorScheme).color
      },

      ${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} 0 ${rem(
        renderServiceCardBoxShadow(theme.colorScheme).blur,
      )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
        renderServiceCardBoxShadow(theme.colorScheme).color
      },

      0 ${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} ${rem(
        renderServiceCardBoxShadow(theme.colorScheme).blur,
      )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
        renderServiceCardBoxShadow(theme.colorScheme).color
      },

      -${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} 0 ${rem(
        renderServiceCardBoxShadow(theme.colorScheme).blur,
      )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
        renderServiceCardBoxShadow(theme.colorScheme).color
      }
      `,
    },
  },

  selectedParentServiceCard: {
    boxSizing: 'border-box',
    border: '0.5px solid #fe7720',
    transition: '300ms',
  },

  selectedChildServiceCard: {
    backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
    boxShadow: `
      0 -${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} ${rem(
      renderServiceCardBoxShadow(theme.colorScheme).blur,
    )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
      renderServiceCardBoxShadow(theme.colorScheme, true).color
    },

      ${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} 0 ${rem(
      renderServiceCardBoxShadow(theme.colorScheme).blur,
    )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
      renderServiceCardBoxShadow(theme.colorScheme, true).color
    },

      0 ${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} ${rem(
      renderServiceCardBoxShadow(theme.colorScheme).blur,
    )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
      renderServiceCardBoxShadow(theme.colorScheme, true).color
    },

      -${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} 0 ${rem(
      renderServiceCardBoxShadow(theme.colorScheme).blur,
    )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
      renderServiceCardBoxShadow(theme.colorScheme, true).color
    }
      `,
    transition: '300ms',

    '&:hover': {
      transition: '300ms',
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
      boxShadow: `
      0 -${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} ${rem(
        renderServiceCardBoxShadow(theme.colorScheme).blur,
      )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
        renderServiceCardBoxShadow(theme.colorScheme, true).color
      },

      ${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} 0 ${rem(
        renderServiceCardBoxShadow(theme.colorScheme).blur,
      )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
        renderServiceCardBoxShadow(theme.colorScheme, true).color
      },

      0 ${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} ${rem(
        renderServiceCardBoxShadow(theme.colorScheme).blur,
      )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
        renderServiceCardBoxShadow(theme.colorScheme, true).color
      },

      -${rem(renderServiceCardBoxShadow(theme.colorScheme).offset)} 0 ${rem(
        renderServiceCardBoxShadow(theme.colorScheme).blur,
      )} ${rem(renderServiceCardBoxShadow(theme.colorScheme).spread)} ${
        renderServiceCardBoxShadow(theme.colorScheme, true).color
      }
      `,
    },
  },
}));

export const useServiceCardGridStyles = createStyles((theme) => ({
  gridContainer: {
    [theme.fn.smallerThan('xs')]: {
      flexBasis: '50%',
      flexShrink: 0,
      maxWidth: '50%',
      flexGrow: 0,
    },
  },
}));

export const useProviderProfilesContainerStyles = createStyles((theme) => ({
  promotionalBannerGridContainer: {
    [theme.fn.smallerThan(992)]: {
      display: 'none',
    },
  },

  filterHeading: {
    margin: `${rem(8)} 0 0 ${rem(8)}`,
    textAlign: 'left',
    [theme.fn.smallerThan('md')]: {
      textAlign: 'center',
    },
  },

  filterProfileGridContainer: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  filterProfileGridContainerHide: {
    display: 'none',
  },

  backIconButtonContainer: {
    color: '#888888',
  },

  backIconButton: {
    width: 20,
    height: 20,
  },
}));

export const useProviderProfilesDrawerStyles = createStyles((theme) => ({
  drawer: {
    display: 'block',
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },

  backIconButtonContainer: {
    color: '#888888',
  },

  backIconButton: {
    width: 20,
    height: 20,
  },
}));

const enum BoxShadow {
  offset = 4,
  blur = 16,
  spread = 0,
  color = 'rgba(0, 0, 0, 0.05)',
}

export const comeFromRight = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate3d(100%, 0, 0)',
  },
  '100%': {
    opacity: 1,
    transform: 'translate3d(0%, 0, 0)',
  },
});

export const useHomeStyles = createStyles((theme) => ({
  leftGridContainer: {
    padding: `0 ${rem(8)} 0 0`,

    [theme.fn.smallerThan('md')]: {
      padding: `0 ${rem(16)} 0 ${rem(16)}`,
    },
  },

  carouselGridContainer: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  profilesGridContainer: {
    height: `calc(100lvh - 162px)`,
    backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[5],
    animation: `${comeFromRight} 350ms ease-in-out`,
    boxShadow: `
      ${rem(BoxShadow.offset)} 0 ${rem(BoxShadow.blur)} ${rem(BoxShadow.spread)} ${rem(BoxShadow.color)},
      -${rem(BoxShadow.offset)} 0 ${rem(BoxShadow.blur)} ${rem(BoxShadow.spread)} ${rem(BoxShadow.color)},
      0 ${rem(BoxShadow.offset)} ${rem(BoxShadow.blur)} ${rem(BoxShadow.spread)} ${rem(BoxShadow.color)},
      0 -${rem(BoxShadow.offset)} ${rem(BoxShadow.blur)} ${rem(BoxShadow.spread)} ${rem(BoxShadow.color)}
    `,

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  expCarouselGridContainerVisible: {
    opacity: 1,
    transition: '300ms',
  },

  expCarouselGridContainerInvisible: {
    opacity: 0,
    transition: '300ms',
  },
}));

export const usePromotionalBannerStyles = createStyles((theme) => ({
  serviceName: {
    fontSize: 23,
    fontWeight: 600,
    color: 'black',

    [theme.fn.smallerThan(1185)]: {
      fontSize: 18,
    },

    // textOverflow: 'ellipsis',
    // wordWrap: 'break-word',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // WebkitLineClamp: 2,
    // lineClamp: 2,
  },
}));

export const useProviderFilterStyles = createStyles((theme) => ({
  label: {
    fontSize: rem(16),
    fontWeight: 600,
    paddingBottom: `${rem(7)}`,
    color: theme.colorScheme === 'light' ? '#212529' : '#ced4da',
  },

  container: {
    padding: `${rem(0)} ${rem(8)}`,
    [theme.fn.smallerThan('md')]: {
      padding: `${rem(0)} ${rem(0)}`,
    },
  },

  formContainer: {
    marginTop: 16,
  },

  heading: {
    textAlign: 'left',
    [theme.fn.smallerThan('md')]: {
      textAlign: 'center',
    },
  },
}));

export const useChildServicesScrollAreaStyles = createStyles((theme) => ({
  root: {
    borderRadius: 6,
    maxHeight: 'calc(100lvh - 365px)',
    [theme.fn.smallerThan('md')]: {
      borderRadius: 6,
      maxHeight: '100svh',
    },
  },
}));

export const useParentServicesScrollAreaStyles = createStyles((theme) => ({
  root: {
    borderRadius: 6,
    maxHeight: 'calc(100lvh - 253px)',
    [theme.fn.smallerThan('md')]: {
      borderRadius: 6,
      maxHeight: '100svh',
    },
  },
}));

export const useProviderProfilesScrollAreaStyles = createStyles((theme) => ({
  root: {
    borderRadius: 6,
    // maxHeight: '560px',
    maxHeight: `calc(100lvh - 370px)`,
    [theme.fn.smallerThan(992)]: {
      borderRadius: 6,
      maxHeight: `calc(100svh - 305px)`,
    },
  },
}));

export const useProviderFilterScrollAreaStyles = createStyles((theme) => ({
  root: {
    maxHeight: 'calc(100lvh - 320px)',
  },
}));

export const useBackButtonStyles = createStyles((theme) => ({
  backButton: {
    position: 'absolute',
    top: 60,
    zIndex: 1,
    color: 'rgb(254 119 32 / 85%)',
    '&:hover': {
      color: 'rgb(254 119 32 / 100%)',
    },
  },
}));
