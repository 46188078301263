import dayjs from 'dayjs';
import { slotList } from '../../services/booking';

export default async function getNextAvailableSlotId() {
  const res = await slotList();
  const nextAvailableSlotId = res.data.find(
    (slot: any) => slot.time_from.split(':')[0] > dayjs(new Date()).format('HH'),
  ).slot_id;
  return nextAvailableSlotId;
}
