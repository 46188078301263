import { MantineStyleSystemProps, Text } from '@mantine/core';

interface Props {
  errorProperty: any;
}

export default function ErrorText({ errorProperty }: Props & MantineStyleSystemProps) {
  if (!errorProperty) return null;

  return (
    <>
      <Text fz={14} pl={5} fw={500} color='red'>
        {errorProperty.message}
      </Text>
    </>
  );
}
