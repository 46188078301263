import {
  ActionIcon,
  Drawer,
  Flex,
  Grid,
  LoadingOverlay,
  ScrollArea,
  Table,
  Title,
  Tooltip,
  UnstyledButton,
  createStyles,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowNarrowLeft, IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAllServices } from '../../../../services/service';
import { rightToLeftAnimation } from '../../MyBooking';
import { useBannerStyles } from '../Profile/components/style';
import DeleteModal from './components/DeleteModal';
import ServiceDiv from './components/ServiceDiv';
import { ServiceContext, useService } from './servicesContext';
import { useRowElStyles } from './styles';
import React from 'react';
import GlobalLoader from '../../../../components/GlobalLoader';

const useStyle = createStyles((theme) => ({
  parent_row: {
    'td:first-of-type': {
      fontWeight: 600,
      fontSize: 16,
      color: theme.colorScheme === 'light' ? 'black' : theme.colors.gray[4],
    },
  },
  child_row: {
    'td:first-of-type': {
      paddingLeft: '15px',
      color: theme.colorScheme === 'light' ? 'black' : theme.colors.gray[5],
      fontSize: 14,
    },
  },

  activeChildRow: {
    td: {
      backgroundColor: theme.colorScheme === 'light' ? '#FCF5ED' : '#1d1e30',
    },
    'td:first-of-type': {
      paddingLeft: '15px',
      color: theme.colorScheme === 'light' ? 'black' : theme.colors.gray[5],
      fontSize: 14,
    },
  },

  input: {
    border: '0.0625rem solid #ced4da',
    backgroundColor: '#fff',
  },

  gridCol: {
    animation: `${rightToLeftAnimation} 0.35s linear`,
  },
}));

const RowEl = (element: any) => {
  const { classes } = useStyle();
  const { open, opened, setSelectedService, selectedService, buttonType, setButtonType, deleteOpen } =
    useContext(ServiceContext);
  const mantinetheme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.sm})`);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { theme } = useBannerStyles();

  const editHandler = (type: string) => {
    open();
    setSelectedService(element?.createdDataInfo);
    setButtonType(type);
  };

  const createHandler = (type: string) => {
    open();
    setSelectedService(element);
    setButtonType(type);
  };

  const removeItem = async (type: string) => {
    setSelectedService(element?.createdDataInfo);
    deleteOpen();
    setButtonType(type);
  };

  const { classes: rowElClasses } = useRowElStyles();
  return (
    <>
      <tr
        key={element.service_type_id}
        className={
          element?.parent
            ? classes.parent_row
            : element.service_type_id === selectedService?.service_type_id
            ? classes.activeChildRow
            : classes.child_row
        }>
        <td>{element.service}</td>
        <td>{element.parent_id && element?.createdDataInfo?.['rate'] ? `$${element?.createdDataInfo['rate']}` : ''}</td>

        <td>
          <>
            {element?.createdDataInfo ? (
              <Flex
                className={rowElClasses.buttonFlexContainer}
                style={{ display: element.parent_id ? 'flex' : 'none' }}>
                <>
                  <Tooltip label='Edit' color='#fe7720'>
                    <UnstyledButton
                      className={rowElClasses.buttonContainerForEditAndCreate}
                      onClick={() => editHandler('edit')}>
                      <IconPencil color='white' size={rem(16)} stroke={1.8} />
                    </UnstyledButton>
                  </Tooltip>
                  <Tooltip label='Delete' color='#fe7720'>
                    <UnstyledButton
                      className={rowElClasses.buttonContainerForDelete}
                      onClick={() => removeItem('delete')}>
                      <IconTrash color='gray' size={rem(20)} stroke={1.8} />
                    </UnstyledButton>
                  </Tooltip>
                </>
              </Flex>
            ) : (
              <Flex
                className={rowElClasses.buttonFlexContainer}
                style={{ display: element.parent_id ? 'flex' : 'none' }}>
                <>
                  <Tooltip label='Create' color='#fe7720'>
                    <UnstyledButton
                      className={rowElClasses.buttonContainerForEditAndCreate}
                      onClick={() => createHandler('create')}>
                      <IconPlus color='white' size={rem(16)} stroke={1.8} />
                    </UnstyledButton>
                  </Tooltip>
                </>
              </Flex>
            )}
          </>
        </td>
      </tr>
    </>
  );
};

function ServicesList({
  servicesList,
  userServices,
  setUserServices,
  isLoading,
}: {
  servicesList: any[];
  userServices: Record<any, any>;
  setUserServices: any;
  isLoading: any;
}) {
  const mantinetheme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.sm})`);
  const { close, opened, selectedService, buttonType, deleteClose, deleteOpened } = useContext(ServiceContext);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  // console.log(servicesList);

  const rows = useMemo(
    () =>
      servicesList.map((element: any, index: number) => {
        const createdDataIn = userServices.findIndex((ele: any) => ele.service_type_id === element.service_type_id);

        if (element.child?.length) {
          return (
            <React.Fragment key={index}>
              <RowEl
                {...element}
                key={element?.service_type_id}
                createdDataInfo={createdDataIn > -1 ? userServices[createdDataIn] : null}
                isParentAdd={true}
              />
              {element.child.map((item: any) => {
                const createdDataChildIn = userServices.findIndex(
                  (ele: any) => ele.service_type_id === item.service_type_id,
                );

                return (
                  <RowEl
                    {...item}
                    key={item?.service_type_id}
                    createdDataInfo={createdDataChildIn > -1 ? userServices[createdDataChildIn] : null}
                    isParentAdd={createdDataIn > -1 ? true : false}
                  />
                );
              })}
            </React.Fragment>
          );
        }
        return (
          <RowEl
            {...element}
            createdDataInfo={createdDataIn > -1 ? userServices[createdDataIn] : null}
            key={element?.service_type_id}
            isParentAdd={true}
          />
        );
      }),
    [servicesList, userServices],
  );

  const { classes } = useStyle();

  return (
    <>
      {/* <ServiceModal setUserServices={setUserServices} /> */}
      {/* <ServiceDiv setUserServices={setUserServices} /> */}
      <DeleteModal setUserServices={setUserServices} />
      <Grid>
        <Grid.Col span={opened ? 9 : 12}>
          {!isMobile && (
            <Title order={2} c='orange.8' fw={600} mb={20}>
              Services
            </Title>
          )}
          <ScrollArea.Autosize
            mah={isTablet ? '100%' : 700}
            offsetScrollbars
            style={{
              borderRadius: 5,
              // boxShadow: 'inset 0px 25px 20px -21px rgba(0,0,0,0.1)',
            }}>
            {isLoading ? <GlobalLoader /> : null}
            <Table verticalSpacing='sm' fontSize='md'>
              {/* <LoadingOverlay visible={isLoading} /> */}
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: theme.colorScheme === 'light' ? '#f9f9f9' : theme.colors.dark[5],
                }}>
                <tr>
                  <th>Service</th>
                  {/* <th style={{ display: `${mobile ? 'none' : ''}` }}>Rate</th> */}
                  <th>Rate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea.Autosize>
        </Grid.Col>

        {opened && (isMobile || isTablet) ? (
          <Drawer
            opened={opened}
            onClose={close}
            size='75%'
            position='bottom'
            padding={0}
            overlayProps={{ opacity: 0.5, blur: 4 }}>
            <Drawer.Header>
              <IconArrowNarrowLeft className='mobileBackButton' onClick={close} />
            </Drawer.Header>
            <ServiceDiv setUserServices={setUserServices} />
          </Drawer>
        ) : (
          opened && (
            <Grid.Col span={3} className={classes.gridCol}>
              <ServiceDiv setUserServices={setUserServices} />
            </Grid.Col>
          )
        )}
      </Grid>
    </>
  );
}

export default function Services({ tabChangeService }: any) {
  const [cookies] = useCookies(['user_Info']);
  const { data, isLoading } = useAllServices(cookies?.user_Info?.id);
  const [userServices, setUserServices] = useState<any[]>([]);
  useEffect(() => {
    if (data?.data?.userServices) {
      const userServicesArr: any[] = [];
      Object.entries(data?.data?.userServices)?.forEach(([key, item]: any) => {
        userServicesArr.push(item);
        if (item?.child) {
          userServicesArr.push(...item.child);
        }
      });
      setUserServices(userServicesArr);
    }
  }, [data]);
  return (
    <>
      <ServiceContext.Provider value={useService()}>
        <ServicesList
          setUserServices={setUserServices}
          userServices={userServices?.length ? userServices : []}
          servicesList={data?.data?.allServices || []}
          isLoading={isLoading}
        />
      </ServiceContext.Provider>
    </>
  );
}
