import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Grid, Group, Menu, Paper, PasswordInput, Text, Title, Tooltip } from '@mantine/core';
import { IconCircleCheck, IconCircleCheckFilled, IconEye, IconEyeOff, IconHelpOctagon } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { resetpasswordSchema } from '../../utils/schema/formSchema';
import { useLoginStyles } from './component/style';
import { useCookies } from 'react-cookie';
import { resetPassword } from '../../services/authentication.api';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { useNavigate } from 'react-router-dom';
export default function ResetPassword() {
  const { classes } = useLoginStyles();
  const [cookie, setCookie, removeCookie] = useCookies(['forget-email']);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(resetpasswordSchema),
    mode: 'all',
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleresetPassword = async (data: any) => {
    const resetPasswordData = {
      email: data.email,
      password: data.password,
    };
    try {
      setIsLoading(true);
      const resData = await resetPassword(resetPasswordData);
      navigate('/login');
      removeCookie('forget-email');
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toastMessageUpdate('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    reset({
      email: cookie['forget-email'] || '',
    });
  }, []);

  const passInpRef = useRef();

  return (
    <>
      <Grid w='100%'>
        <Grid.Col md={5} offsetMd={3.5}>
          <Paper withBorder shadow='md' p={30} radius='xl' mt='xl'>
            <Title align='center'>Reset Password</Title>
            <form onSubmit={handleSubmit(handleresetPassword)} autoComplete='off'>
              <PasswordInput
                mt={15}
                size='md'
                {...register('password', { required: true })}
                label='Password'
                placeholder='Enter your new password'
                visibilityToggleIcon={({ reveal }) =>
                  reveal ? <IconEye size={20} stroke={1} /> : <IconEyeOff size={20} stroke={1} />
                }
              />
              {errors?.password && (
                <Flex direction='row'>
                  <Text fz={14} fw={500} mt={6} pl={5} color='red'>
                    {errors?.password?.message}
                  </Text>
                  <Box mt={6} ml={2}>
                    <Tooltip
                      opened
                      label='Password must have a minimum of 6 characters including at least 1 uppercase letter, 1 numeric digit, and 1 special character (@, #, or !)'
                      color='red'
                      multiline
                      width={220}
                      position='right'
                      withArrow>
                      <IconHelpOctagon size={15} stroke={1.5} color='red' />
                    </Tooltip>
                  </Box>
                </Flex>
              )}

              {/* <Menu
                opened={watch('password')?.length > 0}
                styles={(theme) => ({
                  dropdown: {
                    boxShadow: '0px 0px 6px -1px rgba(0,0,0,0.1)',
                  },
                })}>
                <Menu.Target>
                  <PasswordInput
                    autoComplete='off'
                    mt={15}
                    size='md'
                    {...register('password', { required: true })}
                    label='Password'
                    placeholder='Enter your new password'
                    visibilityToggleIcon={({ reveal }) =>
                      reveal ? <IconEye size={20} stroke={1} /> : <IconEyeOff size={20} stroke={1} />
                    }
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <Box w={'100%'} p={10}>
                    <Text fw={600} fz={18}>
                      Password must
                    </Text>
                    <Flex c='dimmed' mt={10} direction='column' gap={4}>
                      <Group spacing={4}>
                        {watch('password')?.match(/[0-9]/) ? (
                          <IconCircleCheckFilled size={20} />
                        ) : (
                          <IconCircleCheck size={20} />
                        )}

                        <Text>have a minimum of 6 characters</Text>
                      </Group>
                      <Group spacing={4}>
                        <IconCircleCheck size={20} />
                        <Text>have a maximum of 20 characters</Text>
                      </Group>
                      <Group spacing={4}>
                        <IconCircleCheck size={20} />
                        <Text>have at least 1 uppercase</Text>
                      </Group>
                      <Group spacing={4}>
                        <IconCircleCheck size={20} />
                        <Text>have at least 1 lowercase</Text>
                      </Group>
                      <Group spacing={4}>
                        <IconCircleCheck size={20} />
                        <Text>have at least 1 numeric digit</Text>
                      </Group>
                    </Flex>
                  </Box>
                </Menu.Dropdown>
              </Menu> */}

              <PasswordInput
                mt={15}
                size='md'
                {...register('confirm_password', { required: true })}
                label='Confirm Password'
                placeholder='Confirm your new password'
                visibilityToggleIcon={({ reveal }) =>
                  reveal ? <IconEye size={20} stroke={1} /> : <IconEyeOff size={20} stroke={1} />
                }
              />
              {errors?.confirm_password && (
                <Text fz={14} pt={5} pl={5} fw={500} color='red'>
                  {errors?.confirm_password?.message}
                </Text>
              )}
              <Button
                variant='gradient'
                gradient={{ from: '#fda223', to: '#fe7720' }}
                fullWidth
                size='md'
                mt={20}
                loading={isLoading}
                type='submit'
                radius='sm'>
                {!isLoading && 'Submit'}
              </Button>
            </form>
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
}
