import { Badge, Box, Flex, Group, Text, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconBriefcase, IconEdit } from '@tabler/icons-react';
import { useRenderSkillsStyles } from './style';

interface Props {
  skills: string | null;
  onClick: () => void;
}

export default function RenderSkills({ skills, onClick }: Props) {
  const matches = useMediaQuery('(max-width: 40em)');
  const { classes, cx } = useRenderSkillsStyles();
  return (
    <>
      <Flex gap={4} align='center'>
        <IconBriefcase className={cx(classes.briefcaseIcon)} />
        <Text className={cx(classes.skillsText)}>Skills</Text>
        <Box className={cx(classes.skillsContainerForLargeScreen)}>
          {skills ? (
            <>
              {skills.split(',').map((skill, index) => {
                return (
                  <Badge
                    mx={2}
                    key={index}
                    radius='sm'
                    size='sm'
                    variant='gradient'
                    gradient={{ from: '#fda223', to: '#fe7720' }}>
                    {skill.trim()}
                  </Badge>
                );
              })}
            </>
          ) : (
            <>
              <Text c='#7b7b7b'>Add your skills</Text>
            </>
          )}
        </Box>

        <UnstyledButton pos='relative' top={-4} onClick={onClick}>
          <IconEdit size={17} color='#fe7720' />
        </UnstyledButton>
      </Flex>

      <Box className={cx(classes.skillsContainerForSmallScreen)}>
        {skills ? (
          <>
            <Group spacing={2} position='center'>
              {skills.split(',').map((skill, index) => {
                return (
                  <Badge
                    key={index}
                    radius='sm'
                    size='sm'
                    variant='gradient'
                    gradient={{ from: '#fda223', to: '#fe7720' }}>
                    {skill.trim()}
                  </Badge>
                );
              })}
            </Group>
          </>
        ) : (
          <>
            <Text c='#7b7b7b'>Add your skills</Text>
          </>
        )}
      </Box>
    </>
  );
}
