import { useState } from 'react';
import { filterServicesList } from '../../utils/helpers';
import HomeRefactored from './Home/v2';
import { HomeContextRefactored, useHomeContext } from './Home/v2/home.context';

export default function Dashboard() {
  const [servicesList, setServicesList] = useState<Record<string, any>[]>([]);
  const fn = (data: any) => {
    const newArr = filterServicesList(data?.data);
    setServicesList(newArr);
  };

  return (
    <>
      {/* <HomeContext.Provider value={useServiceInformation()}>
        <Home />
      </HomeContext.Provider> */}

      <HomeContextRefactored.Provider value={useHomeContext()}>
        <HomeRefactored />
      </HomeContextRefactored.Provider>
    </>
  );
}
