import { Button, ButtonProps, Text } from '@mantine/core';
import { type ComponentPropsWithoutRef } from 'react';

type Props =
  | (ComponentPropsWithoutRef<'button'> & ButtonProps & { variant: 'outline'; loading?: boolean })
  | (ComponentPropsWithoutRef<'button'> & ButtonProps & { variant: 'gradient'; loading: boolean });

export default function CustomButton(props: Props) {
  if (props.variant === 'outline') {
    return (
      <Button
        {...props}
        styles={(theme) => ({
          root: {
            borderColor: theme.colorScheme === 'light' ? '#e1e1e1' : '#636363',
            transition: '200ms',

            '&:hover': {
              borderColor: theme.colorScheme === 'light' ? '#fe7720' : '#6e6e6e',
              backgroundColor: theme.colorScheme === 'light' ? 'rgb(255 214 198 / 33%)' : 'rgb(5 5 5 / 33%)',
              transition: '200ms',
            },
          },

          label: {
            color: theme.colorScheme === 'light' ? '#fe7720' : 'rgb(228 96 12 / 88%)',
          },
        })}
        variant='outline'
        loaderPosition='center'
        size='sm'
        fullWidth
        radius={4}
        loaderProps={{ c: 'red' }}
        sx={{
          '&[data-disabled]': { pointerEvents: 'all' },
          '&[data-loading]': {
            pointerEvents: 'all',
            '&:hover': { borderColor: '#e1e1e1', backgroundColor: '#f1f1f1' },
          },
        }}
        disabled={props.disabled}>
        <Text>{props.loading ? null : props.children}</Text>
      </Button>
    );
  }

  return (
    <Button
      {...props}
      loading={props.loading}
      disabled={props.disabled}
      variant='gradient'
      gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
      size='sm'
      radius={4}
      loaderPosition='center'
      data-disabled={props.disabled}
      sx={{
        '&[data-disabled]': { pointerEvents: 'all' },
        '&[data-loading]': { pointerEvents: 'all' },
      }}
      fullWidth>
      <Text>{props.loading ? null : props.children}</Text>
    </Button>
  );
}
