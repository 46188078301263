import { Box, Button, FileButton, Image, Tooltip, rem } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { IconCamera, IconUpload } from '@tabler/icons-react';
import type { RefObject } from 'react';
import { UserImageType } from '../../../../../utils/types/ImageType';

interface Props {
  src: string;
  coverImageFile: File | null;
  loading: boolean;
  setCoverImageFile: (file: File | null, type: UserImageType) => void;
  uploadCoverPhoto: () => void;
  resetRef: RefObject<() => void>;
}

export default function ProviderCoverImage({
  src,
  loading,
  coverImageFile,
  setCoverImageFile,
  uploadCoverPhoto,
  resetRef,
}: Props) {
  const { width } = useViewportSize();
  return (
    <>
      <Box sx={(theme) => ({ position: 'relative' })}>
        <Image
          src={src}
          withPlaceholder
          styles={(theme) => ({
            image: {
              borderRadius: rem(8),
              height: `${rem(300)} !important`,

              [theme.fn.smallerThan(1250)]: {
                height: '250px !important',
              },

              [theme.fn.smallerThan(1000)]: {
                height: '220px !important',
              },

              [theme.fn.smallerThan(850)]: {
                height: '200px !important',
              },

              [theme.fn.smallerThan(650)]: {
                height: '180px !important',
              },

              [theme.fn.smallerThan(769)]: {
                borderRadius: 0,
              },
            },
          })}
        />

        {coverImageFile && (
          <Tooltip label={!loading && 'Upload'} position='bottom' withArrow disabled={loading}>
            <Button
              onClick={uploadCoverPhoto}
              loading={loading}
              loaderPosition='center'
              disabled={loading}
              sx={(theme) => ({
                position: 'absolute',
                bottom: '5%',
                right: 80,

                border: 'none',
                background: 'rgba(0, 0, 0, 0.4)',
                borderRadius: rem(4),
                width: rem(36),
                height: rem(36),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.5)',
                },

                '&[data-disabled]': {
                  background: 'rgba(0, 0, 0, 0.4)',
                  pointerEvents: 'all',
                  cursor: 'not-allowed',
                },

                '&[data-loading]::before': {
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                },

                [theme.fn.smallerThan(769)]: {
                  left: `calc(${width}px - 96px)`,
                },

                svg: {
                  stroke: '#fe7720',
                },
              })}>
              {!loading && <IconUpload />}
            </Button>
          </Tooltip>
        )}
        <FileButton
          onChange={(f) => setCoverImageFile(f, 'cover_image')}
          accept='image/png,image/jpeg'
          resetRef={resetRef}>
          {(properties) => (
            <Tooltip
              label={loading ? 'Disabled when uploading' : coverImageFile ? 'Change image' : 'Choose another image'}
              position='bottom'
              withArrow>
              <Button
                disabled={loading}
                sx={(theme) => ({
                  position: 'absolute',
                  bottom: '5%',
                  right: '2%',
                  background: 'rgba(0, 0, 0, 0.4)',
                  borderRadius: rem(4),
                  width: rem(36),
                  height: rem(36),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',

                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.5)',
                  },

                  '&[data-disabled]': {
                    background: 'rgba(0, 0, 0, 0.4)',
                    pointerEvents: 'all',
                    cursor: 'not-allowed',
                  },

                  svg: {
                    stroke: '#fe7720',
                  },
                })}>
                {<IconCamera {...properties} />}
              </Button>
            </Tooltip>
          )}
        </FileButton>
      </Box>
    </>
  );
}
