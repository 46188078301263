import { createContext, useState } from 'react';
import { themeLayout } from '../utils';

export const useThemeLayout = () => {
  const [themeLayoutType, setThemeLayoutType] = useState<themeLayout>('vertical');
  const [toggleSideNav, setToggleSideNav] = useState<boolean>(false);
  const [subscriptionExhausted, setSubscriptionExhausted] = useState<any>();
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<any>();
  const [chatType, setChatType] = useState<any>();
  const [channelId, setChannelId] = useState<any>();
  const [chatRecieverId, setChatRecieverId] = useState<any>();
  const [videoStart, setVideoStart] = useState<any>(false);
  const [notificationRecieved, setNotificationRecieved] = useState<any>(null);
  const [notificationData, setNotificationData] = useState<any>();
  const [selectedConversationId, setSelectedConversationId] = useState<any>();
  const [pusherData, setPusherData] = useState<Record<string, any>>({});
  const [selectedServiceName, setSelectedServiceName] = useState('');

  return {
    themeLayoutType,
    setThemeLayoutType,
    toggleSideNav,
    setToggleSideNav,
    subscriptionExhausted,
    setSubscriptionExhausted,
    selectedSubscriptionPlan,
    setSelectedSubscriptionPlan,
    chatType,
    setChatType,
    channelId,
    setChannelId,
    chatRecieverId,
    setChatRecieverId,
    videoStart,
    setVideoStart,
    notificationRecieved,
    setNotificationRecieved,
    setNotificationData,
    notificationData,
    selectedConversationId,
    setSelectedConversationId,
    pusherData,
    setPusherData,
    selectedServiceName,
    setSelectedServiceName,
  };
};

export const ThemeContext = createContext({} as ReturnType<typeof useThemeLayout>);
