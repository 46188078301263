import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, Text, TextInput, Title } from '@mantine/core';
import axios from 'axios';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InferType } from 'yup';
import { resendMobileVerifyOTP } from '../../services/authentication.api';
import onlyAllowNumbersInput from '../../utils/helpers/onlyAllowNumbersInput';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { otpResendViaMobileSchema } from '../../utils/schema/formSchema';
import { useMobileNumberInputForOtpStyles } from './styles';

interface Props {
  closeMobileNumberInput: () => void;
}

/*
This component will be used when user has signed up but did not verify their mobile number on the next page for whatever reason
*/
export default function ValidateMobileNumber({ closeMobileNumberInput }: Props) {
  const { classes: mobileNumberInputClasses } = useMobileNumberInputForOtpStyles();
  const [cookie, setCookie] = useCookies(['mobilenumber']);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(otpResendViaMobileSchema),
    mode: 'all',
    defaultValues: {
      mobile: '',
    },
  });

  const onSubmit: SubmitHandler<InferType<typeof otpResendViaMobileSchema>> = async (data) => {
    setLoading(true);
    setCookie('mobilenumber', data.mobile, {
      path: '/',
    });

    try {
      const response = await resendMobileVerifyOTP({ mobile: data.mobile });
      toastMessageUpdate('success', response.data.message);
      setLoading(false);
      closeMobileNumberInput();
      navigate('/mobile-verification');
    } catch (error: unknown) {
      setLoading(false);
      if (axios.isAxiosError(error)) {
        toastMessageUpdate('error', error.response?.data.message || 'Something went wrong.');
      } else console.log(error);
    }
  };

  return (
    <>
      <Flex direction='column' p={0}>
        <Title order={3} ta='center'>
          Validate mobile number
        </Title>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' spellCheck='false'>
          <TextInput
            {...register('mobile', { required: true })}
            type='number'
            mt={12}
            data-autofocus
            onKeyDown={onlyAllowNumbersInput}
            description='Please enter your mobile number'></TextInput>

          {errors.mobile && (
            <Text fz={13} pt={5} pl={5} fw={500} color='red'>
              {errors?.mobile?.message}
            </Text>
          )}

          <Button
            fullWidth
            variant='gradient'
            gradient={{ from: '#fda223', to: '#fe7720' }}
            radius={4}
            mt={16}
            type='submit'
            loading={loading}
            loaderPosition='center'
            disabled={watch('mobile').length < 8 || watch('mobile').length > 15}>
            {!loading && 'Submit'}
          </Button>
        </form>
      </Flex>
    </>
  );
}
