import { Button } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import EndVideoCall from '../../../components/EndVideoCall';
import { ThemeContext } from '../../../context/themeContext';
import { useUserProfile } from '../../../services/user.api';
import { RoleType } from '../../../utils';
import { AppEvents } from '../../../utils/helpers/sendFIle';
import NotificationWrapper from './NotificationWrapper';

export default function HeaderErrorBlock() {
  const customerRole = [RoleType.organization_service_consumer, RoleType.customer];
  const [cookies, setCookie] = useCookies(['role', 'user_Address', 'subscription_status', 'user_Info']);
  const [allowLocation, setAllowLocation] = useState(false);
  const navigate = useNavigate();
  const { data: userProfileData, isLoading, isError, refetch }: any = useUserProfile();
  const [videoCallIn, setVideoCallIn] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const { subscriptionExhausted } = useContext(ThemeContext);

  // check video call status, if any videocall is running or not
  useEffect(() => {
    AppEvents.subscribe('videoCallStatus', (params: any) => {
      if (params) {
        setVideoCallIn(params);
      } else {
        setVideoCallIn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (userProfileData?.data?.user) {
      setCookie('user_Info', userProfileData?.data?.user, { path: '/' });
    }
  }, [userProfileData]);

  // used google navigator to allow the browser location.
  const getNavPermission = (isClicked = false) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const state = JSON.stringify(position.coords.latitude);
      if (state) {
        setAllowLocation(true);
      } else {
        setAllowLocation(false);
      }
    });
    if (isClicked) {
      alert(`Please enable your browser's location access from the browser settings or from the url bar`);
    }
    // navigator?.permissions?.query({ name: 'geolocation' }).then((location: any) => {
    //   if (location.state === 'granted') {
    //     setAllowLocation(true);
    //   } else {
    //     setAllowLocation(false);
    //   }
    //   if (location.state === 'denied' && isClicked) {
    //     alert(`Please enable your browser's location access from the browser settings or from the url bar`);
    //   }
    // });
  };

  useEffect(() => {
    getNavPermission();
  }, []);

  // handle navigation as per condition.
  const handleNavigationButton = (page: string) => {
    if (videoCallIn) {
      open();
      return;
    } else {
      switch (page) {
        case 'subscription':
          navigate('/subscription');
          break;
        case 'profile':
          navigate('/my-profile');
          break;
        case 'address-update':
          if (customerRole.includes(+cookies.role)) navigate('/my-profile');
          else navigate('/partner/profile');
          break;
        default:
          navigate('/');
      }
    }
  };

  const isMobile = useMediaQuery('(max-width: 576px)');

  if (!cookies?.subscription_status && userProfileData?.data?.has_address?.state_short_code === null) {
    return (
      <>
        <NotificationWrapper message='Please update your address'>
          <Button
            compact
            size='md'
            type='button'
            variant='subtle'
            fz={isMobile ? 12 : 16}
            onClick={() => {
              handleNavigationButton('address-update');
            }}>
            Click Here
          </Button>
        </NotificationWrapper>
      </>
    );
  }

  // if (cookies?.subscription_status && JSON.parse(cookies?.subscription_status) === true) {
  // if (subscriptionExhausted?.data?.chat_exausted && JSON.parse(cookies?.subscription_status)) {
  //   return (
  //     <>
  //       <NotificationWrapper
  //         message={
  //           customerRole.includes(+cookies?.role)
  //             ? `${
  //                 !userProfileData?.data?.is_expired && subscriptionExhausted?.data?.chat_exausted
  //                   ? subscriptionExhausted?.message
  //                   : 'To improve your results, please consider starting a Subscription.'
  //               }`
  //             : `${
  //                 !userProfileData?.data?.is_expired && subscriptionExhausted?.data?.chat_exausted
  //                   ? subscriptionExhausted?.message
  //                   : 'To list your services, please consider starting a Subscription.'
  //               }`
  //         }>
  //         <Button
  //           compact
  //           size='md'
  //           type='button'
  //           variant='subtle'
  //           fz={isMobile ? 12 : 16}
  //           onClick={() => {
  //             handleNavigationButton('subscription');
  //           }}>
  //           Click Here
  //         </Button>
  //       </NotificationWrapper>
  //       <EndVideoCall opened={opened} close={close} />
  //     </>
  //   );
  // }
  return (
    <>
      {/* cookies?.user_Address !== 'null' || */}
      {!allowLocation && (
        <NotificationWrapper message='Please allow your browser location to get a better result.'>
          <Button compact size='xs' type='button' onClick={() => getNavPermission(true)}>
            Click Here
          </Button>
        </NotificationWrapper>
      )}
      {cookies?.user_Address === 'null' && customerRole.includes(+cookies?.role) && (
        <NotificationWrapper message='Please click to complete your profile to get a better result.'>
          <Button
            compact
            size='xs'
            type='button'
            onClick={() => {
              // navigate(`/my-profile`);
              handleNavigationButton('profile');
            }}>
            Click Here
          </Button>
        </NotificationWrapper>
      )}
      <EndVideoCall opened={opened} close={close} />
    </>
  );
}
