import { Box, Button, Modal, Radio, Text, TextInput } from '@mantine/core';
import { IconCashBanknote } from '@tabler/icons-react';
import { useState } from 'react';

interface IWithdrawModal {
  opened: boolean;
  close: () => void;
}

export default function WithdrawModal(props: IWithdrawModal) {
  const { close, opened } = props;
  const [value, setValue] = useState('react');

  return (
    <Modal opened={opened} onClose={close} title='Available Balance'>
      <Text>$100.00</Text>
      <TextInput placeholder='Enter Amount' />

      <Radio.Group value={value} onChange={setValue} name='favoriteFramework' label='Withdraw to' withAsterisk mt='md'>
        <Radio value='react' label='Axis Bank' />
        <Radio value='svelte' label='Punjab National Bank' />
      </Radio.Group>
      {/* Modal content */}
      <Box ta='right' mt='xl'>
        <Button size='md' radius='xl'>
          Procced
        </Button>
        <Text c='dimmed' size='xs'>
          Processing time up to 24-72 hours
        </Text>
      </Box>
      <Box ta='center' my='lg'>
        <Button size='md' compact variant='subtle' radius='xl'>
          Add New Bank Now
        </Button>
      </Box>
    </Modal>
  );
}
