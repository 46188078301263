import { Box, Grid, Text, Title } from '@mantine/core';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { deleteUserProfile } from '../../../../../services/user.api';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import CustomButton from './CustomButton';
import { deleteMyAccountStyles } from './style';

interface Props {
  closeDeleteAccount: () => void;
}

export default function DeleteMyAccount({ closeDeleteAccount }: Props) {
  const { classes: deleteMyAccountClasses } = deleteMyAccountStyles();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    'access_token',
    'role',
    'user_Address',
    'user_Overview',
    'user_Info',
    'peerId',
    'bookingDetails',
    'agora_token',
    'agora_video_token',
  ]);
  const deletMyAccount = async () => {
    setLoading(true);
    try {
      const formUrl: any = await deleteUserProfile();
      if (formUrl?.success) {
        removeCookie('access_token', { path: '/' });
        removeCookie('role', { path: '/' });
        removeCookie('user_Address', { path: '/' });
        removeCookie('user_Overview', { path: '/' });
        removeCookie('user_Info', { path: '/' });
        removeCookie('peerId', { path: '/' });
        removeCookie('bookingDetails', { path: '/' });
        removeCookie('agora_token', { path: '/' });
        removeCookie('agora_video_token', { path: '/' });
        navigate('/register');
        setLoading(false);
      }
    } catch (error: any) {
      toastMessageUpdate('error', error?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Title order={3} ta='center'>
          Delete account
        </Title>
        <Text c='dimmed' ta='center' mt={16}>
          Are you sure you want to delete your account?
        </Text>
        <Text c='#fb4f4f' fw={600} ta='center' fz={13}>
          You will not be able to reverse this action
        </Text>
        <Grid p={0} m={0} gutter={8} mt={12}>
          <Grid.Col className={deleteMyAccountClasses.buttonGridContainer}>
            <CustomButton variant='outline' loading={loading} onClick={deletMyAccount}>
              OK
            </CustomButton>
          </Grid.Col>
          <Grid.Col className={deleteMyAccountClasses.buttonGridContainer}>
            <CustomButton variant='gradient' loading={false} onClick={closeDeleteAccount}>
              Cancel
            </CustomButton>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
