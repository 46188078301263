import removeNullishValuesFromObject from './removeNullishValuesFromObject';

type AddressComponents = {
  latitude: string;
  longitude: string;
  fullAddress: string;
  country: string;
  state: string;
  stateShortCode: string;
  city: string;
  postalCode: string;
};

export default function getAddressComponentsFromGooglePlaceAutocomplete(
  result: google.maps.GeocoderResult,
): Partial<AddressComponents> {
  const addressComponents: AddressComponents = {
    latitude: '',
    longitude: '',
    fullAddress: '',
    country: '',
    state: '',
    stateShortCode: '',
    city: '',
    postalCode: '',
  };

  console.log(result);

  addressComponents.fullAddress = result.formatted_address;
  addressComponents.latitude = String(result.geometry.location.lat());
  addressComponents.longitude = String(result.geometry.location.lng());

  result.address_components.forEach((component) => {
    if (component.types.includes('country')) {
      addressComponents.country = component.long_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      addressComponents.state = component.long_name;
      addressComponents.stateShortCode = component.short_name;
    } else if (
      /**
       * Here, we are setting the city.
       * According to the official documentation:
       * 1. the `locality` type indicates a city.
       * 2. the `administrative_area_level_2` type indicates a county (within US)
       * 3. the `administrative_area_level_3` type indicates a third order civil entity
       * ---
       * However, as of current requirement, at the time of writing (2024-03-21), we are considering all of them for city.
       * the supporting documentation for this can be found here: https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
       */
      component.types.includes('administrative_area_level_2') ||
      component.types.includes('administrative_area_level_3') ||
      component.types.includes('locality')
    ) {
      addressComponents.city = component.long_name;
    } else if (component.types.includes('postal_code')) {
      addressComponents.postalCode = component.long_name;
    }
  });

  // console.log(addressComponents);
  const addressComponentsWithoutNullishValues = removeNullishValuesFromObject(
    addressComponents,
  ) as Partial<AddressComponents>;
  console.log(addressComponentsWithoutNullishValues);

  return addressComponentsWithoutNullishValues;
}
