import { Button, Modal, Paper, Text, ThemeIcon, Title, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCheck } from '@tabler/icons-react';
import React from 'react';

interface IModal {
  state: boolean;
  close: () => void;
  message: string;
  success: boolean;
}

// UI of payment success modal.
export default function PaymentSuccessMoadal({ state, close, message, success }: IModal) {
  return (
    <Modal
      centered
      opened={state}
      onClose={close}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      bg='transparent'
      styles={{
        content: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
      <Paper p={15} ta='center' mt={30}>
        <ThemeIcon size={80} radius={200} top={-45} pos='relative'>
          <IconCheck size={rem(60)} />
        </ThemeIcon>
        <Title c={success ? 'orange' : 'red'} mb={10} mt={-35}>
          {success ? 'Success' : 'Error'}
        </Title>
        <Text>{message}</Text>
        <Button
          fullWidth
          mt='xs'
          radius={4}
          variant='gradient'
          gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
          size='md'
          onClick={close}>
          OK
        </Button>
      </Paper>
      {/* Modal content */}
    </Modal>
  );
}
