import React, { useContext, useEffect, useState } from 'react';
import { useClient } from '../../../../utils/setting';
import { ActionIcon, Button, Flex } from '@mantine/core';
import { IconMicrophone, IconMicrophoneOff, IconPhoneOff, IconVideo, IconVideoOff } from '@tabler/icons-react';
import { VideoContext } from '../../Chat/videoContext';
import AgoraRTC, { ICameraVideoTrack } from 'agora-rtc-react';
import { AppEvents } from '../../../../utils/helpers/sendFIle';
import { ThemeContext } from '../../../../context/themeContext';

// camera control and its functionality.
const Controls = (props: any) => {
  const client = useClient();
  const { tracks, setStart, setInCall, closeVideoCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const { setTrackStatus } = useContext(VideoContext);
  const [cameraList, setCameraList] = useState<any[]>([]);
  const [cameraId, setCamera] = useState<string | null>(null);
  const [externalAudio, setExternalAudio] = useState<string>('');
  const { setVideoStart } = useContext(ThemeContext);

  // get camera list with different devices and handling its features.
  const getDevices = async () => {
    AgoraRTC.getCameras(true).then((res) => {
      // if (!res.length) {
      //   setIsVideoEnable(false);
      // }
      console.log('device resp', res);
      const list = res.map((item: any) => ({
        value: item.deviceId,
        label: item.label,
      }));
      setCamera(list.length ? list[0].value : null);
      setCameraList(list);
    });

    const audio = await AgoraRTC.getMicrophones(false);
    const external = audio.filter((item) => item.label !== 'Default')?.[0].deviceId;
    setExternalAudio(external ? external : '');
  };

  const [stream, setStream] = useState<ICameraVideoTrack | undefined>(undefined);

  // handle camera and video track
  const userStream = async () => {
    const data = await AgoraRTC.createCameraVideoTrack({ cameraId: cameraId || undefined, encoderConfig: '720p' });
    console.log('user stream', data);
    setStream(data);
  };

  useEffect(() => {
    if (cameraId && cameraList.length) {
      userStream();
    }
  }, [cameraId]);

  // audio and video mute functionality.
  const mute = async (type: 'audio' | 'video') => {
    if (type === 'audio') {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === 'video') {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
      // await stream.setEnabled(!stream.enabled);
      // setStream(stream);
      console.log('video track', trackState);
      setTrackStatus(trackState);
    }
  };

  // channel leave functionality
  const leaveChannel = async () => {
    console.log('leave', stream);
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
    stream?.close();
    setVideoStart(false);
    AppEvents.dispatch('videoCallStatus', false);
    closeVideoCall();
  };

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <>
      <Flex gap='lg' justify='center' mt={'-95px'}>
        <ActionIcon
          onClick={() => mute('audio')}
          variant='gradient'
          style={{ border: 'none', background: 'linear-gradient(160deg, #FEC74E 0%, #FE7D49 100%)' }}
          radius='50%'
          size={45}>
          {trackState.audio ? (
            <IconMicrophone size='1.8rem' stroke={1.5} />
          ) : (
            <IconMicrophoneOff size='1.8rem' stroke={1.5} />
          )}
        </ActionIcon>

        <ActionIcon
          onClick={() => mute('video')}
          variant='gradient'
          style={{ border: 'none', background: 'linear-gradient(160deg, #FEC74E 0%, #FE7D49 100%)' }}
          radius='50%'
          size={45}>
          {trackState.video ? <IconVideo size='1.8rem' stroke={1.5} /> : <IconVideoOff size='1.8rem' stroke={1.5} />}
        </ActionIcon>

        <ActionIcon
          onClick={() => leaveChannel()}
          variant='gradient'
          style={{ border: 'none', background: '#f03e3e' }}
          radius='50%'
          size={45}>
          <IconPhoneOff size='1.8rem' stroke={1.5} />
        </ActionIcon>
      </Flex>
    </>
  );
};

export default Controls;
