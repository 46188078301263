import { DateValue } from '@mantine/dates';
import dayjs from 'dayjs';

export function GetWeekDates(startDate: Date | DateValue, daysToAdd: number) {
  const aryDates = [];
  if (!startDate) {
    return;
  }
  for (let i = 0; i <= daysToAdd; i++) {
    const currentDate = new Date();
    // currentDate.setDate(startDate.getDate() + i);
    const updatedDate = new Date(dayjs(dayjs(startDate).add(i, 'day')).toISOString());
    aryDates.push(updatedDate);
  }
  return aryDates;
}

export function makeid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
