import { Box } from '@mantine/core';
import { useCookies } from 'react-cookie';
import UserBanner from './components/UserBanner';
import UserInfo from './components/UserInfo';
import UserBannerRefactored from './components/UserBannerRefactored';
import UserBannerRefactoredWithCrop from './components/UserBannerRefactoredWithCrop';

export default function UserProfile() {
  const [cookie, setCookie] = useCookies(['user_Info', 'subscription_status', 'access_token']);

  return (
    <>
      <Box mb={70} pos='relative'>
        {/* <UserBannerRefactored /> */}
        <UserBannerRefactoredWithCrop />
        <UserInfo />
      </Box>
    </>
  );
}
