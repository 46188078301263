import React, { useContext, useEffect, useState } from 'react';
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-react';
import { LoadingOverlay, Text } from '@mantine/core';
import { appId, channelName } from '../../../utils/setting';
import ChannelForm from './components/ChannelForm';
import VideoCall from './components/VideoCall';
import { useCookies } from 'react-cookie';
import { VideoContext } from '../Chat/videoContext';
import { ThemeContext } from '../../../context/themeContext';
import { generateToken } from '../../../services/chat.api';

const WebVideo = ({ endVideoCall, chatUserData }: any) => {
  const [cookies, setCookie] = useCookies(['peerId', 'agora_token']);
  const { chatType, channelId } = useContext(ThemeContext);
  const [inCall, setInCall] = useState(false);
  const [videoChannelName, setChannelName] = useState(
    channelName + `${chatType === 'userChat' ? channelId : cookies?.peerId}`,
  );
  const { startVideo, setChatUser } = useContext(VideoContext);
  const [callLoader, setCallLoader] = useState(false);
  // handling video call and chat user data with props and context api.
  useEffect(() => {
    console.log('start', startVideo, chatUserData);
    setChatUser(chatUserData);
    setCallLoader(true);
    generateVideoToken();
  }, [startVideo, chatUserData]);

  const generateVideoToken = async () => {
    try {
      const tokenData = await generateToken({
        channel_name: channelName + `${chatType === 'userChat' ? channelId : cookies?.peerId}`,
      });
      setCookie('agora_token', tokenData.data, { path: '/' });
      setInCall(startVideo);
      setCallLoader(false);
    } catch (error: any) {
      console.log('token err', error);
      setCallLoader(false);
    }
  };

  return (
    <>
      <Text color='dimmed' ta='center' fz='lg' pb='md'>
        Welcome to AidBy video call
      </Text>
      <LoadingOverlay visible={callLoader} overlayBlur={2} loaderProps={{ size: 'lg' }} />
      {inCall ? (
        <VideoCall setInCall={setInCall} channelName={videoChannelName} closeVideo={endVideoCall} />
      ) : // <ChannelForm setInCall={setInCall} />
      null}
    </>
  );
};

export default WebVideo;
