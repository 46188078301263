import axios from 'axios';
import { axiosInst } from './axios.config';

function isValidIPAddress(ipAddress: string) {
  const ipv4Regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  // Regular expression for IPv6
  const ipv6Regex =
    /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,6}(:[0-9a-fA-F]{1,4}){1,2}$|^([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,3}$|^([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,4}$|^([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,5}$|^([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,6}$|^([0-9a-fA-F]{1,4}:){1}(:[0-9a-fA-F]{1,4}){1,7}$|^::$/;

  return ipv4Regex.test(ipAddress) || ipv6Regex.test(ipAddress);
}

export const getIP = async () => {
  // const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
  // const data: string = await res.text();
  // const ipRegex = /ip=([a-fA-F\d:.]+)/g;
  // const matches = data.match(ipRegex);
  // if (matches && matches.length > 0) {
  //   for (const match of matches) {
  //     // Remove "ip=" prefix
  //     const ipAddress = match.substring(3);
  //     if (isValidIPAddress(ipAddress)) {
  //       return ipAddress;
  //     }
  //   }
  // }

  // the functionality of this function can be replaced with this line
  return (await axios.get<{ ip: string }>('https://api64.ipify.org/?format=json')).data?.['ip'];
};

export const loginAPI = async (data: Record<string, any>) => {
  return await axiosInst.post('login', data);
};

export const forgetAPI = async (data: Record<string, any>) => {
  return await axiosInst.post('/forget-password', data);
};

export const OTPVerify = async (data: Record<string, any>) => {
  return await axiosInst.post('/verify-otp', data);
};

export const resetPassword = async (data: Record<string, any>) => {
  return await axiosInst.post('/verify-forget-password-otp', data);
};

export const RegisterAPI = async (data: Record<string, any>) => {
  try {
    return await axiosInst.post('register', data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const mobileVerifyOTP = async (data: Record<string, any>) => {
  return await axiosInst.post('/verify-contact', data);
};

export const resendMobileVerifyOTP = async (data: Record<string, any>) => {
  return await axiosInst.post('/contact-resend-otp', data);
};
