import { Box, Button, Flex, Grid, Text, Title, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit, IconMapPin } from '@tabler/icons-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { NavLink } from 'react-router-dom';
import { coverImageFile, orgProfileImage, profileImageFile } from '../../../../../assets';
import ImageCropper, { OnSelectArgType } from '../../../../../components/ImageCropper';
import ProfilePhoto from '../../../../../components/ProfilePhoto';
import { updateUserProfile, useUserProfile } from '../../../../../services/user.api';
import { updateUserOverview } from '../../../../../services/userProfiles';
import { useVerification } from '../../../../../services/verification';
import { RoleType } from '../../../../../utils';
import generateRandomString from '../../../../../utils/helpers/generateRandomAlphabetStrings';
import setFileWithValidation from '../../../../../utils/helpers/setFileWithValidation';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { ValidationPattern } from '../../../../../utils/schema/validationMessage';
import { UserImageType } from '../../../../../utils/types/ImageType';
import ModalOrDrawer from '../../../customer/Profile/components/ModalOrDrawer';
import EditUserRefactored from './EditUser-refactored';
import ProviderCoverImage from './ProviderCoverImage';
import RenderSkills from './RenderSkills';
import { useUserBannerRefactoredStyles } from './style';
import { chargebeeUserCreate } from '../../../../../services/payment.api';
import { createSubscription } from '../../../../../services/organization.api';
import { subscriptionLimit, useSubscriptionList } from '../../../../../services/subscription.api';
import { ThemeContext } from '../../../../../context';
import GlobalLoader from '../../../../../components/GlobalLoader';

export default function UserBannerRefactoredWithCrop() {
  const [cookies, setCookie] = useCookies(['user_Overview', 'user_Info', 'role', 'subscription_status']);

  // Related to the profile image
  const [profileImageFileToBeUpdated, setProfileImageFileToBeUpdated] = useState<File | null>(null);
  const [profileImageSourceURL, setProfileImageSourceURL] = useState('');
  const profileImageFileToBeUpdatedResetRef = useRef<() => void>(null);
  const [profileImageUploadIsLoading, setProfileImageUploadIsLoading] = useState(false);

  // Related to the cover image
  const [coverImageFileToBeUpdated, setCoverImageFileToBeUpdated] = useState<File | null>(null);
  const [coverImageSourceURL, setCoverImageSourceURL] = useState('');
  const coverImageFileToBeUpdatedResetRef = useRef<() => void>(null);
  const [coverImageUploadIsLoading, setCoverImageUploadIsLoading] = useState(false);

  const { data, isLoading, refetch } = useUserProfile();
  const { data: verificationData } = useVerification(cookies.user_Info?.id);
  const { data: subscriptionList } = useSubscriptionList(cookies?.user_Info?.type);

  const { classes, cx } = useUserBannerRefactoredStyles();

  const [providerEditOpened, { open: openProviderEdit, close: closeProviderEdit }] = useDisclosure(false);
  const [profileUpdateType, setProfileUpdateType] = useState<'name' | 'skills' | null>(null);
  const [encodedIdWithString, setEncodedIdWithString] = useState('');

  const [imageCropperOpened, { open: openImageCropper, close: closeImageCropper }] = useDisclosure(false);
  const [imageFileToBeCropped, setImageFileToBeCropped] = useState<File | null>(null);
  const { setSubscriptionExhausted } = useContext(ThemeContext);

  //Create for chargbee user
  const [subscriptionLoading, setSubscriptionLoading] = useState<boolean>(false);

  useEffect(() => {
    if (subscriptionList?.data && !cookies?.user_Info?.chargebee_id) {
      const basicPlan = subscriptionList?.data?.filter((item: any) => +item?.amount === 0);
      if (basicPlan?.length) {
        subscribeBasicPlan(basicPlan);
      }
      // else {
      //   if (!cookies?.user_Info?.subcription_id && (cookies?.role === '2' || cookies?.role === '3')) {
      //     setOpenPromotionModal(true);
      //   } else {
      //     setOpenPromotionModal(false);
      //   }
      // }
    }
  }, [subscriptionList]);

  const createChargebeeUser = async () => {
    setSubscriptionLoading(true);
    try {
      const userParams = {
        first_name: cookies?.user_Info?.name?.split(' ')[0],
        last_name: cookies?.user_Info?.name.split(' ')[1],
      };
      const res = await chargebeeUserCreate(userParams);
      setSubscriptionLoading(false);
    } catch (error: any) {
      console.log(error);
      setSubscriptionLoading(false);
    }
  };

  const subscribeBasicPlan = async (basicPlan: any) => {
    setSubscriptionLoading(true);
    try {
      const userParams = {
        first_name: cookies?.user_Info?.name?.split(' ')[0],
        last_name: cookies?.user_Info?.name.split(' ')[1],
      };
      const subscriptionParams = {
        subcription_id: basicPlan[0]?.subcription_id,
      };
      const subFormData = {
        user_id: cookies?.user_Info?.id,
      };
      const res = await chargebeeUserCreate(userParams);
      const profile = await updateUserProfile(subscriptionParams);
      setCookie('user_Info', profile?.data, { path: '/' });
      await createSubscription(subFormData);
      // .then((res: any) => {
      //   if (res?.success) {
      //     toastMessageUpdate('success', 'Subscribed with basic plan');
      //   }
      // })
      // .catch((err: any) => {
      //   console.log('sub error', err);
      // });
      const response = await subscriptionLimit();
      setSubscriptionExhausted(response);
      setCookie('subscription_status', false, {
        path: '/',
      });
      setSubscriptionLoading(false);
    } catch (error: any) {
      toastMessageUpdate('error', error?.data?.message || error?.message || '');
      // setOpenPromotionModal(true);
      console.log(error);
    }
  };

  /**
   * Here, it is necessary to use an useEffect in order to
   * 1. set the image URLs for the first render
   * 2. generate the encoded id used for the `View profile` button
   */
  useEffect(() => {
    // Cover image
    if (data?.data?.user_profile?.cover_image) {
      setCoverImageSourceURL(data?.data?.user_profile?.cover_image);
    } else {
      // setting static cover image in case the provider does not have any cover image
      setCoverImageSourceURL(coverImageFile);
    }

    // Profile image
    if (data?.data?.user_profile?.image) {
      setProfileImageSourceURL(data?.data?.user_profile?.image);
    } else {
      // setting static profile image (based on role) in case the provider does not have any profile image
      switch (+cookies.role) {
        case RoleType.service_provider:
          setProfileImageSourceURL(profileImageFile);
          break;
        case RoleType.organization_service_provider:
          setProfileImageSourceURL(orgProfileImage);
          break;
      }
    }

    setEncodedIdWithString(btoa(generateRandomString(16) + cookies.user_Info.id + generateRandomString(16)));
    // if (!cookies?.user_Info?.chargebee_id) {
    //   createChargebeeUser();
    // }
  }, [data]);

  const handleImageUpload = async (type: UserImageType) => {
    if (!cookies.user_Overview.user_profile_id) {
      toastMessageUpdate('info', 'Please create your profile.');
      setProfileImageFileToBeUpdated(null);
      setCoverImageFileToBeUpdated(null);
    } else {
      switch (type) {
        case 'profile_image':
          await handleProfileImageUpload();
          break;
        case 'cover_image':
          await handleCoverImageUpload();
          break;
      }
    }
  };

  const handleProfileImageUpload = async () => {
    setProfileImageUploadIsLoading(true);
    const fd = new FormData();
    console.log(profileImageFileToBeUpdated?.name);

    if (profileImageFileToBeUpdated) {
      fd.append('image', profileImageFileToBeUpdated, profileImageFileToBeUpdated.name);
    }
    const updatedProfileData = await updateUserOverview(fd);
    setCookie('user_Overview', updatedProfileData?.user_profile, { path: '/' });
    setProfileImageUploadIsLoading(false);
    setProfileImageFileToBeUpdated(null);
  };

  const handleCoverImageUpload = async () => {
    setCoverImageUploadIsLoading(true);
    const fd = new FormData();
    if (coverImageFileToBeUpdated) {
      fd.append('cover_image', coverImageFileToBeUpdated, coverImageFileToBeUpdated.name);
    }
    const updatedProfileData = await updateUserOverview(fd);
    setCookie('user_Overview', updatedProfileData?.user_profile, { path: '/' });
    setCoverImageUploadIsLoading(false);
    setCoverImageFileToBeUpdated(null);
  };

  const setImageWithValidation = async (file: File | null, type: UserImageType) => {
    const result = setFileWithValidation({
      file: file,
      fileRef: type === 'profile_image' ? profileImageFileToBeUpdatedResetRef : coverImageFileToBeUpdatedResetRef,
      type: 'image',
      maxAllowedFileSize:
        type === 'profile_image' ? ValidationPattern.Max_Profile_Image_Size : ValidationPattern.Max_Banner_Image_Size,
    });

    if (result.status === 'success') {
      if (type === 'profile_image') {
        setImageFileToBeCropped(result.validatedFile);
        openImageCropper();
        return;
      } else if (type === 'cover_image') {
        setCoverImageFileToBeUpdated(result.validatedFile);
        setCoverImageSourceURL(URL.createObjectURL(result.validatedFile));
        return;
      }
    }
  };

  const handleUpdateName = () => {
    setProfileUpdateType('name');
    openProviderEdit();
  };

  const handleUpdateSkills = () => {
    setProfileUpdateType('skills');
    openProviderEdit();
  };

  const updateDataFromModalOrDrawer = (value: any, type: 'name' | 'skills') => {
    refetch();
  };

  const handleImageCropperCancel = () => {
    closeImageCropper();
    setProfileImageFileToBeUpdated(null);
  };

  const handleImageCropperSelect = (data: OnSelectArgType) => {
    if (data.status === 'success') {
      setProfileImageSourceURL(data.croppedImageBlobUrl);
      setProfileImageFileToBeUpdated(data.croppedImageFile);
      closeImageCropper();
    }
  };

  return (
    <>
      {(subscriptionLoading || isLoading) && <GlobalLoader />}
      <Grid p={0} m={0} gutter={0}>
        <Grid.Col>
          <ProviderCoverImage
            loading={coverImageUploadIsLoading}
            src={coverImageSourceURL}
            coverImageFile={coverImageFileToBeUpdated}
            setCoverImageFile={setImageWithValidation}
            uploadCoverPhoto={() => handleImageUpload('cover_image')}
            resetRef={coverImageFileToBeUpdatedResetRef}
          />
        </Grid.Col>

        <Grid.Col mb={16} pos='relative'>
          <Flex className={cx(classes.flexContainerOne)}>
            <Box>
              <Flex className={cx(classes.flexContainerTwo)}>
                <Box className={cx(classes.profilePhotoContainer)}>
                  <ProfilePhoto
                    resetRef={profileImageFileToBeUpdatedResetRef}
                    uploadProfilePhoto={() => handleImageUpload('profile_image')}
                    loading={profileImageUploadIsLoading}
                    profilePhoto={profileImageFileToBeUpdated}
                    setProfilePhoto={setImageWithValidation}
                    showGradientBackground
                    showVerificationBorder={verificationData?.data.background_verification}
                    type='renderWithEdit'
                    src={profileImageSourceURL}
                    sizes={{ xs: 145, sm: 150, md: 155, lg: 155, xl: 160 }}
                  />
                </Box>

                <Flex className={cx(classes.flexContainerThree)}>
                  <Flex pos='relative'>
                    <Title order={3} ml={2}>
                      {data?.data.user.name}
                    </Title>
                    <UnstyledButton className={cx(classes.editButton)} onClick={handleUpdateName}>
                      <IconEdit />
                    </UnstyledButton>
                  </Flex>
                  {data?.data?.user_profile && (
                    <>
                      <Flex gap={4} align='center'>
                        <IconMapPin color='#7f7f7f' size={18} />
                        <Text c='#7b7b7b'>{data?.data?.has_address?.city}</Text>
                      </Flex>

                      <RenderSkills skills={data?.data?.user_profile?.skills} onClick={handleUpdateSkills} />
                    </>
                  )}
                </Flex>
              </Flex>
            </Box>
            {data?.data?.user_profile && (
              <Box mt={16}>
                <Button
                  variant='outline'
                  compact
                  radius={4}
                  component={NavLink}
                  to={`/provider/${encodedIdWithString}`}>
                  View Profile
                </Button>
              </Box>
            )}
          </Flex>
        </Grid.Col>
      </Grid>

      <ModalOrDrawer
        opened={providerEditOpened}
        onClose={closeProviderEdit}
        drawerProps={{ dz: profileUpdateType === 'name' ? 350 : 250, drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <EditUserRefactored
          close={closeProviderEdit}
          sendDataToParent={updateDataFromModalOrDrawer}
          updateType={profileUpdateType}
        />
      </ModalOrDrawer>

      <ImageCropper
        imageFileToBeCropped={imageFileToBeCropped}
        opened={imageCropperOpened}
        onClose={closeImageCropper}
        onSelect={handleImageCropperSelect}
        onCancel={handleImageCropperCancel}
      />
    </>
  );
}
