import { createStyles, getStylesRef, rem } from '@mantine/core';
import styled from '@emotion/styled';

export const useNotificationWrapperStyles = createStyles((theme) => ({
  container: {
    backgroundColor: '#20262E',
    padding: `${rem(4)} ${rem(0)}`,
  },
}));

export const useSidebarStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
    paddingTop: 0,
    position: 'fixed',
    top: 0,
  },

  header: {
    padding: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
  },
}));

export const HeaderAvatarIcon = createStyles(() => ({
  placeholderIcon: {
    height: '1.5rem !important',
    width: '1.5rem !important',
  },
}));

export const HeaderMenuStyle = createStyles((theme) => ({
  headerCommon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    '&:hover': {
      [`& .${getStylesRef('header-icon')}`]: {
        color: theme.colors.orange[8],
      },

      [`& .${getStylesRef('header-text')}`]: {
        color: theme.colors.orange[8],
      },
    },
  },

  icon: {
    ref: getStylesRef('header-icon'),
    userSelect: 'none',
  },

  text: {
    ref: getStylesRef('header-text'),
    userSelect: 'none',
  },

  active: {
    color: theme.colors.orange[7],
    userSelect: 'none',
  },
}));

export const MobileMenuStyle = createStyles((theme) => ({
  footerCommon: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },

  mobileMenu: {
    padding: '1rem',
    position: 'fixed',
    left: 0,
    bottom: 0,
    boxShadow: '#FFD8A9 5px 15px 50px 0px',
    borderRadius: '24px 24px 0 0',
    width: '100%',
    transition: '500ms',
  },

  mobileMenuWhileScrollUp: {
    bottom: 0,
    transition: '500ms ease-in-out',
  },

  mobileMenuWhileScrollDown: {
    bottom: -70,
    transition: '500ms ease-in-out',
  },
}));

export const IndicatorStyle = createStyles((theme) => ({
  indicator: {
    width: '17px',
    height: '17px',
    borderRadius: '50%',
  },
}));

export const ScrollContianer = styled.div(({ theme }) => ({
  maxHeight: 450,
  paddingBottom: 5,
  overflowY: 'auto',
  height: '100vh',
  '&::-webkit-scrollbar': {
    width: 2,
  },
  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
  },
}));

export const UserListScrollContianer = styled.div(({ theme }) => ({
  maxHeight: 'calc(100vh - 240px)',
  marginTop: '20px',
  overflowY: 'auto',
  height: '100vh',
  '&::-webkit-scrollbar': {
    width: 2,
  },
  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
  },
}));

export const UserListScrollContianerMobile = styled.div(({ theme }) => ({
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: 1,
  },
  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      // background: '#888',
      borderRadius: 10,
    },
  },
}));
