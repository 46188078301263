import { Tabs, TabsProps, rem } from '@mantine/core';

export default function StyledTabs(props: TabsProps) {
  return (
    <>
      <Tabs
        placement='right'
        // keepMounted={false}
        styles={(theme) => ({
          root: {
            flexDirection: 'column',
          },

          tab: {
            ...theme.fn.focusStyles(),
            boxSizing: 'border-box',
            backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[7],
            margin: `0 ${rem(2)}`,
            border: 0,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : 'black',
            padding: `${theme.spacing.xs} ${theme.spacing.md}`,
            cursor: 'pointer',
            fontSize: theme.fontSizes.md,
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'right',
            marginTop: 2,

            '&:disabled': {
              opacity: 0.5,
              cursor: 'not-allowed',
            },

            '&:hover': {
              backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[7],
              border: 0,
            },

            // '&:not(:first-of-type)': {
            //   borderLeft: 0,
            // },

            [theme.fn.smallerThan(577)]: {
              backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
              fontWeight: 500,
              borderRadius: 0,
              border: 0,
              margin: 0,
            },

            '&[data-active]': {
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#ffe1b9',
              color: theme.colors.orange[8],

              [theme.fn.smallerThan(577)]: {
                border: 0,
                fontWeight: 600,
                backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
              },
            },
          },

          tabIcon: {
            marginRight: theme.spacing.xs,
            display: 'flex',
            alignItems: 'center',
          },

          tabsList: {
            backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[7],
            display: 'flex',
            // border: 'none',
            flexDirection: 'row',
            width: '100%',

            [theme.fn.smallerThan(577)]: {
              flexDirection: 'column',
              border: 'none',
            },
          },
        })}
        {...props}
      />
    </>
  );
}
