import { Flex, Radio } from '@mantine/core';

interface Props {
  backgroundCheckType: string;
  sendDataToParent: ({ backgroundCheckType }: { backgroundCheckType: string }) => void;
}

export default function BackgroundCheckType({ backgroundCheckType, sendDataToParent }: Props) {
  return (
    <>
      <Radio.Group
        value={backgroundCheckType}
        name='bgReason'
        onChange={(e) => {
          sendDataToParent({ backgroundCheckType: e });
        }}>
        <Flex gap={10} direction='column'>
          <Radio
            disabled={!!backgroundCheckType}
            value='1'
            label='I Would like to get my background checked for an ONE TIME FEE of 32.99 as I Understand that 99% of People
            hiring someone would like their background verified. My profile will be more visible to people looking to
            hire someone.'
            color='orange'
          />
          <Radio
            disabled={!!backgroundCheckType}
            value='2'
            label='I Would NOT like to get my background checked and with this option I understand that, I will be missing job
            requests placed by people who are looking for Background Checked Service Providers. My profile visibility is
            reduced. I Understand I will not be able to participate in Aidbys emergency booking services , where every
            background checked service provider gets a notification when a job is posted.'
            color='orange'
          />
        </Flex>
      </Radio.Group>
    </>
  );
}
