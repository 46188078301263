import { catchHandler } from './../utils/helpers/index';
import { useQuery } from '@tanstack/react-query';
import { axiosTokenInst } from './axios.config';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';
import { servicesTypeList } from './service_types.api';

export const createService = async (data: any) => {
  try {
    return await axiosTokenInst.post('service/create', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUserSpecificService = async (userId: any) => {
  try {
    return await axiosTokenInst.get(`service/user-specific/${userId}`);
  } catch (error: any) {
    console.log(error);
    toastMessageUpdate('error', error?.data?.message);
    throw error;
  }
};

export const useUserServiceById = (userId: string, dataHandler: (data: any) => void) => {
  return useQuery({
    queryKey: ['service', userId],
    queryFn: async () => {
      if (!userId) return;
      return await getUserSpecificService(userId);
    },
    onSuccess: (data) => {
      if (data) {
        data.data = Object.entries(data?.data).map(([key, value]: [string, any]) => {
          return {
            parent_id: key,
            service_type_id: key,
            ...value,
          };
        });
        dataHandler(data);
      }
    },
  });
};

export const updateService = async (data: any) => {
  try {
    return await axiosTokenInst.post('service/update', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteService = async (serviceId: any) => {
  try {
    const params = {
      service_id: serviceId,
    };
    return await axiosTokenInst.delete('service/delete', { params });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const liveLocation = async (params: any) => {
  return await axiosTokenInst.post(`/livelocation`, params);
};

export const useLiveLocation = (id: string, lat: any, lng: any, enabled: boolean) => {
  const params = {
    booking_id: id,
    latitude: lat,
    longitude: lng,
  };
  return useQuery({
    queryKey: ['live-location', id],
    queryFn: async () => await liveLocation(params),
    enabled: enabled,
    refetchInterval: enabled && 5000,
  });
};
export const serviceById = catchHandler(async (service_id: any) => {
  return await axiosTokenInst.get(`service/lists`, {
    params: {
      service_id,
    },
  });
});

export const useServiceById = (id: string) => {
  return useQuery({
    queryKey: ['service', id],
    queryFn: async () => await serviceById(id),
    enabled: id ? true : false,
  });
};

export const useAllServices = (userId: any, parent_id?: string, isEnable = true) => {
  return useQuery({
    queryKey: ['service-list', { parent_id }],
    queryFn: async () => {
      const parentRes = await servicesTypeList(parent_id);
      const userServices = await getUserSpecificService(userId); // '3' cookies?.user_Info?.id;

      const childServices = async () => {
        return new Promise((resolve) => {
          let withChildServicesArr: any[] = [];

          parentRes.data.map(async (item: any, index: number) => {
            const res = await servicesTypeList(item?.service_type_id);
            withChildServicesArr = [...withChildServicesArr, { ...item, parent: true, child: res.data }];
            if (index === parentRes.data.length - 1 && res.data) {
              console.log('done', res);
              setTimeout(() => {
                resolve(withChildServicesArr);
              }, 400);
            }
          });
        });
      };
      const allServices: any = await childServices();

      parentRes['data'] = {
        allServices: allServices.sort((a: any, b: any) =>
          `${a.service}`?.toLowerCase().localeCompare(b.service?.toLowerCase()),
        ),
        userServices: userServices.data,
      };
      return parentRes;
    },
    enabled: isEnable,
  });
};
