import { BackgroundImage, Box, Flex, Grid, Image } from '@mantine/core';
import { useMouse } from '@mantine/hooks';
import { ReactNode } from 'react';
import { LogoDarkText, LogoLight, loginImage } from '../../../assets';
import { useWrapperStyles } from './style';

export default function Wrapper({ children }: { children: ReactNode }) {
  const { classes, cx } = useWrapperStyles();
  const { ref, x, y } = useMouse();

  return (
    <>
      <Grid className={classes.wrapper} my='xl'>
        <Grid.Col
          md={6}
          ref={ref}
          sx={(theme) => ({
            [theme.fn.smallerThan(992)]: {
              display: 'none',
            },
            position: 'relative',
            minHeight: 350,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(80,80,181,0.7642244397759104) 57%, rgba(143,164,170,1) 100%) ,url(${loginImage})`,
            zIndex: 1,

            '::before': {
              position: 'absolute',
              content: `""`,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              minHeight: 350,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundImage: `radial-gradient(at ${x}px ${y}px, rgba(50, 127, 250, 0.6), rgba(102, 0, 208, 0.6)), url(${loginImage})`,
              // backgroundImage: `radial-gradient(200px circle at ${x}px ${y}px, rgba(255, 255, 255, 0.2), transparent 60%), url(${loginImage})`,
              // backgroundImage: `radial-gradient(150px circle at ${x}px ${y}px, rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0.6)), url(${loginImage})`,
              zIndex: -1,
              transition: 'opacity 0.8s ease-in-out',
              opacity: 0,
            },

            ':hover::before': {
              opacity: 1,
            },
          })}>
          <Flex p='xl' h='100%' justify='center' align='center' direction='column' ta='center'>
            <Box>
              <BackgroundImage src={loginImage}></BackgroundImage>
              <a
                style={{ textDecoration: 'none' }}
                target='_blank'
                href={`${process.env.REACT_APP_FRONTEND_BASE_URL}`}
                rel='noreferrer'>
                <Image src={LogoDarkText} width={200} height={200} className={classes.largeScreenLogo} />
              </a>
            </Box>
          </Flex>
        </Grid.Col>

        <Grid.Col
          sx={(theme) => ({
            [theme.fn.largerThan(992)]: {
              display: 'none',
            },
          })}>
          <Flex justify='center' align='center' h='100%'>
            <Image src={LogoLight} width={80} height={80} className={classes.smallScreenLogo} />
          </Flex>
        </Grid.Col>

        <Grid.Col md={6} className={classes.children}>
          {children}
        </Grid.Col>
      </Grid>
    </>
  );
}
