import {
  Box,
  Drawer,
  DrawerProps,
  Flex,
  Modal,
  ModalBaseOverlayProps,
  UnstyledButton,
  createStyles,
  getStylesRef,
  rem,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowLeft, IconX } from '@tabler/icons-react';
import { CSSProperties } from 'react';

const useModalOrDrawerStyles = createStyles((theme) => ({
  drawerContainerBox: {
    padding: `0 ${rem(16)} ${rem(16)} ${rem(16)}`,
    userSelect: 'none',
  },

  modalContainerBox: {
    padding: `0 ${rem(16)} ${rem(16)} ${rem(16)}`,
    userSelect: 'none',
  },

  crossIconButtonContainer: {
    color: '#888888',
    transition: '120ms',
    ':hover': {
      backgroundColor: 'none',
      color: '#fe7720',
      transition: '120ms',
    },

    [`&:hover .${getStylesRef('cross-button')}`]: {
      transition: '120ms',
    },

    [theme.fn.smallerThan(451)]: {
      display: 'none',
    },
  },

  crossIconButton: {
    ref: getStylesRef('cross-button'),
    width: 20,
    height: 20,
    transition: '120ms',
  },

  backIconButtonContainer: {
    color: '#888888',

    [theme.fn.largerThan(450)]: {
      display: 'none',
    },
  },

  backIconButton: {
    width: 20,
    height: 20,
  },
}));

interface Props {
  children: React.ReactNode;
  opened: boolean;
  onClose: () => void;
  closeOnClickOutside?: boolean;
  drawerProps: {
    dz: DrawerProps['size'];
    drawerBackButton: boolean;
    drawerOverlayProps?: ModalBaseOverlayProps;
    bs?: CSSProperties['boxShadow'];
  };
  modalProps: {
    modalCloseButton: boolean;
  };
}

/*
    This component will be used as container whenever same content is needed
        1. in a drawer (for mobile devices)
        2. in a modal (for desktop devices)
*/
export default function ModalOrDrawer({
  children,
  opened,
  onClose: close,
  closeOnClickOutside,
  drawerProps: { dz, bs, drawerBackButton, drawerOverlayProps },
  modalProps: { modalCloseButton },
}: Props) {
  const matches = useMediaQuery(`(max-width: 450px)`);
  const { classes: modalOrDrawerClasses } = useModalOrDrawerStyles();
  return (
    <>
      {matches ? (
        <>
          <Drawer
            opened={opened}
            onClose={close}
            position={'bottom'}
            size={dz}
            closeOnClickOutside={closeOnClickOutside ?? false}
            withCloseButton={false}
            overlayProps={drawerOverlayProps ?? { opacity: 0.5, blur: 8 }}
            transitionProps={{
              transition: 'slide-up',
              duration: 120,
              timingFunction: 'ease-in-out',
            }}
            styles={(theme) => ({
              content: {
                backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
                boxShadow: bs ?? 'none',
              },
            })}>
            {drawerBackButton && (
              <UnstyledButton onClick={close} className={modalOrDrawerClasses.backIconButtonContainer}>
                <IconArrowLeft className={modalOrDrawerClasses.backIconButton} />
              </UnstyledButton>
            )}
            <Box className={modalOrDrawerClasses.drawerContainerBox}>{children}</Box>
          </Drawer>
        </>
      ) : (
        <>
          <Modal
            opened={opened}
            onClose={close}
            withCloseButton={false}
            centered
            // size={'auto'}
            closeOnClickOutside={closeOnClickOutside ?? false}
            transitionProps={{
              transition: 'pop',
              duration: 120,
              timingFunction: 'ease-in-out',
            }}
            // overlayProps={{
            //   opacity: 0,
            //   blur: 0,
            // }}
          >
            {modalCloseButton && (
              <Flex justify='flex-end'>
                <UnstyledButton onClick={close} className={modalOrDrawerClasses.crossIconButtonContainer}>
                  <IconX className={modalOrDrawerClasses.crossIconButton} />
                </UnstyledButton>
              </Flex>
            )}
            <Box className={modalOrDrawerClasses.modalContainerBox}>{children}</Box>
          </Modal>
        </>
      )}
    </>
  );
}
