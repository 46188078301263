import { createStyles, getStylesRef } from '@mantine/core';

export const useMobileNumberInputForOtpStyles = createStyles((theme) => ({
  crossIconButtonContainer: {
    color: '#888888',
    transition: '120ms',
    ':hover': {
      backgroundColor: 'none',
      color: '#fe7720',
      transition: '120ms',
    },

    [`&:hover .${getStylesRef('button')}`]: {
      transition: '120ms',
    },

    [theme.fn.smallerThan(451)]: {
      display: 'none',
    },
  },

  crossIconButton: {
    ref: getStylesRef('button'),
    width: 20,
    height: 20,
    transition: '120ms',
  },

  backIconButtonContainer: {
    color: '#888888',

    [theme.fn.largerThan(450)]: {
      display: 'none',
    },
  },

  backIconButton: {
    width: 20,
    height: 20,
  },
}));
