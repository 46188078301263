import { Avatar, Box, Button, Card, Flex, Paper, Stack, Text, createStyles } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import { HeaderAvatarIcon } from '../../../../layouts/authLayout/component/styles';
import { makeUpperCase } from '../../../../utils/helpers/makeFirstLetterUpper';
import { IconArrowUpRight } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { serviceIconHandler } from '../../../../utils/helpers';
import { useCookies } from 'react-cookie';

// const useStyles = createStyles((theme) => ({
//   card: {
//     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
//     padding: theme.spacing.md,
//     boxShadow: theme.shadows.xs,
//   },
//   body: {
//     padding: theme.spacing.md,
//   },
//   like: {
//     color: theme.colors.red[6],
//   },
// }));

const serviceCardStyles = createStyles((theme) => ({
  card: {
    height: '100%',
    backgroundColor: theme.colorScheme === 'light' ? '#feede5' : theme.colors.dark[7],
    borderStyle: 'none !important',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
      boxShadow: '0 0 8px 2px #FFD8A9',
    },
  },
}));

const useIconStyles = createStyles((theme) => ({
  image: {
    objectFit: 'contain',
  },
}));

interface IServiceProps {
  image: string;
  title: string;
  navigateTo: string;
  description: string;
  rate: number;
}

// service card ui handling by props.
export function ServiceCard({ image, title, navigateTo, description, rate, ...props }: IServiceProps) {
  const { classes } = serviceCardStyles();
  const { classes: AvatarStyle } = HeaderAvatarIcon();
  const { classes: iconStyles } = useIconStyles();
  const enable = true;
  const isMobile = useMediaQuery('(max-width: 576px)');

  const [cookie] = useCookies(['user_Info']);
  // console.log(cookie.user_Info.type);

  return (
    // <Paper withBorder radius='md' className={classes.card}>
    //   <Flex>
    //     <Avatar classNames={AvatarStyle} mr={10} size={75} radius={50} src={image}></Avatar>
    //     <Stack spacing={7}>
    //       <Title order={4}>{title}</Title>
    //       <Text>${rate}/hr</Text>
    //       <Text size={14} c='dimmed'>
    //         {description}
    //       </Text>
    //       <Button component={NavLink} to={navigateTo} style={{ alignSelf: 'self-start' }} mt='xs'>
    //         Book Now
    //       </Button>
    //     </Stack>
    //   </Flex>
    // </Paper>
    <Paper withBorder className={classes.card} p={10}>
      <Flex direction='column' h='100%' justify='space-between' align='start' gap={7}>
        <Avatar src={serviceIconHandler(title)} classNames={iconStyles}></Avatar>
        <Text>{makeUpperCase(title)}</Text>
        {+cookie.user_Info.type === 2 ? (
          <>
            <Button
              styles={(theme) => ({
                root: {
                  '&[data-disabled]': {
                    backgroundColor: 'transparent',
                    cursor: 'not-allowed',
                    pointerEvents: 'all',
                    color: theme.colorScheme === 'light' ? '#a7a7a7' : '#6e6e6e',
                  },
                },
              })}
              pl={0}
              disabled={+cookie.user_Info.type === 2}
              c='#FE7D49'
              variant='transparent'
              rightIcon={<IconArrowUpRight size={20} />}>
              <Text>Book now</Text>
            </Button>
          </>
        ) : (
          <>
            <Button
              pl={0}
              c='#FE7D49'
              variant='transparent'
              component={NavLink}
              to={navigateTo}
              rightIcon={<IconArrowUpRight size={20} />}>
              Book now
            </Button>
          </>
        )}
      </Flex>
    </Paper>
  );
}
