import { Group, Text, UnstyledButton } from '@mantine/core';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { useBackButtonStyles } from '../style';

interface Props {
  renderIf: boolean;
  onClick: () => void;
}

export default function BackButton({ onClick, renderIf }: Props) {
  const { classes } = useBackButtonStyles();
  return (
    <>
      {renderIf ? (
        <UnstyledButton onClick={onClick} className={classes.backButton}>
          <Group spacing={2}>
            <IconArrowNarrowLeft />
            <Text>Back</Text>
          </Group>
        </UnstyledButton>
      ) : null}
    </>
  );
}
