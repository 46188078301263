import { useDisclosure } from '@mantine/hooks';
import { createContext, useEffect, useState } from 'react';

export const useService = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedService, setSelectedService] = useState<any>();
  const [serviceTypeId, setServiceTypeId] = useState<string>('');
  const [buttonType, setButtonType] = useState<string>('');
  const [deleteOpened, { open: deleteOpen, close: deleteClose }] = useDisclosure(false);
  const [createdService, setCreatedService] = useState<any>();

  return {
    opened,
    open,
    close,
    selectedService,
    setSelectedService,
    serviceTypeId,
    setServiceTypeId,
    setButtonType,
    buttonType,
    deleteOpened,
    deleteOpen,
    deleteClose,
    createdService,
    setCreatedService,
  };
};

export const ServiceContext = createContext({} as ReturnType<typeof useService>);
