import { Global } from '@mantine/core';
import {
  LatoBold,
  LatoLight,
  LatoRegular,
  PlayfairBold,
  PlayfairExtraBold,
  PlayfairMedium,
  PlayfairRegular,
  PlayfairSemiBold,
  RalewayRegular,
  RalewaySemiBold,
  RalewayBold,
} from '../../assets';

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Lato',
            src: `url('${LatoLight}') format("truetype")`,
            fontWeight: 300,
            fontStyle: 'Light',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Lato',
            src: `url('${LatoRegular}') format("truetype")`,
            fontWeight: 400,
            fontStyle: 'Regular',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Lato',
            src: `url('${LatoBold}') format("truetype")`,
            fontWeight: 700,
            fontStyle: 'Bold',
          },
        },

        {
          '@font-face': {
            fontFamily: 'Playfair Display',
            src: `url('${PlayfairRegular}') format("truetype")`,
            fontWeight: 400,
            fontStyle: 'Regular',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Playfair Display',
            src: `url('${PlayfairMedium}') format("truetype")`,
            fontWeight: 500,
            fontStyle: 'Medium',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Playfair Display',
            src: `url('${PlayfairSemiBold}') format("truetype")`,
            fontWeight: 600,
            fontStyle: 'SemiBold',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Playfair Display',
            src: `url('${PlayfairBold}') format("truetype")`,
            fontWeight: 700,
            fontStyle: 'Bold',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Playfair Display',
            src: `url('${PlayfairExtraBold}') format("truetype")`,
            fontWeight: 800,
            fontStyle: 'ExtraBold',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Raleway',
            src: `url('${RalewayRegular}') format("truetype")`,
            fontWeight: 400,
            fontStyle: 'Regular',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Raleway',
            src: `url('${RalewaySemiBold}') format("truetype")`,
            fontWeight: 600,
            fontStyle: 'SemiBold',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Raleway',
            src: `url('${RalewayBold}') format("truetype")`,
            fontWeight: 700,
            fontStyle: 'Bold',
          },
        },
      ]}
    />
  );
}
