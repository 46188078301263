import { createStyles, em } from '@mantine/core';

export const useModalStyles = createStyles((theme) => ({
  header: {
    // border: `1px solid ${theme.colors.gray[3]}`,
    marginBottom: theme.spacing.md,
    // fontFamily: 'Playfair Display, sans-serif',
    fontWeight: 600,
  },
  title: {
    fontSize: theme.fontSizes.lg,
  },
  button: {
    borderRadius: '5px',
  },
}));

export const useBookingCardStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],
    transition: '200ms',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[5],
      boxShadow: `0px 0px 15px ${theme.colorScheme === 'light' ? '#FECDA6' : '#6f6155'}`,
      borderColor: theme.colorScheme === 'light' ? '#FECDA6' : '#907560',
      transition: '200ms',
    },
  },

  activeCard: {
    border: `${em(1.5)} solid ${theme.colorScheme === 'light' ? '#fe7720' : '#b0581f'} !important`,
    boxShadow: `0px 0px 10px ${theme.colorScheme === 'light' ? '#FECDA6' : '#6f6155'}`,
    transition: '200ms',
  },

  serviceNameText: {
    fontSize: 17,

    [theme.fn.smallerThan(992)]: {
      fontSize: 18,
    },

    [theme.fn.smallerThan(745)]: {
      fontSize: 16,
    },
  },

  icon: {
    width: 15,

    [theme.fn.smallerThan(600)]: {
      width: 12,
    },
  },

  dateTimeText: {
    [theme.fn.smallerThan(1255)]: {
      fontSize: 13.2,
    },

    [theme.fn.smallerThan(640)]: {
      fontSize: 13,
    },

    [theme.fn.smallerThan(600)]: {
      fontSize: 12,
    },

    [theme.fn.smallerThan(570)]: {
      fontSize: 11,
    },

    [theme.fn.smallerThan(540)]: {
      fontSize: 10.3,
    },

    [theme.fn.smallerThan(520)]: {
      fontSize: 13,
    },
  },

  iconOpened: {
    width: 14,
    [theme.fn.smallerThan(1255)]: {
      width: 13.2,
    },

    [theme.fn.smallerThan(1200)]: {
      width: 12.5,
    },
  },

  dateTimeTextIfOpened: {
    [theme.fn.smallerThan(1255)]: {
      fontSize: 13,
    },

    [theme.fn.smallerThan(1200)]: {
      fontSize: 12.5,
    },

    [theme.fn.smallerThan(1160)]: {
      fontSize: 12,
    },

    [theme.fn.smallerThan(1125)]: {
      fontSize: 11.4,
    },

    [theme.fn.smallerThan(1050)]: {
      fontSize: 10.5,
    },

    [theme.fn.smallerThan(992)]: {
      fontSize: 13,
    },

    [theme.fn.smallerThan(860)]: {
      fontSize: 11.5,
    },

    [theme.fn.smallerThan(800)]: {
      fontSize: 11,
    },
  },

  dateTimeTextIfThereIsMap: {
    [theme.fn.smallerThan(965)]: {
      fontSize: 13.5,
    },

    [theme.fn.smallerThan(920)]: {
      fontSize: 13.2,
    },

    [theme.fn.smallerThan(895)]: {
      fontSize: 12,
    },

    [theme.fn.smallerThan(840)]: {
      fontSize: 11.4,
    },

    [theme.fn.smallerThan(800)]: {
      fontSize: 10.8,
    },
  },

  dateTimeFlex: {
    // [theme.fn.smallerThan(540)]: {
    //   flexDirection: 'column',
    // },
  },

  serviceNameIconFlex: {
    height: 35,
    [theme.fn.smallerThan(1280)]: {
      height: 40,
    },
    [theme.fn.smallerThan(992)]: {
      height: 30,
    },
    [theme.fn.smallerThan(685)]: {
      height: 40,
    },
  },

  serviceNameIconFlexIfOpened: {
    height: 45,
  },
}));
