import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Flex,
  Grid,
  Group,
  Paper,
  PasswordInput,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  rem,
} from '@mantine/core';
import { upperFirst, useMediaQuery } from '@mantine/hooks';
import { IconBrandGoogle, IconChevronDown, IconEye, IconEyeOff, IconHelpOctagon } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { RegisterAPI } from '../../services/authentication.api';
import { toastMessageUpdate } from '../../utils/helpers/tosterMessage';
import { signUpSchema } from '../../utils/schema/formSchema';
import Wrapper from './component/Wrapper';
import { useLoginStyles } from './component/style';
import { useCookies } from 'react-cookie';
import ModalOrDrawer from '../dashboard/customer/Profile/components/ModalOrDrawer';
import ErrorText from '../../components/ErrorText';

export function GoogleButton(props: ButtonProps) {
  return <Button leftIcon={<IconBrandGoogle />} variant='default' color='gray' {...props} />;
}

export default function Register() {
  const { classes } = useLoginStyles();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery('(min-width: 480px)');
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signUpSchema),
    mode: 'all',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [cookie, setCookie] = useCookies(['mobilenumber', 'emailAddress']);
  const [calendlyModal, setCalendlyModal] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const registerData = {
      name: data.firstName.trim().concat(' ', data.lastName.trim()),
      email: data.email.toLowerCase(),
      mobile: +data.mobile,
      password: data.password.trim(),
      password_confirmation: data.password.trim(),
      type: +data.userType,
      // termsConditions: data.termsCondition,
    };
    try {
      const registrationData = await RegisterAPI(registerData);
      setLoading(false);
      setCookie('mobilenumber', +data.mobile, {
        path: '/',
      });

      setCookie('emailAddress', data.email, {
        path: '/',
      });

      navigate('/mobile-verification');
      toastMessageUpdate('success', 'Otp sent your mobile & email, please check & validate');
      console.log(registrationData);
    } catch (error: any) {
      setLoading(false);
      toastMessageUpdate('error', error?.response?.data?.message || '');
      console.log(error);
    }
  };

  const [registerAsList, setRegisterAsList] = useState<any[]>([]);
  const signUserType = () => {
    let list = [
      {
        value: '1',
        label: 'User',
        type: '14',
      },
      {
        value: '2',
        label: 'Service Provider',
        type: '23',
      },
      {
        value: '3',
        label: 'Organizational Service Provider',
        type: '23',
      },
      {
        value: '4',
        label: 'Organizational Service User',
        type: '14',
      },
    ];

    if (searchParams.get('type')) {
      list = list.filter((item) => item.type === searchParams.get('type'));
    }

    setRegisterAsList(list);
  };

  useEffect(() => {
    signUserType();
  }, [searchParams.get('type')]);

  const openCalendlyModal = () => {
    setCalendlyModal(true);
  };

  return (
    <>
      <Wrapper>
        <Paper className={classes.paper} radius='md' py={!isMobile ? 0 : 'xl'} px='xs' withBorder={false}>
          <Title ta='center' order={1} fw={600}>
            Sign up
          </Title>
          <Text ta='center' color='dimmed' mb={20}>
            Please enter your details
          </Text>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
            <Stack>
              <Grid>
                <Grid.Col lg={6} md={6} sm={12}>
                  <Stack>
                    <TextInput
                      {...register('firstName', { required: true })}
                      size='md'
                      label='First Name'
                      fw='lg'
                      placeholder='Enter your first name'
                    />
                    {errors?.firstName && (
                      <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                        {errors?.firstName?.message}
                      </Text>
                    )}
                  </Stack>
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={12}>
                  <Stack>
                    <TextInput
                      {...register('lastName', { required: true })}
                      size='md'
                      label='Last Name'
                      fw='lg'
                      placeholder='Enter your last name'
                    />
                    {errors?.lastName && (
                      <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                        {errors?.lastName?.message}
                      </Text>
                    )}
                  </Stack>
                </Grid.Col>

                <Grid.Col lg={6} md={6} sm={12}>
                  {/* <Stack> */}
                  <Flex>
                    <Select
                      label='Phone'
                      labelProps={{ fz: 'md' }}
                      rightSection={<IconChevronDown size='1rem' style={{ cursor: 'pointer' }} />}
                      styles={(theme) => ({
                        wrapper: {
                          height: 42,
                        },
                        input: {
                          height: 42,
                          borderRadius: `${rem(8)} 0 0 ${rem(8)}`,
                          paddingRight: 0,
                          border: `0.0625rem solid #e1e1e1`,
                        },
                        rightSection: { pointerEvents: 'none' },
                      })}
                      w={'30%'}
                      data={[
                        { value: '+91', label: '+91' },
                        { value: '+1', label: '+1' },
                      ]}
                      defaultValue={'+1'}
                    />
                    <TextInput
                      w={'70%'}
                      size='md'
                      mt={'29.4px'}
                      {...register('mobile', { required: true })}
                      placeholder='Enter your number'
                      radius='md'
                      styles={(theme) => ({
                        input: {
                          borderRadius: `0 ${rem(8)} ${rem(8)} 0`,
                          // borderLeft: 50,
                        },
                      })}
                    />
                  </Flex>
                  {errors?.mobile && (
                    <Text fz={14} mt={0} pl={5} fw={500} color='red'>
                      {errors?.mobile?.message}
                    </Text>
                  )}
                  {/* </Stack> */}
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={12}>
                  <Stack>
                    <TextInput
                      size='md'
                      {...register('email', { required: true })}
                      placeholder='Enter your email'
                      label='Email'
                      radius='md'
                      type='email'
                    />
                    {errors?.email && (
                      <Text fz={14} mt={-16} pl={5} fw={500} color='red'>
                        {errors?.email?.message}
                      </Text>
                    )}
                  </Stack>
                </Grid.Col>
                <Grid.Col lg={12} md={12} sm={12}>
                  <Stack>
                    <PasswordInput
                      {...register('password', { required: true })}
                      size='md'
                      label='Password'
                      placeholder='Enter your password'
                      radius='md'
                      visibilityToggleIcon={({ reveal }) =>
                        reveal ? <IconEye size={20} stroke={1} /> : <IconEyeOff size={20} stroke={1} />
                      }
                    />
                    {errors?.password && (
                      <Flex direction='row'>
                        <Text fz={14} fw={500} mt={-16} pl={5} color='red'>
                          {errors?.password?.message}
                        </Text>
                        <Box mt={-16} ml={2}>
                          <Tooltip
                            opened
                            label='Password must have a minimum of 6 characters including at least 1 uppercase letter, 1 numeric digit, and 1 special character (@, #, or !)'
                            color='red'
                            multiline
                            width={220}
                            position='right'
                            withArrow>
                            <IconHelpOctagon size={15} stroke={1.5} color='red' />
                          </Tooltip>
                        </Box>
                      </Flex>
                    )}
                  </Stack>
                </Grid.Col>
              </Grid>
              <Controller
                name='userType'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    size='md'
                    label='Sign Up As'
                    placeholder='Choose sign up as'
                    rightSection={<IconChevronDown size='1rem' />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={registerAsList}
                  />
                )}
              />
              {errors?.userType && (
                <Text fz={14} fw={500} mt={-16} pl={5} color='red'>
                  {errors?.userType?.message}
                </Text>
              )}
              <Checkbox
                radius='sm'
                label={
                  <>
                    <span>I accept</span>{' '}
                    <a
                      style={{ textDecoration: 'none' }}
                      target='_blank'
                      rel='noreferrer'
                      href={`${process.env.REACT_APP_FRONTEND_BASE_URL}terms-conditions`}>
                      terms & conditions
                    </a>
                    &nbsp; and &nbsp;
                    <a
                      style={{ textDecoration: 'none' }}
                      target='_blank'
                      rel='noreferrer'
                      href={`${process.env.REACT_APP_FRONTEND_BASE_URL}privacy-policy`}>
                      privacy policy
                    </a>
                  </>
                }
                {...register('termsCondition')}
              />
              {errors?.termsCondition && (
                <Text fz={14} fw={500} mt={-7} pl={5} color='red'>
                  {errors?.termsCondition?.message}
                </Text>
              )}
              <Checkbox
                radius='sm'
                label={
                  <Flex>
                    <Text>
                      I consent to receive Text Messages, Email Communications and Phone Calls by{' '}
                      <a
                        style={{ textDecoration: 'none' }}
                        target='_blank'
                        rel='noreferrer'
                        href={`${process.env.REACT_APP_FRONTEND_BASE_URL}`}>
                        aidby.com
                      </a>
                    </Text>
                  </Flex>
                }
                {...register('receiveMessageEmailPhoneCall')}
              />
              {errors?.receiveMessageEmailPhoneCall && (
                <Text fz={14} fw={500} mt={-7} pl={5} color='red'>
                  {errors?.receiveMessageEmailPhoneCall?.message}
                </Text>
              )}
            </Stack>

            <Flex justify='center' direction='column' mt='xl'>
              <Button
                variant='gradient'
                gradient={{ from: '#fda223', to: '#fe7720' }}
                radius='sm'
                loading={loading}
                fullWidth
                size='md'
                type='submit'>
                {!loading && upperFirst('Sign Up ')}
              </Button>
              <Flex justify='center' gap={5} mt={25} direction={isMobile ? 'row' : 'column'}>
                <Text ta='center'>Already have an account?</Text>
                <Link style={{ textDecoration: 'none' }} to='/login'>
                  <Text c='orange.9' ta='center'>
                    Sign In
                  </Text>
                </Link>
              </Flex>
            </Flex>
            <Flex justify='center' gap={5} mt={25} direction={isMobile ? 'row' : 'column'}>
              <Text ta='center'>Facing issue in Signup!</Text>
              <Box style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={openCalendlyModal}>
                <Text ta='center' c='orange.9'>
                  Schedule a call with us
                </Text>
              </Box>
            </Flex>
          </form>
          {/* <Divider label='Or continue with email' labelPosition='center' my='lg' />
          <GoogleButton w='100%' size='md' variant='filled' color='primary'>
            Continue with Google
          </GoogleButton> */}
        </Paper>
      </Wrapper>
      <ModalOrDrawer
        opened={calendlyModal}
        onClose={() => setCalendlyModal(false)}
        drawerProps={{ dz: 300, drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <Box className='calendly-inline-widget' style={{ height: '915px', minWidth: '100%' }}>
          <iframe
            src='https://calendly.com/aidby/30min'
            width='100%'
            className='calendly-iframe'
            height='100%'
            style={{ border: '0px none' }}></iframe>
        </Box>
      </ModalOrDrawer>
    </>
  );
}
