import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { catchHandler } from '../utils/helpers';
import { axiosTokenInst } from './axios.config';

export const getWebsocketId = catchHandler(async () => {
  return await axiosTokenInst.get(`/get-ws-id`);
});

export const getNotification = catchHandler(async ({ pageParam, queryKey }: any) => {
  let pageNumber = 1;
  if (pageParam) {
    pageNumber = pageParam.match(/page=(.*)/)[1];
  }
  return await axiosTokenInst.get(`/get-notifications?type=${queryKey[1]}&page=${pageNumber}`);
});

export const sendNotification = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`/save-notification`, formData);
});

export const readNotification = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`/read-notification`, formData);
});

export const useNotificationtId = (notificationType: string) => {
  return useInfiniteQuery({
    queryKey: ['get-notification', notificationType],
    queryFn: getNotification,
    getNextPageParam: (lastPage: Record<any, any>, pages) => lastPage.notifications.notifications.next_page_url,
  });
};
