import { useQuery } from '@tanstack/react-query';
import { axiosTokenInst } from './axios.config';

export const createReview = async (params: any) => {
  return await axiosTokenInst.post('/reviews/save', params);
};

export const findReview = async (reviewId: string) => {
  return await axiosTokenInst.get('reviews/find', {
    params: {
      review_id: reviewId,
    },
  });
};

export const reviewList = async (review_type: string, entity_id: any) => {
  return await axiosTokenInst.get('reviews/list', {
    params: { review_type, entity_id },
  });
};

type ReviewDetailsResponse = {
  approved: number;
  booking_id: string;
  created_at: string;
  created_by: string;
  has_user: {
    id: number;
    name: string;
    email: string;
    email_verified_at: string | null;
    type: number;
    mobile: string;
    created_at: string;
    updated_at: string;
    chargebee_id: string;
    subscription_id: string;
    subscription_start: number;
    subscription_end: number;
    chargebee_card: boolean;
    onfido_applicant_id: string | null;
    onfido_form_uri: string | null;
    onfido_check_id: string | null;
    onfido_result: string | null;
    has_verification: {
      verification_id: string;
      background_check: null;
      drug_test: null;
      users_id: number;
      background_verified: number;
      drug_test_verified: number;
      created_at: string;
      created_by: string;
      updated_at: string;
      updated_by: string;
      business_license: null;
      business_license_verified: number;
      type: string;
      verification_amount_paid: string;
    };
    user_profile: {
      user_profile_id: string;
      experience: string;
      skills: string;
      intro: string;
      image: string;
      cover_image: string;
      currency: string;
      users_id: number;
      gender: string;
      total_reviews: null;
      withdrawal_requested: boolean;
      earnings: string;
      past_earnings: string;
      pending_amount: string;
    };
  };
  rating: string;
  review: string;
  review_by: string;
  review_id: string;
  review_to: string;
  review_type: number;
  updated_at: string;
  updated_by: string;
};

export const useReviewList = (review_type: string, entity_id: string | undefined, enabled = true) => {
  return useQuery<ReviewDetailsResponse[], Error>({
    queryKey: ['review-details', entity_id],
    // queryFn: async () => await reviewList(review_type, entity_id),
    queryFn: () =>
      axiosTokenInst
        .get<ReviewDetailsResponse[]>('reviews/list', {
          params: { review_type, entity_id },
        })
        .then((res) => res.data),
    enabled: enabled,
    staleTime: 60 * 60 * 1000,
  });
};
