export enum RoleType {
  customer = 1,
  service_provider,
  organization_service_provider,
  organization_service_consumer,
}

export enum ReviewType {
  user = 'user',
  service = 'service',
  booking = 'booking',
}

export const customerRole = [RoleType.customer, RoleType.organization_service_consumer];

export const providerRole = [RoleType.service_provider, RoleType.organization_service_provider];
