import { ActionIcon, Anchor, Group, rem, Text } from '@mantine/core';
import { IconHeart } from '@tabler/icons-react';
import { useContext, useState } from 'react';
import { HeaderMenuStyle } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { customerRole } from '../../../utils';
import { useCookies } from 'react-cookie';
import { useDisclosure } from '@mantine/hooks';
import { ProfileMenu } from './HeaderTop';
import EndVideoCall from '../../../components/EndVideoCall';
import { ThemeContext } from '../../../context/themeContext';
import MessageNotification from './MessageNotification';

const HeaderMenu = () => {
  const { classes: menuClass } = HeaderMenuStyle();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['access_token', 'role', 'user_Info']);
  const [videoCallOn, setVideoCallOn] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const location = useLocation();
  const { setChatType, setChannelId } = useContext(ThemeContext);

  // To check any ongoing video call is running or not.
  // If any video call is running then set it on a state.
  const ongoingVideoCall = (params: any) => {
    if (params) {
      setVideoCallOn(params);
    } else {
      setVideoCallOn(false);
    }
  };

  const handleNavigation = (page: string) => {
    // As per page type string, navigate to user specific page.
    if (videoCallOn) {
      open();
      return;
    } else {
      switch (page) {
        // case 'search':
        //   navigate('/');
        //   break;
        case 'favourites':
          navigate('/favourites');
          break;
        case 'message':
          if (location.pathname !== '/chat') {
            setChatType('userChat');
            setChannelId(null);
            navigate('/chat');
          }
          break;
        default:
          navigate('/');
      }
    }
  };

  return (
    <>
      <Group>
        {/* Favourites should not be shown to provider; the role of provider is 2 */}
        {customerRole.includes(+cookies.role) && (
          <Anchor
            className={menuClass.headerCommon}
            onClick={() => {
              handleNavigation('favourites');
            }}
            underline={false}>
            <ActionIcon className={location.pathname === '/favourites' ? menuClass.active : menuClass.icon}>
              <IconHeart size={rem(18)} />
            </ActionIcon>
            <Text className={location.pathname === '/favourites' ? menuClass.active : menuClass.text}>Favourites</Text>
          </Anchor>
        )}

        <MessageNotification />
        <ProfileMenu ongoingVideoCall={ongoingVideoCall} />
      </Group>

      <EndVideoCall opened={opened} close={close} />
    </>
  );
};

export default HeaderMenu;
