const mapLangLat = [
  [88.47496, 22.726156],
  [88.388344, 22.767427],
  [88.336338, 22.75893],
];

export const langLatModify = () => {
  return mapLangLat.map(([lng, lat], index) => {
    return {
      lat,
      lng,
    };
  });
};
