import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
export const axiosInst = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Axios token instance setup for token based API
export const axiosTokenInst = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosTokenInst.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const cookies = new Cookies();
  const token = cookies.get('access_token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Axios response instance setup for token based API
axiosTokenInst.interceptors.response.use(
  (res: AxiosResponse) => {
    return res.data;
  },
  (err: AxiosError<any>) => {
    if (err.response?.status === 401) {
      const cookies = new Cookies();
      (location.href = '/login'),
        cookies.remove('access_token', {
          path: '/',
        });
      cookies.remove('user_Info', {
        path: '/',
      });
    } else if (err.response?.status === 429) {
      toast.warning(err?.response?.data?.message || err.message || 'Something went wrong');
    }
    return Promise.reject(err.response);
  },
);
