import { useNavigate } from 'react-router-dom';

/*
    This function will accept a random string in BASE-64 encoded format.
    After decoding (if it is possible), the value will be in the following format:
    random-16-char + {provider/customer id} + random-16-char
    
    The function will get the provider or customer id from this using a regex and return it.
 */
export default function decodeUserIdFromRandomizedString(encodedIdWithRandomString: string | undefined): string {
  const navigate = useNavigate();
  if (!encodedIdWithRandomString) return '';
  try {
    const decodedIdWithString = atob(encodedIdWithRandomString);
    const match = decodedIdWithString.match(/\d+/);
    const decodedId = match ? match[0] : '';
    return decodedId;
  } catch (error) {
    navigate('/content-not-found');
    return '';
  }
}
