import {
  Badge,
  Button,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Skeleton,
  Text,
  Title,
  createStyles,
  getStylesRef,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconArrowNarrowLeft, IconCheckbox } from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import PaymentModal from '../../../components/PaymentModal';
import { ThemeContext } from '../../../context/themeContext';
import { createSubscription } from '../../../services/organization.api';
import { chargebeeSubscriptionUpdate } from '../../../services/payment.api';
import { subscriptionLimit, useSubscriptionList } from '../../../services/subscription.api';
import { updateUserProfile, useUserProfile } from '../../../services/user.api';
import { providerRole } from '../../../utils';
import { toastMessageUpdate } from '../../../utils/helpers/tosterMessage';
import { rightToLeftAnimation } from '../MyBooking';
import SubscriptionModal from './components/SubscriptionModal';

export const useSubscriptionStyle = createStyles((theme) => ({
  gridCol: {
    animation: `${rightToLeftAnimation} 0.35s linear`,
  },

  subscribe_card: {
    position: 'relative',
    boxShadow: '5px 6px 18px -7px rgba(0,0,0,0.42)',
    '&:before': {
      content: `""`,
      height: '100%',
      width: '100%',
      display: 'block',
      position: 'absolute',
      inset: 1,
      background: theme.fn.gradient({ from: '#fda223', to: '#fe7720' }),
      opacity: '0',
      transition: 'opacity 0.6s ease-in-out',
      borderRadius: 5,
    },

    '&:hover': {
      color: 'black',
      '&:before': {
        opacity: '1',
      },
      '.main_bd': {
        position: 'relative',
      },

      [`& .${getStylesRef('child-btn')}`]: {
        background: 'white',
        color: theme.colors.orange,
      },

      [`& .${getStylesRef('child-icon')}`]: {
        color: theme.colors.dark[8],
      },

      [`& .${getStylesRef('list-items')}`]: {
        color: theme.colors.dark[8],
      },

      [`& .${getStylesRef('child-divider')}`]: {
        borderTopColor: theme.colors.orange[9],
      },
    },
  },
  chooseBtn: {
    ref: getStylesRef('child-btn'),
    background: theme.fn.gradient({ from: '#fda223', to: '#fe7720' }),
    borderRadius: 5,
    height: 50,
    color: '#fff',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 10,
    textTransform: 'capitalize',
    border: 'none',
  },

  disabledButton: {
    background: theme.fn.gradient({ from: '#fda223', to: '#fe7720' }),
    borderRadius: 5,
    height: 50,
    color: '#fff',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 10,
    textTransform: 'capitalize',
    border: 'none',
    '&:hover': {
      cursor: 'copy',
    },
  },

  listItems: {
    ref: getStylesRef('list-items'),
  },

  icon: {
    ref: getStylesRef('child-icon'),
    color: 'orange',
  },

  divider: {
    ref: getStylesRef('child-divider'),
  },
}));

export default function Subscription() {
  const [cookie, setCookie] = useCookies(['user_Info', 'user_Address', 'subscription_status', 'access_token', 'role']);
  const { data, isLoading } = useSubscriptionList(cookie?.user_Info?.type);
  const { classes } = useSubscriptionStyle();
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [openPromotionModal, setOpenPromotionModal] = useState(false);
  const { data: userProfileData, refetch }: any = useUserProfile();
  const [subscriptionData, setSubscriptionData] = useState<any>();
  const { setSelectedSubscriptionPlan, setSubscriptionExhausted, selectedSubscriptionPlan } = useContext(ThemeContext);
  const [planDetails, setPlanDetails] = useState<any>(null);
  const [subOpened, { open: subOpen, close: subClose }] = useDisclosure(false);

  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const theme = useMantineTheme();

  console.log(data);

  // set user information.
  useEffect(() => {
    if (userProfileData?.data?.user) {
      setCookie('user_Info', userProfileData?.data?.user, { path: '/' });
    }
    // If user not subscribe any plan, then show promotional modal
    if (cookie?.access_token && !cookie?.user_Info?.subcription_id && (cookie?.role === '2' || cookie?.role === '3')) {
      // setOpenPromotionModal(true);
    }
  }, [subscriptionData]);

  // create subscription api call and its functionality.
  const navigateHandler = async (id?: string) => {
    try {
      const formData = {
        subcription_id: subscriptionId || id,
      };
      setLoading(true);
      await updateUserProfile(formData);
      const subFormData = {
        user_id: cookie.user_Info?.id,
      };
      const subscription = await createSubscription(subFormData);
      setSubscriptionData(subscription);
      const response = await subscriptionLimit();
      setSubscriptionExhausted(response);
      refetch();
      setLoading(false);
      toastMessageUpdate('success', 'You have subscribed successfully.');
      setCookie('subscription_status', false, {
        path: '/',
      });
      navigate(-1);
      // if (providerRole.includes(+cookie.role)) {
      //   navigate('/partner/profile');
      // } else {
      //   if (cookie.user_Address) {
      //     navigate('/', {
      //       replace: true,
      //     });
      //   } else {
      //     navigate('/my-profile', {
      //       replace: true,
      //     });
      //   }
      // }
    } catch (error: any) {
      setLoading(false);
      toastMessageUpdate('error', error?.data?.message || '');
      console.log(error);
    }
  };

  const upgradeSubscription = async (id?: string, item?: any) => {
    try {
      setLoading(true);
      if (+item?.amount > 0) {
        open();
        setPlanDetails(item);
        setLoading(false);
      } else {
        const formData = {
          subcription_id: subscriptionId || id,
        };
        await updateUserProfile(formData);
        const subFormData = {
          user_id: cookie.user_Info?.id,
        };
        const subscription: any = await chargebeeSubscriptionUpdate(subFormData);
        setSubscriptionData(subscription);
        setPlanDetails(null);
        toastMessageUpdate('success', subscription?.message);
        const response = await subscriptionLimit();
        setSubscriptionExhausted(response);
        refetch();
        setLoading(false);
        setCookie('subscription_status', false, {
          path: '/',
        });
        navigate(-1);
      }
    } catch (error: any) {
      setLoading(false);
      toastMessageUpdate('error', error?.data?.message || error || error?.message || '');
      console.log(error);
    }
  };

  const upgradePlan = () => {
    if (cookie.user_Info?.chargebee_id) {
      upgradeSubscription(selectedSubscriptionPlan?.subcription_id, selectedSubscriptionPlan);
    } else {
      setSubscriptionId(selectedSubscriptionPlan?.subcription_id);
      setPlanDetails(selectedSubscriptionPlan);
      open();
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading || isLoading} />
      <div>
        {/* <PromotionModal state={openPromotionModal} /> */}
        {/* <PaymentModal onPayment={navigateHandler} close={close} state={opened} /> */}
        <Grid m={0}>
          <Grid.Col span={isMobile || isTablet ? 12 : opened ? 6 : 12}>
            <Title order={3} pt={65} pb={isMobile || isTablet ? 3 : 20} px={10} fw={600}>
              Choose Your Plan
            </Title>
            {!(isMobile || isTablet) && opened && (
              <Group
                onClick={() => {
                  close();
                  setPlanDetails(null);
                }}
                style={{ cursor: 'pointer' }}
                spacing={2}
                c='orange'
                mt={-15}
                px={10}>
                <IconArrowNarrowLeft /> <Text>Back</Text>
              </Group>
            )}
            <Grid gutter={30} mt={isMobile || isTablet ? 1 : 20} m={0}>
              {isLoading && (
                <Grid.Col md={4}>
                  <Skeleton h={300} />
                </Grid.Col>
              )}
              {!planDetails &&
                !!data?.data?.length &&
                data?.data
                  .filter((item: any) => item?.user_type === +cookie.user_Info?.type)
                  .sort((a: any, b: any) => a.amount - b.amount)
                  .map((item: any) => {
                    return (
                      <>
                        <Grid.Col span={isMobile ? 12 : isTablet ? 6 : opened ? 8 : 4} key={item?.subcription_id}>
                          <Paper
                            bg={theme.colorScheme === 'dark' ? theme.colors.dark[6] : ''}
                            radius='md'
                            shadow='xl'
                            py={30}
                            className={classes.subscribe_card}
                            // className={classes.spinningBorder}
                          >
                            <Flex
                              direction='column'
                              h='100%'
                              gap={10}
                              className='main_bd'
                              style={{ justifyContent: 'flex-start', zIndex: 2 }}>
                              <Badge
                                radius={3}
                                mx={20}
                                w='fit-content'
                                style={{ letterSpacing: '3px', fontSize: '0.8rem' }}>
                                {+item?.amount > 0 ? 'Premium' : 'Basic'}
                              </Badge>
                              <Text size={isMobile ? 16 : 24} fw={600} mx={20} h={isMobile ? 45 : isTablet ? 60 : 65}>
                                {item?.name}
                              </Text>
                              <Flex gap={5} align='end'>
                                {+item?.amount > 0 ? (
                                  <>
                                    <Text fz={30} fw={600} p={0} lh={0.85} ml={20}>
                                      {item?.currency}
                                      {item?.amount}
                                    </Text>
                                    <Text lh={1}>for {item?.duration}</Text>
                                  </>
                                ) : (
                                  <Text fz={30} fw={600} p={0} lh={0.85} ml={20}>
                                    Free Forever
                                  </Text>
                                )}
                              </Flex>
                              <Divider my='sm' className={classes.divider} />
                              <List
                                mx={20}
                                spacing='xs'
                                size='sm'
                                center
                                icon={<IconCheckbox size='1.3rem' className={classes.icon} />}>
                                <List.Item className={classes.listItems}>{item?.details}</List.Item>
                                <List.Item className={classes.listItems}>Optional drug testing</List.Item>
                              </List>

                              <Button
                                mx={20}
                                className={classes.chooseBtn}
                                disabled={
                                  item?.subcription_id === cookie?.user_Info?.subcription_id &&
                                  !userProfileData?.data?.is_expired
                                }
                                sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                                onClick={() => {
                                  setSelectedSubscriptionPlan(item);
                                  if (userProfileData?.data?.is_expired) {
                                    if (cookie.user_Info?.chargebee_id) {
                                      upgradeSubscription(item?.subcription_id, item);
                                    } else {
                                      setSubscriptionId(item?.subcription_id);
                                      setPlanDetails(item);
                                      open();
                                    }
                                  } else {
                                    subOpen();
                                  }
                                }}>
                                Choose
                              </Button>
                            </Flex>
                          </Paper>
                        </Grid.Col>
                      </>
                    );
                  })}

              {planDetails && (
                <>
                  <Grid.Col span={isMobile ? 12 : isTablet ? 6 : opened ? 8 : 4} key={planDetails?.subcription_id}>
                    <Paper
                      bg={theme.colorScheme === 'dark' ? theme.colors.dark[6] : ''}
                      radius='md'
                      shadow='xl'
                      py={30}
                      className={classes.subscribe_card}>
                      <Flex direction='column' h='100%' gap={10} className='main_bd'>
                        <Badge radius={3} mx={20} w='fit-content' style={{ letterSpacing: '3px', fontSize: '0.8rem' }}>
                          Basic
                        </Badge>
                        <Text size={isMobile ? 16 : 24} fw={600} mx={20} h={isMobile ? 45 : isTablet ? 60 : 65}>
                          {planDetails?.name}
                        </Text>
                        <Flex gap={5} align='end'>
                          <Text fz={30} fw={600} p={0} lh={0.85} ml={20}>
                            {planDetails?.currency}
                            {planDetails?.amount}
                          </Text>
                          <Text lh={1}>for {planDetails?.duration}</Text>
                        </Flex>
                        <Divider my='sm' className={classes.divider} />
                        <List
                          mx={20}
                          spacing='xs'
                          size='sm'
                          center
                          icon={<IconCheckbox size='1.3rem' className={classes.icon} />}>
                          <List.Item className={classes.listItems}>{planDetails?.details}</List.Item>
                        </List>

                        <Button mx={20} className={classes.chooseBtn}>
                          Choose
                        </Button>
                      </Flex>
                    </Paper>
                  </Grid.Col>
                </>
              )}
            </Grid>
          </Grid.Col>

          {opened && (isMobile || isTablet) ? (
            <Drawer
              opened={opened}
              onClose={() => {
                close();
                setPlanDetails(null);
              }}
              position='bottom'
              size='lg'
              overlayProps={{ opacity: 0.5, blur: 4 }}>
              <PaymentModal
                type='withShadow'
                forbookingServices={false}
                onPayment={navigateHandler}
                close={() => {
                  close();
                  setPlanDetails(null);
                }}
                state={opened}
              />
            </Drawer>
          ) : (
            opened && (
              <Grid.Col span={6} className={classes.gridCol}>
                <PaymentModal
                  type='withShadow'
                  forbookingServices={false}
                  onPayment={navigateHandler}
                  close={close}
                  state={opened}
                />
              </Grid.Col>
            )
          )}
          <SubscriptionModal opened={subOpened} upgrade={upgradePlan} close={subClose} />
        </Grid>
      </div>
    </>
  );
}
