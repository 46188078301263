import { Button } from '@mantine/core';
import { IconCategory, IconMenu2 } from '@tabler/icons-react';
import { useProfileViewButtonStyles } from '../style';

type ProfileView = 'list' | 'grid';

interface Props {
  profilesViewType: ProfileView;
  onClick: (type: ProfileView) => void;
}

export default function ProfileViewButton({ profilesViewType, onClick }: Props) {
  const { classes, cx } = useProfileViewButtonStyles();
  return (
    <>
      <Button
        variant='default'
        className={cx(classes.button)}
        leftIcon={
          <IconMenu2 color={profilesViewType === 'list' ? '#fe7720' : 'black'} onClick={() => onClick('list')} />
        }
        rightIcon={
          <IconCategory color={profilesViewType === 'grid' ? '#fe7720' : 'black'} onClick={() => onClick('grid')} />
        }>
        |
      </Button>
    </>
  );
}
