import { MantineThemeOverride, rem } from '@mantine/core';

export const mantinethemeConfig: MantineThemeOverride = {
  dir: 'ltr',
  focusRing: 'auto',
  loader: 'oval',
  primaryShade: 7,
  // primaryShade: 7,
  defaultRadius: 'md',
  lineHeight: 1.4,
  fontFamily: 'Lato, sans-serif',
  headings: {
    fontFamily: 'Raleway, sans',
    fontWeight: 700,
  },
  primaryColor: 'orange',
  fontSizes: {
    xs: rem('11px'),
    sm: rem('14px'),
    md: '16px',
    lg: '22px',
    xl: '28px',
  },
  colors: {
    orange: [
      '#FFEFE9',
      '#FFDFD1',
      '#FFCEBC',
      '#FFBEA4',
      '#FFAE8D',
      '#FE9E76',
      '#FE8E5F',
      '#FE7D49',
      '#FE6D32',
      '#FE5D1B',
    ],
    yellow: [
      '#FFF8E9',
      '#FFF1D3',
      '#FFEABE',
      '#FFE3A7',
      '#FFDC91',
      '#FED57B',
      '#FECE66',
      '#FEC74E',
      '#FEC039',
      '#FEB922',
    ],
    violet: [
      '#FFEEE6',
      '#E4E2FB',
      '#D6D4F9',
      '#C8C5F7',
      '#BBB7F5',
      '#ADA9F3',
      '#9F9AF1',
      '#918CEF',
      '#847DED',
      '#766FEB',
    ],
    dark: [
      '#ced4da',
      '#acaebf',
      '#8c8fa3',
      '#666980',
      '#4d4f66',
      '#34354a',
      '#2b2c3d',
      '#1d1e30',
      '#0c0d21',
      '#01010a',
    ],
  },
  shadows: {
    xs: 'rgba(0, 0, 0, 0.2) 0px 25px 15px -20px',
    sm: '4.1px 8.1px 19.4px rgba(0, 0, 0, 0.049), 33px 65px 80px rgba(0, 0, 0, 0.07)',
    md: `rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;`,
    // xl: 'rgba(0, 0, 0, 0.2) 0px 25px 15px 20px',
  },
  components: {
    TextInput: {
      styles: (theme) => ({
        label: {
          paddingBottom: '7px',
          fontWeight: 700,
        },
        input: {
          backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],
          borderColor: theme.colorScheme === 'light' ? '#e1e1e1' : '#aeaeae',
        },
      }),
    },

    PasswordInput: {
      styles: (theme) => ({
        label: {
          paddingBottom: '7px',
          fontWeight: 700,
        },
        input: {
          backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],
          borderColor: theme.colorScheme === 'light' ? '#e1e1e1' : '#aeaeae',
        },
      }),
    },

    MonthPickerInput: {
      styles: (theme) => ({
        input: {
          backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],
          borderColor: '#e1e1e1',
        },
      }),
    },

    YearPickerInput: {
      styles: (theme) => ({
        input: {
          backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],
          borderColor: '#e1e1e1',
        },
      }),
    },

    Select: {
      styles: (theme) => ({
        label: {
          paddingBottom: '7px',
          fontWeight: 700,
        },
        input: {
          backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],
          borderColor: theme.colorScheme === 'light' ? '#e1e1e1' : '#aeaeae',
        },
      }),
    },
    Textarea: {
      styles: (theme) => ({
        label: {
          paddingBottom: '7px',
          fontWeight: 'bold',
          fontSize: '16px',
        },
        input: {
          backgroundColor: theme.colorScheme === 'light' ? '#ececec' : theme.colors.dark[6],
          borderColor: theme.colorScheme === 'light' ? '#e1e1e1' : '#aeaeae',
        },
      }),
    },
    Checkbox: {
      styles: () => ({
        input: {
          borderRadius: '5px',
        },
        label: {
          userSelect: 'none',
        },
      }),
    },

    Title: {
      styles: () => ({
        root: {
          fontFamily: 'Raleway, sans',
          fontWeight: 700,
        },
      }),
    },

    Modal: {
      styles: () => ({
        content: {
          borderRadius: '20px',
        },
      }),
    },

    PinInput: {
      styles: (theme) => ({
        input: {
          backgroundColor: theme.colorScheme === 'light' ? '#f6f6f6' : theme.colors.dark[6],
        },
      }),
    },

    Drawer: {
      styles: () => ({
        // header: {
        //   height: '40px',
        // },
        content: {
          borderRadius: '25px 25px 0 0',
        },
        close: {
          display: 'none',
        },
      }),
    },

    Indicator: {
      styles: () => ({
        root: {
          border: 'none',
        },
      }),
    },
  },
};
