import { Button, Paper, Text, ThemeIcon, Title, rem } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

export default function OtpVerificationSuccess() {
  const navigate = useNavigate();
  return (
    <>
      <Paper p={15} ta='center' mt={30}>
        <ThemeIcon size={80} radius={200} top={-45} pos='relative'>
          <IconCheck size={rem(60)} />
        </ThemeIcon>
        <Title c='orange' mb={10} mt={-35}>
          Success
        </Title>
        <Text>OTP successfully verified</Text>
        <Text c='dimmed' fz={13}>
          You will be redirected to the login page
        </Text>
        <Button
          fullWidth
          mt='xs'
          radius={4}
          variant='gradient'
          gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
          size='md'
          onClick={() => {
            navigate('/login');
          }}>
          OK
        </Button>
      </Paper>
    </>
  );
}
