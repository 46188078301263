import { Card, ScrollArea, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSimilarProfile } from '../services/user.api';
import { RelatedProfileCard } from './RelatedProfileCard';
import { useSimilarProfilesStyles } from './style';
import generateRandomString from '../utils/helpers/generateRandomAlphabetStrings';
import decodeUserIdFromRandomizedString from '../utils/helpers/decodeUserIdFromRandomizedString';

export default function SimilarProfiles({ id, title, withBorder = true }: any) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { encodedIdWithRandomString } = useParams();
  const userProfileId = decodeUserIdFromRandomizedString(encodedIdWithRandomString);
  const { data, isLoading, isError } = useSimilarProfile({ service_type_id: id });
  const [relatedUser, setRelatedUser] = useState<any>([]);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const { classes: similarProfileClasses } = useSimilarProfilesStyles();

  // filter similar types of profile based on service
  useEffect(() => {
    if (data?.data?.length) {
      if (userProfileId) {
        const filterProfile = data?.data.filter((item: any) => item?.users_id !== Number(userProfileId));
        setRelatedUser(filterProfile);
        // } else if (partnerId) {
        //   const filterProfile = data?.data.filter((item: any) => item?.users_id !== Number(partnerId));
        //   setRelatedUser(filterProfile);
      }
    }
  }, [data, userProfileId]);

  return (
    <>
      <Card className={similarProfileClasses.relatedProfilesCardContainer}>
        <Title order={isMobile ? 5 : 3}>{title}</Title>
        {isError && (
          <Text mt='xs' c='dimmed'>
            Error while fetching data..
          </Text>
        )}
        {relatedUser?.length > 0 ? (
          <>
            <ScrollArea.Autosize mah={`calc(100svh * 0.42)`} offsetScrollbars type='always' scrollbarSize={2}>
              {relatedUser?.map((profile: any, index: number) => {
                return (
                  <RelatedProfileCard
                    key={index}
                    image={profile?.['image']}
                    role={profile?.['name']}
                    location={profile?.['distance_in_miles']}
                    experience={profile?.['experience']}
                    click={() =>
                      navigate(
                        `/user/${btoa(
                          generateRandomString(16) + profile?.users_id + generateRandomString(16),
                        )}${search}`,
                      )
                    }
                    rate={profile?.['rate']}
                  />
                );
              })}
            </ScrollArea.Autosize>
          </>
        ) : (
          <Text pt={10} ta='left' color='dimmed'>
            No user available at this moment.
          </Text>
        )}
      </Card>
    </>
  );
}
