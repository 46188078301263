import { createStyles, rem } from '@mantine/core';

export const useConfirmBookingScrollAreaStylesIfPaymentModalIsOpened = createStyles((theme) => ({
  root: {
    padding: 4,
    margin: 0,
    maxHeight: `calc(100lvh - 160px)`,
    borderRadius: 8,
    boxShadow: `inset 0px 2px 2px 0px rgba(0,0,0,0.1),inset 0px -2px 2px 0px rgba(0,0,0,0.1)`,

    [theme.fn.smallerThan('md')]: {
      maxHeight: `100%`,
    },
  },
}));

export const useConfirmBookingScrollAreaStylesIfPaymentModalIsClosed = createStyles((theme) => ({
  root: {
    padding: 4,
    margin: 0,
    maxHeight: `100%`,

    [theme.fn.smallerThan('md')]: {
      maxHeight: `100%`,
    },
  },
}));

export const useBookServiceStyles = createStyles((theme) => ({
  serviceDetailsFlexContainer: {
    border: `1px solid ${theme.colorScheme === 'light' ? '#c1c1c1' : '#545454'}`,
    padding: `${rem(16)} ${rem(8)} ${rem(16)} ${rem(8)}`,
    borderRadius: 8,
    backgroundColor: theme.colorScheme === 'light' ? '#f2f2f2' : theme.colors.dark[5],

    '&:hover': {
      borderColor: theme.colorScheme === 'light' ? '#b1b1b1' : '#8d8d8d',
      backgroundColor: theme.colorScheme === 'light' ? '#eeeeee' : theme.colors.dark[6],
    },
  },
}));
