import React, { useEffect, useState } from 'react';
import UserOverview from './components/UserOverview';
import UserInfo from '../../Partner/Profile/components/UserInfo';

export default function CustomerProfile() {
  return (
    <>
      <UserInfo />
      {/* <ProfileInformation /> */}
    </>
  );
}
