import type { RefObject } from 'react';
import { toastMessageUpdate } from './tosterMessage';

type setFileWithValidationArg = {
  // This is a subset of the MIME types
  type: 'audio' | 'image' | 'video';
  file: File | null;
  maxAllowedFileSize: number;
  fileRef: RefObject<() => void>;
};

type setFileWithValidationReturn =
  | {
      status: 'error';
    }
  | {
      status: 'success';
      validatedFile: File;
    };

export default function setFileWithValidation({
  file,
  type,
  maxAllowedFileSize,
  fileRef,
}: setFileWithValidationArg): setFileWithValidationReturn {
  if (!file) return { status: 'error' };

  if (!file.type.startsWith(`${type}/`)) {
    toastMessageUpdate('error', `Only ${type} files are allowed`);
    fileRef.current?.();
    return { status: 'error' };
  } else {
    // Here, it is guranteed that this is a file of the valid type
    if (file.size > maxAllowedFileSize) {
      toastMessageUpdate(
        'error',
        `${type.charAt(0).toUpperCase() + type.slice(1)} size should not exceed ${
          maxAllowedFileSize / (1000 * 1024)
        } MB`,
      );
      fileRef.current?.();
      return { status: 'error' };
    } else {
      return { status: 'success', validatedFile: file };
    }
  }
}
