import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Grid, Radio, RangeSlider, Select, Text, TextInput, rem } from '@mantine/core';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { filterSearchSchema } from '../../../../../utils/schema/formSchema';
import { InferType } from 'yup';
import { useServiceTypeList } from '../../../../../services/service_types.api';
import { IconChevronDown } from '@tabler/icons-react';
import { useStateList } from '../../../../../services/location.api';
import removeNullishValuesFromObject from '../../../../../utils/helpers/removeNullishValuesFromObject';
import { useProviderFilterStyles } from '../style';

interface Props {
  sendDataToParent: (type: 'submit' | 'reset', data?: any) => void;
}

export default function ProviderFilter({ sendDataToParent }: Props) {
  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(filterSearchSchema),
    mode: 'all',
  });

  const onSubmit: SubmitHandler<InferType<typeof filterSearchSchema>> = (data) => {
    // console.log(data);
    const formattedData = {
      service_type_id: data.category,
      state: data.location,
      starting_price: data.priceRateRange ? data.priceRateRange[0] : 1,
      ending_price: data.priceRateRange ? data.priceRateRange[1] : 100,
      experience: data.experience,
      range: data.range,
    };

    const dataToSend = removeNullishValuesFromObject(formattedData);
    sendDataToParent('submit', dataToSend);
  };

  const { data: serviceList, isSuccess: isServiceListFetchSuccess } = useServiceTypeList(() =>
    console.log('service list fetched'),
  );
  const { data: stateList, isSuccess: isStateListFetchSuccess } = useStateList();

  const handleReset = () => {
    reset({
      category: '',
      experience: '',
      location: '',
      range: '',
      priceRateRange: [1, 100],
    });
    sendDataToParent('reset');
  };
  const { classes, cx } = useProviderFilterStyles();

  return (
    <>
      <Box className={cx(classes.container)}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <Flex direction='column' gap={16} className={cx(classes.formContainer)}>
            <TextInput
              {...register('range')}
              type='number'
              styles={(theme) => ({
                label: {
                  fontSize: rem(16),
                  fontWeight: 600,
                },
              })}
              onKeyDown={(e) => {
                ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
              }}
              label='Find service provider'
              placeholder='Search provider in miles'></TextInput>

            <Controller
              control={control}
              name='category'
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    label='Category'
                    styles={(theme) => ({
                      label: {
                        fontSize: rem(16),
                        fontWeight: 600,
                      },
                    })}
                    placeholder='Select category'
                    rightSection={<IconChevronDown size={rem(18)} />}
                    rightSectionWidth={30}
                    data={
                      isServiceListFetchSuccess
                        ? serviceList.data.map((s: any) => {
                            return { value: s.service_type_id, label: s.service };
                          })
                        : []
                    }
                    filter={(value, item) => item?.service?.toLowerCase().includes(value?.toLowerCase().trim())}
                  />
                </>
              )}
            />

            <Controller
              control={control}
              name='location'
              render={({ field }) => (
                <>
                  <Box>
                    <Select
                      {...field}
                      searchable
                      clearable
                      label='Location'
                      styles={(theme) => ({
                        label: {
                          fontSize: rem(16),
                          fontWeight: 600,
                        },
                      })}
                      placeholder='Select location'
                      rightSection={<IconChevronDown size={rem(18)} />}
                      rightSectionWidth={30}
                      transitionProps={{ transition: 'fade', duration: 150, timingFunction: 'ease' }}
                      data={isStateListFetchSuccess ? stateList.data.states.map((state: any) => state.name) : []}
                    />
                  </Box>
                </>
              )}
            />

            <Controller
              control={control}
              name='priceRateRange'
              render={({ field }) => (
                <>
                  <Box>
                    <Text className={cx(classes.label)}>Price Rate</Text>
                    <RangeSlider
                      {...field}
                      min={1}
                      max={100}
                      label={(value) => `$${value}/hr`}
                      labelTransitionTimingFunction='ease-out'
                      labelTransitionDuration={250}
                      labelTransition='slide-up'
                    />
                  </Box>
                </>
              )}
            />

            <Controller
              control={control}
              name='experience'
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  label='Experience'
                  styles={(theme) => ({
                    label: {
                      fontSize: rem(16),
                      fontWeight: 600,
                    },
                  })}>
                  <Flex direction='column' gap={5} mt={10}>
                    <Radio value='0_1' label='0 - 1 Years' />
                    <Radio value='2_3' label='2 - 3 Years' />
                    <Radio value='4_5' label='4 - 5 Years' />
                    <Radio value='6_7' label='6 - 7 Years' />
                  </Flex>
                </Radio.Group>
              )}
            />

            <Grid p={0} m={0} gutter={5} mt={10}>
              <Grid.Col span={6}>
                <Button fullWidth type='submit' c='primary' variant='outline' radius={4}>
                  Search
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button fullWidth type='button' c='primary' variant='outline' radius={4} onClick={handleReset}>
                  Reset
                </Button>
              </Grid.Col>
            </Grid>
          </Flex>
        </form>
      </Box>
    </>
  );
}
