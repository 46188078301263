import { Button, Text } from '@mantine/core';
import { useUrgentBookingButtonStyles } from '../style';

interface Props {
  renderIf: boolean;
  loading: boolean;
  onClick: () => void;
}

export default function UrgentBookingButton({ renderIf, loading, onClick }: Props) {
  const { classes, cx } = useUrgentBookingButtonStyles();

  return (
    <>
      {renderIf ? (
        <Button
          className={cx(classes.button)}
          variant='gradient'
          loaderPosition='center'
          gradient={{ from: '#fda223', to: '#fe7720' }}
          onClick={onClick}
          loading={loading}>
          <Text>{loading ? null : 'Urgent Booking'}</Text>
        </Button>
      ) : null}
    </>
  );
}
