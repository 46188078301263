import { Button, Flex, Modal, Text, Title, UnstyledButton, rem } from '@mantine/core';
import { useEffect, useState } from 'react';
import type { Area, Point } from 'react-easy-crop';
import Cropper from 'react-easy-crop';
import { useImageCropperModalStyles, useImageCropperStyles } from './style';
import { IconX } from '@tabler/icons-react';

export type OnSelectArgType =
  | {
      status: 'error';
    }
  | {
      status: 'success';
      croppedImageBlobUrl: string;
      croppedImageFile: File;
    };

type Props = {
  opened: boolean;
  onClose: () => void;
  onSelect: (data: OnSelectArgType) => void;
  onCancel: () => void;
  imageFileToBeCropped: File | null;
};

export default function ImageCropper({ opened, onClose, onSelect, onCancel, imageFileToBeCropped }: Props) {
  const { classes: imageCropperModalClasses } = useImageCropperModalStyles();
  const { classes } = useImageCropperStyles();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  useEffect(() => {
    (async function setImageToBeCroppedSourceUrlOnFileChange() {
      if (imageFileToBeCropped) {
        const toBeCroppedImageURL = await readFile(imageFileToBeCropped);
        setImageToBeCroppedSourceURL(toBeCroppedImageURL);
      }
    })();
  }, [imageFileToBeCropped]);

  const [imageToBeCroppedSourceURL, setImageToBeCroppedSourceURL] = useState('');

  const handleSelect = async () => {
    try {
      if (imageToBeCroppedSourceURL && croppedAreaPixels) {
        const croppedImage = (await getCroppedImg(imageToBeCroppedSourceURL, croppedAreaPixels)) as string;
        const blobToBeConvertedToFile = await (await fetch(croppedImage)).blob();
        const croppedImageFile = new File([blobToBeConvertedToFile], 'pfp', {
          type: blobToBeConvertedToFile.type,
        });

        onSelect({
          status: 'success',
          croppedImageBlobUrl: croppedImage,
          croppedImageFile: croppedImageFile,
        });
      }
      onSelect({
        status: 'error',
      });
    } catch (error) {
      console.log(error);
      onSelect({
        status: 'error',
      });
    }
  };

  return (
    <>
      <Modal
        p={0}
        m={0}
        centered
        opened={opened}
        onClose={onClose}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        transitionProps={{ transition: 'fade', duration: 350 }}
        classNames={imageCropperModalClasses}>
        <UnstyledButton onClick={onClose} className={classes.crossIconButtonContainer}>
          <IconX className={classes.crossIconButton} />
        </UnstyledButton>
        <Title ta='center'>Crop Image</Title>
        <Text ta='center' mb={8}>
          Calibrate the image to crop section of this image
        </Text>
        <div
          style={{
            position: 'relative',
            width: '100%',
            minWidth: '100%',
            height: 500,
            minHeight: 500,
            borderRadius: 4,
          }}>
          <Cropper
            image={imageToBeCroppedSourceURL}
            crop={crop}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            zoom={zoom}
            onZoomChange={setZoom}
            aspect={1 / 1}
            cropShape='round'
            // cropSize={{
            //   width: 160,
            //   height: 160,
            // }}
            zoomSpeed={0.1}
            showGrid
            style={{
              containerStyle: {
                backgroundColor: 'white',
                padding: '0px !important',
                margin: '0px !important',
                borderRadius: 8,
              },
              cropAreaStyle: {
                padding: '0px !important',
                margin: '0px !important',
              },
              mediaStyle: {
                backgroundColor: 'red',
                padding: '0px !important',
                margin: '0px !important',
              },
            }}
          />
        </div>

        <Flex justify='center' mt={16} mb={16} gap={16}>
          <Button
            onClick={handleSelect}
            variant='gradient'
            gradient={{ from: '#fda223', to: '#fe7720' }}
            style={{
              borderRadius: 4,
              width: `${rem(90)}`,
              padding: `${rem(4)} ${rem(16)}`,
            }}>
            <Text ta='center' fz={18}>
              Select
            </Text>
          </Button>

          <UnstyledButton
            onClick={onCancel}
            style={{
              border: '1px solid #c7c7c7',
              width: `${rem(90)}`,
              borderRadius: 4,
              padding: `${rem(4)} ${rem(16)}`,
            }}>
            <Text ta='center' fz={18}>
              Cancel
            </Text>
          </UnstyledButton>
        </Flex>
      </Modal>
    </>
  );
}

function readFile(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        if (reader.result !== null && typeof reader.result === 'string') {
          resolve(reader.result);
        }
      },
      false,
    );
    reader.readAsDataURL(file);
  });
}

async function getCroppedImg(imageSrc: string, pixelCrop: Area) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.translate(image.width / 2, image.height / 2);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement('canvas');

  const croppedCtx = croppedCanvas.getContext('2d');

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // As Base64 string
  // console.log(croppedCanvas.toDataURL('image/jpeg'));

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      if (file) resolve(URL.createObjectURL(file));
    }, 'image/png');
  });
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
