import {
  ActionIcon,
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  ScrollArea,
  Text,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconGenderBigender, IconHeart, IconHeartFilled, IconMapPin } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  addFavouriteProvider,
  deletegetFavouriteProvider,
  useFavouriteList,
} from '../../../../../services/favourite.api';
import { reviewList, useReviewList } from '../../../../../services/review';
import { useUserServiceById } from '../../../../../services/service';
import { useUserOverview } from '../../../../../services/userProfiles';
import { getVerifications } from '../../../../../services/verification';
import { ReviewType } from '../../../../../utils';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import ProfileCard from '../../../UserProfile/components/ProfileCard';
import { CommentCard } from '../../../UserProfile/components/CommentCard';
import decodeUserIdFromRandomizedString from '../../../../../utils/helpers/decodeUserIdFromRandomizedString';

const useCardStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'light' ? '#ECECEC' : theme.colors.dark[8],
    // height: '100%',
    height: 'calc(100svh - 150px)',
    [theme.fn.smallerThan(768)]: {
      borderRadius: 0,
    },
  },
}));

export default function CustomerViewProfile() {
  const { encodedIdWithRandomString } = useParams();

  const decodedId = decodeUserIdFromRandomizedString(encodedIdWithRandomString);

  const { data: profileData, isLoading: userOverviewIsLoading } = useUserOverview(decodedId || '');
  const fn = (data: any) => console.log(data);
  const { data: serviceData, isLoading: serviceIsLoading } = useUserServiceById(decodedId || '', fn);
  const [verificationStatus, setVerificationStatus] = useState<any>();
  const [userFeedback, setUserFeedback] = useState<any>([]);
  const theme = useMantineTheme();
  const { data: favList, isLoading: favListLoading, refetch: favRefetch } = useFavouriteList();

  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const [loading, setLoading] = useState(false);
  const [favourites, setFavourites] = useState(false);
  const [favUserDetails, setFavUserDetails] = useState<any>([]);
  const [favLoading, setFavLoading] = useState(false);
  const { classes } = useCardStyles();
  const [cookie, setCookie] = useCookies(['role']);

  // fetch user verification and review list.
  useEffect(() => {
    if (decodedId) {
      fetchVerification();
      fetchUserReviewList();
    }
  }, [decodedId]);

  // fetch user verification and its functionality.
  const fetchVerification = async () => {
    try {
      const verification = await getVerifications(decodedId);
      console.log(verification.data);
      setVerificationStatus(verification.data);
    } catch (error) {
      console.log(error);
    }
  };

  // fetch review list of specific user.
  const fetchUserReviewList = async () => {
    try {
      const review = await reviewList(ReviewType.user, decodedId);
      console.log(review);

      setUserFeedback(review.data);
    } catch (error) {
      console.log(error);
    }
  };

  const { data: ratingsData, isLoading: isReviewLoading, isSuccess } = useReviewList(ReviewType.user, decodedId);

  const handleFavourite = async (userId: undefined | string) => {
    if (+cookie.role === 2) return;
    else {
      setFavLoading(true);
      try {
        if (favourites) {
          const formData = {
            favourite_id: favUserDetails?.favourite_id,
          };
          const data: any = await deletegetFavouriteProvider(formData);
          toastMessageUpdate('success', data?.message);
          setFavLoading(false);
          setFavourites(false);
        } else {
          const formData = {
            user_id: userId,
          };
          const data: any = await addFavouriteProvider(formData);
          toastMessageUpdate('success', data?.message);
          setFavLoading(false);
          setFavourites(true);
        }
        favRefetch();
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        toastMessageUpdate('error', error?.data?.message);
        setLoading(false);
        setFavLoading(false);
      }
    }
  };

  useEffect(() => {
    getFavourites();
  }, [favList]);

  const getFavourites = async () => {
    if (favList?.data?.length) {
      const favUserIn = favList?.data?.findIndex((item: any) => item?.fav_user_id == decodedId);
      if (favUserIn > -1) {
        setFavUserDetails(favList?.data[favUserIn]);
        setFavourites(true);
      } else {
        setFavourites(false);
      }
    } else {
      setFavourites(false);
    }
  };

  return (
    <Grid gutter={5}>
      <LoadingOverlay visible={userOverviewIsLoading || serviceIsLoading || isReviewLoading} />
      <Grid.Col md={3}>
        <Card className={classes.card}>
          <ProfileCard {...profileData?.['data']} verification={verificationStatus} userId={decodedId} />
          <Divider size='xs' my={20} />
          <Flex direction='column' align={isMobile || isTablet ? 'center' : 'start'} gap={16}>
            <Text align='right' c='orange' fw={600} size='lg'>
              Basic info
            </Text>
            <Group spacing='sm'>
              <IconGenderBigender />
              <Text fw={600}>Gender:</Text>
              <Text color='dimmed'>{profileData?.['data']?.['user_profile']?.['gender'] || 'N/A'}</Text>
            </Group>

            <Group spacing='sm'>
              <IconMapPin />
              <Text fw={600}>Location:</Text>
              <Text color='dimmed'>{profileData?.['data']?.['has_address']?.['city'] || 'N/A'}</Text>
            </Group>
          </Flex>
        </Card>
      </Grid.Col>

      <Grid.Col lg={9}>
        <Box>
          <Title order={2} ml={5} mb={12}>
            Ratings
          </Title>
          <ScrollArea.Autosize mah={'calc(100svh - 180px)'} offsetScrollbars scrollbarSize={4}>
            <ul>
              {isSuccess &&
                (ratingsData.length ? (
                  ratingsData.map((item, index: number) => {
                    return (
                      <li key={index}>
                        <CommentCard
                          postedAt={item.created_at}
                          body={item.review}
                          rating={item.rating}
                          author={{
                            name: item.has_user.name,
                            image: item.has_user.user_profile.image || '',
                          }}
                          verifiedUser={item.has_user.has_verification}
                        />

                        {index !== ratingsData.length - 1 && <Divider my='sm' />}
                      </li>
                    );
                  })
                ) : (
                  <Divider
                    my='xs'
                    variant='dashed'
                    labelPosition='center'
                    label={
                      <>
                        <Text fz={18} color='dimmed'>
                          No reviews
                        </Text>
                      </>
                    }
                  />
                ))}
            </ul>
          </ScrollArea.Autosize>
        </Box>
      </Grid.Col>
    </Grid>
  );
}
