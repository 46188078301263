export const ValidationPattern = {
  password: /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
  numberOnly: /^[0-9]*$/,
  emailPattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+(?:\s*)$/,
  // emailPattern: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}(?:\s*)$/,
  characterOnly: /^[a-zA-Z ]+$/,
  pinCodePattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/, // US zip code pattern.
  Max_Profile_Image_Size: 3 * 1000 * 1024,
  Max_Banner_Image_Size: 5 * 1000 * 1024,
  // characterOnlyWithComma: /^[a-zA-Z,-]+$/,
  characterOnlyWithComma: /^[a-zA-Z-][a-zA-Z-](\s?[a-zA-Z-])*?(,[a-zA-Z-][a-zA-Z-](\s?[a-zA-Z-])*?)*$/,
};

export const ValidationErrorMessage = {
  Password_Required: '*Please enter your password',
  Password_Pattern: '*Please follow password policy',
  Password_Max: '*Password should be within 20 characters.',
  Password_Min: 'Password must contain with 6 characters.',
  Password_Not_Match: "Passwords don't match.",
  Profile_Name_Required: '*Please enter your name',
  OTP_required: '*Please enter the OTP (One-Time Password)',
  OTP_Min_Length: '*Please enter the complete OTP (One-Time Password)',
  Invalid_OTP: '*Please enter a valid OTP',
  Email_Pattern: '*Please enter a valid email',
  Email_Required: '*Please enter your email',
  Email_Max: 'Email should be within 50 characters.',
  Name_Required: '*Please enter your name.',
  Number_Only: 'Please type numbers only.',
  User_Name_Required: '*Please enter your user name.',
  User_Name_Min: 'User name should be a minimum of 3 characters.',
  User_Name_Max: 'User name should be within 50 characters.',
  User_Type_Required: '*Please select your user type',
  Phone_Required: '*Please enter your contact number',
  Phone_Max: 'Contact number should be within 15 digits.',
  Phone_Min: 'Contact number should be a minimum of 8 digits.',
  Valid_pincode: 'Please enter valid pin code.',
  PinCode_Required: '*Please enter your pin code.',
  City_Required: '*Please select your city.',
  Gender_Required: '*Please select your gender.',
  Address_Required: '*Please enter your address.',
  Valid_Name: 'Please ensure the input consists of only alphabetic characters.',
  ServiceCharges_Required: '*Please add your service charges.',
  ServiceDescription_Required: '*Please add your service description.',
  ServiceTitle_Required: '*Please add your service title.',
  Profile_Designation_Required: '*Please add your profile designation',
  Currency_Required: '*Please select your currency.',
  Address_Max: 'Addess should be with in 1000 characters',
  Terms_Condition_Required: '*Please accept terms and conditions.',
  Zip_Required: '*Please enter your zip code.',
  Valid_Zipcode: 'Please enter valid zip code.',
  First_Name_Required: '*Please enter your first name',
  Last_Name_Required: '*Please enter your last name',
  First_Name_2_Required: '*First name should be a minimum of 2 characters.',
  Last_Name__2_Required: '*Last name should be a minimum of 2 characters.',
  Card_Number_Required: 'Please enter your card number.',
  Card_Number_Length: 'Card number should be within 16 digits',
  Expiry_Month: '*Please add your card expiry month.',
  Expiry_Year: '*Please add your card expiry year.',
  Cvv_Required: '*Please add your card cvv number.',
  Valid_Card_Number: 'Please enter valid card number.',
  Valid_CVV_Number: 'Please enter valid cvv number.',
  Org_Name_Required: 'Please enter your organization name.',
  Max_Description: 'Description should be with in 1000 character.',
  Quantity_Required: '*Please enter your quantity.',
  confirm_password_required: '*Please enter above password',
  Allow_Message_Email_Call_Required: '*Please accept the consent checkbox.',
  Minimum_Service_Charges: `Services charge can't be less than $5.`,
  Maximum_Service_Charges: `Service charge is too high`,
  State_Required: '*Please select your state.',
  Country_Required: '*Please select your country',
  CharacterOnlyWithComma: `*Please enter characters only, for different skills separate with comma(,)`,
  ExperienceCannotExceedTwoDigits: '*Cannot exceed two digits',
  ServiceProvidersCannotExceedThreeDigits: '*Too much providers quantity',
  CannotStartWithZero: '*Cannot start with zero (0)',
};
