import {
  ActionIcon,
  Box,
  Card,
  Drawer,
  Flex,
  Indicator,
  Menu,
  Text,
  Tooltip,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconArrowLeft, IconBell, IconCircleCheck } from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import { readNotification, useNotificationtId } from '../../../services/websocket.api';
import { ThemeContext } from '../../../context';
import { IndicatorStyle, ScrollContianer } from './styles';
import { useCookies } from 'react-cookie';
import { useConversationList } from '../../../services/chat.api';

const useNotifcationStyles = createStyles((theme) => ({
  notificationCardUnread: {
    background: theme.colorScheme === 'light' ? '#fff4e1' : '#1f1f1f',
  },

  notificationCardRead: {
    background: theme.colorScheme === 'light' ? '#f7f7f7' : '#0b0b0b',
  },
}));

export default function Notification() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [opened, { open, close }] = useDisclosure(false);
  const [cookies, setCookie] = useCookies(['user_Info', 'user_Overview']);
  const [notifications, setNotifications] = useState(null);
  const { notificationRecieved } = useContext(ThemeContext);
  const { classes, cx } = useNotifcationStyles();

  const {
    data: notificationData,
    isLoading,
    refetch,
    isRefetchError,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useNotificationtId('service');
  const { classes: indicatorClass } = IndicatorStyle();
  const getAllNotification = async () => {
    notificationData?.pages.map((page) => {
      setNotifications(page?.notifications.unread_count);
    });
  };

  useEffect(() => {
    getAllNotification();
  }, [notificationData]);

  useEffect(() => {
    if (notificationRecieved) {
      refetch();
    }
  }, [notificationRecieved]);

  const readNotificationHandler = async (e: any) => {
    const formData = { notification_id: e.notification_Id };
    const data: any = await readNotification(formData);
    if (data.success) {
      refetch();
    }
  };

  const scrollHandler = async (e: any) => {
    const totalHeight = e.target.clientHeight + e.target.scrollTop;
    if (totalHeight >= e.target.scrollHeight - 50 && !isFetching && !isLoading && hasNextPage && !isRefetchError) {
      fetchNextPage();
    }
  };

  return (
    <>
      {mobile ? (
        <>
          <Indicator
            disabled={!notifications}
            label={notifications}
            classNames={indicatorClass}
            position={'top-start'}
            size={'md'}>
            <ActionIcon onClick={open}>
              <IconBell size='1.2rem'></IconBell>
            </ActionIcon>
          </Indicator>

          <Drawer opened={opened} onClose={close} position='bottom' withCloseButton={false} padding={0}>
            <Drawer.Header>
              <IconArrowLeft onClick={close} className='mobileBackButton' />
            </Drawer.Header>
            <ScrollContianer onScroll={scrollHandler}>
              <>
                {notificationData?.pages.map((page, index) => (
                  <React.Fragment key={index}>
                    {!page.notifications.notifications.data.length ? (
                      <>
                        <Text c='dimmed' ta='center' fz='md' fw='bold'>
                          You have no notifications.
                        </Text>
                      </>
                    ) : (
                      page.notifications.notifications.data.map((e: any, index: any) => {
                        return (
                          <Box
                            key={e.notification_Id}
                            sx={(theme) => ({
                              margin: 10,
                            })}>
                            <Card
                              withBorder
                              className={cx({
                                [classes.notificationCardRead]: e.read,
                                [classes.notificationCardUnread]: !e.read,
                              })}>
                              <Flex justify='space-between' direction='row' align='center'>
                                <Text c='dimmed'>{e.message}</Text>
                                <Flex direction='column'>
                                  <Tooltip label='Mark as read' position='left'>
                                    <ActionIcon size={22}>
                                      <IconCircleCheck color='#495057' onClick={() => readNotificationHandler(e)} />
                                    </ActionIcon>
                                  </Tooltip>
                                </Flex>
                              </Flex>
                            </Card>
                          </Box>
                        );
                      })
                    )}
                  </React.Fragment>
                ))}
              </>
            </ScrollContianer>
          </Drawer>
        </>
      ) : (
        <>
          <Flex direction='column' justify='center'>
            <Menu
              width={300}
              shadow='md'
              position='bottom-end'
              withArrow
              arrowOffset={150}
              arrowSize={15}
              transitionProps={{ transition: 'pop-top-right', duration: 200 }}>
              <Menu.Target>
                <Indicator
                  disabled={!notifications}
                  classNames={indicatorClass}
                  label={notifications}
                  position={'top-start'}
                  size={'md'}>
                  <ActionIcon>
                    <IconBell size='1.2rem'></IconBell>
                  </ActionIcon>
                </Indicator>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label fz='sm' fw='bold'>
                  Your notifications
                </Menu.Label>
                <ScrollContianer onScroll={scrollHandler}>
                  {notificationData?.pages.map((page, index) => (
                    <React.Fragment key={index}>
                      {!page.notifications.notifications.data.length ? (
                        <>
                          <Text c='dimmed' ta='center' fz='md' fw='bold'>
                            You have no notifications.
                          </Text>
                        </>
                      ) : (
                        page?.notifications?.notifications.data.map((e: any, index: any) => {
                          return (
                            <React.Fragment key={e.notification_Id}>
                              <Menu.Item
                                closeMenuOnClick={false}
                                sx={(theme) => ({
                                  transition: '500ms',
                                })}>
                                <Card
                                  withBorder
                                  // bg={e.read ? '#dee2e6' : '#e7f3ff'}
                                  className={cx({
                                    [classes.notificationCardRead]: e.read,
                                    [classes.notificationCardUnread]: !e.read,
                                  })}>
                                  <Flex justify='space-between' direction='row' align='center'>
                                    <Text c='dimmed'>{e.message}</Text>
                                    <Flex direction='column'>
                                      <Tooltip label='Mark as read' position='left'>
                                        <ActionIcon size={22}>
                                          <IconCircleCheck color='#495057' onClick={() => readNotificationHandler(e)} />
                                        </ActionIcon>
                                      </Tooltip>
                                    </Flex>
                                  </Flex>
                                </Card>
                              </Menu.Item>
                            </React.Fragment>
                          );
                        })
                      )}
                    </React.Fragment>
                  ))}
                </ScrollContianer>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </>
      )}
    </>
  );
}
