import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Group,
  PasswordInput,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { MonthPickerInput, YearPickerInput } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowNarrowLeft, IconChevronDown, IconEye, IconEyeOff } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { chargebeeSubscriptionUpdate, chargebeeUserCreate, updateChargebeeUserCard } from '../services/payment.api';
import { encryptionHandler } from '../utils/helpers/paymentEncryption';
import { basicPaymentSchema, paymentSchema } from '../utils/schema/formSchema';
import { useMonthHeaderStyles, usePaymentModalStyles } from './style';
import { ThemeContext } from '../context/themeContext';
import { useCookies } from 'react-cookie';
import { updateUserProfile, userProfile } from '../services/user.api';
import { AnyNsRecord } from 'dns';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';
import { useNavigate } from 'react-router-dom';
import { subscriptionLimit } from '../services/subscription.api';
import onlyAllowNumbersInput from '../utils/helpers/onlyAllowNumbersInput';

interface IPaymentModal {
  state: boolean;
  close: () => void;
  onPayment: () => void;
  forbookingServices: boolean;
}

const PaymentModalRefactored = ({ state, close, onPayment, forbookingServices }: IPaymentModal) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(paymentSchema),
    mode: 'onChange',
  });

  const {
    register: basicPlanRegister,
    handleSubmit: basicPlanSubmit,
    reset: basicPlanReset,
    formState: { errors: basicPlanError },
  } = useForm({
    resolver: yupResolver(basicPaymentSchema),
    mode: 'onChange',
  });
  const today = new Date();
  const { classes: monthHeaderClass } = useMonthHeaderStyles();
  const [cookie, setCookie] = useCookies(['user_Info', 'subscription_status']);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 576px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isL = useMediaQuery('(max-width: 1300px)');

  const theme = useMantineTheme();
  const { setSelectedSubscriptionPlan, selectedSubscriptionPlan, setSubscriptionExhausted } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      reset();
    }
    console.log('plan', selectedSubscriptionPlan);
  }, [state]);

  // Submit payment with payment details.
  const submitPayment = async (data: any) => {
    try {
      const formData = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        card_number: encryptionHandler(data?.card_number),
        expiry_month: encryptionHandler(dayjs(data.expiry_month).format(`MM`)),
        expiry_year: encryptionHandler(dayjs(data.expiry_year).format(`YYYY`)),
        cvv: encryptionHandler(data?.cvv),
      };
      // If upgrade current plan.
      if (forbookingServices) {
        setIsLoading(true);
        const paymentCardUpdate: any = await updateChargebeeUserCard(formData);
        console.log('paymentCardUpdate', paymentCardUpdate);
        if (paymentCardUpdate?.success) {
          toastMessageUpdate('success', paymentCardUpdate?.message);
          onPayment();
          setIsLoading(false);
          if (!cookie.user_Info?.chargebee_card) {
            const data: any = await userProfile();
            setCookie('user_Info', data?.data?.user, { path: '/' });
          }
        }
      } else {
        if (cookie.user_Info?.chargebee_id) {
          setIsLoading(true);
          const params = {
            subcription_id: selectedSubscriptionPlan?.subcription_id,
          };
          await updateUserProfile(params);
          const paymentCardUpdate = await updateChargebeeUserCard(formData);
          const subFormData = {
            user_id: cookie.user_Info?.id,
          };
          const subscriptionUpdate: any = await chargebeeSubscriptionUpdate(subFormData);
          setCookie('subscription_status', false, {
            path: '/',
          });
          toastMessageUpdate('success', subscriptionUpdate?.message);
          const response = await subscriptionLimit();
          setSubscriptionExhausted(response);
          setIsLoading(false);
          close();
          navigate(-1);
        } else {
          // First time subscription any plan.
          setIsLoading(true);
          const res = await chargebeeUserCreate(formData);
          console.log(res);
          // If selected any premium plan
          if (+selectedSubscriptionPlan?.amount > 0) {
            console.log('update card details for premium plan');
            await updateChargebeeUserCard(formData);
          }
          close();
          onPayment();
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      toastMessageUpdate('error', error?.data?.message || error?.message || '');
    }
  };

  // Function of basic payment.
  // If user register basic plan first time, then this function handle it.
  const submitBasicPayment = async (data: any) => {
    try {
      setIsLoading(true);
      const res = await chargebeeUserCreate(data);
      close();
      onPayment();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toastMessageUpdate('error', error?.data?.message || error?.message || '');
    }
  };

  console.log(typeof watch('expiry_year')?.getFullYear());

  const { classes, cx } = usePaymentModalStyles();

  return (
    <>
      <Box className={cx(classes.container)}>
        {/* <Flex justify='space-between' mb={25} mt={0}>
          <Title order={3} fw={600}>
            Payment
          </Title>
        </Flex> */}
        {!cookie.user_Info?.chargebee_id && +selectedSubscriptionPlan?.amount === 0 ? (
          <form onSubmit={basicPlanSubmit(submitBasicPayment)} autoComplete='off'>
            <Title order={3} fw={600} mb={48}>
              Payment
            </Title>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  label='First Name'
                  {...basicPlanRegister('first_name')}
                  size='md'
                  placeholder='Enter your first name'
                />
                {basicPlanError?.first_name && (
                  <Text fz={14} pl={5} fw={500} color='red'>
                    {basicPlanError?.first_name?.message}
                  </Text>
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  withAsterisk
                  {...basicPlanRegister('last_name')}
                  label='Last Name'
                  size='md'
                  placeholder='Enter your last name'
                />
                {basicPlanError?.last_name && (
                  <Text fz={14} pl={5} fw={500} color='red'>
                    {basicPlanError?.last_name?.message}
                  </Text>
                )}
              </Grid.Col>

              <Grid.Col sm={12} ta='left'>
                <Button
                  loading={isLoading}
                  w={isMobile || isTablet ? '100%' : '30%'}
                  h={50}
                  radius={4}
                  type='submit'
                  variant='gradient'
                  gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
                  {!isLoading && 'Submit'}
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        ) : (
          <>
            <form onSubmit={handleSubmit(submitPayment)} autoComplete='off'>
              <Title order={3} fw={600} mb={48}>
                Payment
              </Title>
              <Grid>
                <Grid.Col lg={6} md={6} sm={6}>
                  <TextInput
                    withAsterisk
                    label='First Name'
                    {...register('first_name')}
                    size='md'
                    placeholder='Enter your first name'
                  />
                  {errors?.first_name && (
                    <Text fz={14} pl={5} fw={500} color='red'>
                      {errors?.first_name?.message}
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col lg={6} md={6} sm={6}>
                  <TextInput
                    withAsterisk
                    {...register('last_name')}
                    label='Last Name'
                    size='md'
                    placeholder='Enter your last name'
                  />
                  {errors?.last_name && (
                    <Text fz={14} pl={5} fw={500} color='red'>
                      {errors?.last_name?.message}
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col sm={12}>
                  <TextInput
                    withAsterisk
                    {...register('card_number')}
                    label='Card Number'
                    size='md'
                    placeholder='Enter your card number'
                    maxLength={16}
                    type='number'
                    onKeyDown={onlyAllowNumbersInput}
                  />
                  {errors?.card_number && (
                    <Text fz={14} pl={5} fw={500} color='red'>
                      {errors?.card_number?.message}
                    </Text>
                  )}
                </Grid.Col>

                <Grid.Col lg={6}>
                  <Grid>
                    <Grid.Col sm={12} pb={0}>
                      <Text fw={600}>Expiry month/year</Text>
                    </Grid.Col>
                    <Grid.Col>
                      <Flex direction='row' w='100%'>
                        <Controller
                          name='expiry_month'
                          control={control}
                          render={({ field }) => (
                            <MonthPickerInput
                              valueFormat='MMM'
                              monthsListFormat='MMM'
                              minDate={
                                watch('expiry_year') && watch('expiry_year').getFullYear() === today.getFullYear()
                                  ? today
                                  : undefined
                              }
                              icon={<IconChevronDown color='gray' />}
                              withAsterisk
                              classNames={monthHeaderClass}
                              {...field}
                              size='md'
                              placeholder='Select MM'
                              w='100%'
                            />
                          )}
                        />

                        <Center w='10%'>
                          <Text fw='bold'>/</Text>
                        </Center>

                        <Controller
                          name='expiry_year'
                          control={control}
                          render={({ field }) => (
                            <YearPickerInput
                              minDate={
                                watch('expiry_month') && watch('expiry_month').getMonth() < today.getMonth()
                                  ? new Date(today.getFullYear() + 1, 0, 1)
                                  : today
                              }
                              withAsterisk
                              classNames={monthHeaderClass}
                              icon={<IconChevronDown color='gray' />}
                              {...field}
                              size='md'
                              w='100%'
                              placeholder='Select YY'
                            />
                          )}
                        />
                      </Flex>
                      {(errors?.expiry_month || errors?.expiry_year) && (
                        <Text fz={14} pl={5} fw={500} color='red'>
                          Please add your card expiry month/year
                        </Text>
                      )}
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <Grid>
                    <Grid.Col sm={12} pb={0}>
                      <Text fw={600}>CVV</Text>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                      <PasswordInput
                        {...register('cvv', { required: true })}
                        size='md'
                        placeholder='Enter CVV'
                        withAsterisk
                        maxLength={3}
                        onKeyDown={onlyAllowNumbersInput}
                        visibilityToggleIcon={({ reveal }) =>
                          reveal ? <IconEye size={20} stroke={1} /> : <IconEyeOff size={20} stroke={1} />
                        }
                      />
                      {errors?.cvv && (
                        <Text fz={14} pl={5} fw={500} color='red'>
                          {errors?.cvv?.message}
                        </Text>
                      )}
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col sm={12} ta='left'>
                  <Button
                    loading={isLoading}
                    w={isMobile || isTablet ? '100%' : '30%'}
                    h={50}
                    radius={4}
                    type='submit'
                    variant='gradient'
                    gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
                    {!isLoading && 'Submit'}
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </>
        )}
      </Box>
    </>
  );
};

export default PaymentModalRefactored;
