import { Box, Text } from '@mantine/core';
import { useNotificationWrapperStyles } from './styles';

interface Props {
  message: string;
  children: React.ReactNode;
}

export default function NotificationWrapper({ children, message }: Props) {
  const { classes: notificationWrapperClasses } = useNotificationWrapperStyles();
  return (
    <Box className={notificationWrapperClasses.container}>
      <Text ta='center' c='white'>
        {message} {children}
      </Text>
    </Box>
  );
}
