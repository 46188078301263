import { toastMessageUpdate } from '../utils/helpers/tosterMessage';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Chat_App_Temporary_Token } from '../utils/setting';
import { axiosTokenInst } from './axios.config';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { catchHandler } from '../utils/helpers';

// Axios token instance setup for token based API
const axiosAgoraTokenInst = axios.create({
  baseURL: 'https://a61.chat.agora.io/611023380/1195332', // POST https://{host}/{org_name}/{app_name}/users
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosAgoraTokenInst.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.headers.Authorization = `Bearer ${Chat_App_Temporary_Token}`;
  return config;
});

// Axios token instance setup for token based API
const axiosAgoraUserTokenInst = axios.create({
  baseURL: 'https://a61.chat.agora.io',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://localhost:3000',
  },
});

axiosAgoraUserTokenInst.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.headers.Authorization = `Bearer ${Chat_App_Temporary_Token}`;
  return config;
});

export const registerUserAgora = async (data: any) => {
  return await axiosAgoraTokenInst.post('/users', data);
};

export const loginUserAgora = async (username: any) => {
  return await axiosAgoraTokenInst.post(`/users/${username}`);
};

// export const loginUserAgora = async (username: any) => {
//   return await axiosAgoraUserTokenInst.post(`/chat/user/${username}/token`);
// };

export const generateToken = async (data: Record<string, any>) => {
  return await axiosTokenInst.post('/chat/token', data);
};

export const chatStore = async (params: any) => {
  return await axiosTokenInst.post('/chat/store', params);
};

export const getChatHistory = async (bookingId: Record<string, any>) => {
  return await axiosTokenInst.get(`/chat/show/${bookingId}`, { params: { page: 1 } });
};

export const getConversationsList = catchHandler(async ({ pageParam, queryKey }: any) => {
  let pageNumber = 1;
  if (pageParam) {
    pageNumber = pageParam.match(/page=(.*)/)[1];
  }
  return await axiosTokenInst.get(`/chat/show/conversations/${queryKey[1]}`, { params: { page: pageNumber } });
});

export const getConversationsMessage = catchHandler(async ({ pageParam, queryKey }: any) => {
  let pageNumber = 1;
  if (pageParam) {
    pageNumber = pageParam.match(/page=(.*)/)[1];
  }
  return await axiosTokenInst.get(`/chat/show/chats/${queryKey[1]}`, {
    params: { page: pageNumber },
  });
});

export const useConversationList = (userId: any) => {
  return useInfiniteQuery({
    queryKey: ['conversationList', userId],
    queryFn: getConversationsList,
    getNextPageParam: (lastPage: Record<any, any>, pages) => lastPage.conversation.next_page_url,
  });
};

export const useConversationsMessage = (conversationId: any, enable: boolean) => {
  return useInfiniteQuery({
    queryKey: ['conversationMessageList', conversationId],
    enabled: enable,
    queryFn: getConversationsMessage,
    getNextPageParam: (lastPage: Record<any, any>, pages) => lastPage?.chats?.next_page_url,
  });
};
