/**
 * Role types reference ---
 * 1: 'User'
 * 2: 'Service Provider'
 * 3: 'Organizational Service Provider'
 * 4: 'Organizational Service User'
 */
type Parameter = {
  data: {
    user_verification: boolean;
    background_verification: boolean;
    drug_test_verification: boolean;
    business_license_verification: boolean | null;
    onfido_result?: string;
    type: '1' | '2' | null;
  };
  role: 1 | 2 | 3 | 4;
  userIsViewingOwnProfile?: boolean;
};

type VerificationStatus = {
  backgroundVerification: string | null;
  identityVerification: string | null;
  licenseVerification: string | null;
};

export default function renderVerificationStatusText({ data, role, userIsViewingOwnProfile }: Parameter) {
  // console.log(data, role);

  const verification = {} as Partial<VerificationStatus>;
  if (data === undefined) {
    verification.backgroundVerification = 'Not Verified';
    verification.identityVerification = 'Not Verified';
    verification.licenseVerification = 'Not Verified';
    return verification;
  }

  if (typeof role === 'string' && ['1', '2', '3', '4'].includes(role)) {
    role = +role as 1 | 2 | 3 | 4;
  }

  switch (role) {
    case 1:
      if (Object.hasOwn(data, 'onfido_result')) {
        if (data.onfido_result === 'clear') verification.identityVerification = 'Verified';
        else verification.identityVerification = 'Not Verified';
      }
      break;
    /**
     * If the type is 2 then we need to check for
     * 1. Background verification
     * 2. Identity verification (checked by Onfido)
     */
    case 2:
      // checking identity verification
      if (Object.hasOwn(data, 'onfido_result')) {
        if (data.onfido_result === 'clear') verification.identityVerification = 'Verified';
        else verification.identityVerification = 'Not Verified';
      }

      // checking background verification
      if (data.type === '2') verification.backgroundVerification = 'Not Submitted';
      // else if (data.type === '1') {
      //   if (data?.background_verification) verification.backgroundVerification = 'Verified';
      //   else verification.backgroundVerification = userIsViewingOwnProfile ? 'Verification Pending' : 'Not Verified';
      // }
      else {
        if (data?.background_verification) verification.backgroundVerification = 'Verified';
        else verification.backgroundVerification = userIsViewingOwnProfile ? 'Verification Pending' : 'Not Verified';
      }

      console.log(verification);
      break;

    /**
     * If the type is 3 then we need to check for
     * 1. Business license verification
     */
    case 3:
      if (!data.type && !data.business_license_verification) {
        verification.licenseVerification = 'Not Submitted';
      } else if (data.type === '2') {
        if (data.business_license_verification) {
          verification.licenseVerification = 'Verified';
        } else {
          verification.licenseVerification = 'Not Verified';
        }
      }

      console.log(verification);
      break;
  }

  return verification;
}
