import { Box, Button, Flex, Text } from '@mantine/core';
import { useNoProfileFoundStyles } from '../style';
import { useContext } from 'react';
import { HomeContextRefactored } from '../home.context';

interface Props {
  renderButtonToDisplayAllProfiles?: boolean;
}

export default function NoProfileFound({ renderButtonToDisplayAllProfiles }: Props) {
  const { classes: noProfileFoundClasses } = useNoProfileFoundStyles();

  const { setWithoutRangeSearch } = useContext(HomeContextRefactored);
  return (
    <>
      <Box className={noProfileFoundClasses.container}>
        <Text className={noProfileFoundClasses.headerText}>No profile found</Text>

        <Text className={noProfileFoundClasses.contentText}>
          {`Oops!! No profiles ${
            renderButtonToDisplayAllProfiles ? 'in 20 miles' : ''
          } currently. We are working to offer diverse and engaging options. Need service professionals
          rightaway?`}
        </Text>

        <Flex justify='space-between' align='center'>
          <Text>
            Contact{' '}
            <Text component='a' href='mailto:customerservice@aidby.com' className={noProfileFoundClasses.emailText}>
              customerservice@aidby.com
            </Text>
          </Text>

          {renderButtonToDisplayAllProfiles ? (
            <Button compact variant='outline' radius={4} onClick={() => setWithoutRangeSearch(true)}>
              Show all profiles
            </Button>
          ) : null}
        </Flex>
      </Box>
    </>
  );
}
