import { Box, Divider, Flex, Grid, Image, Table, Text, Title } from '@mantine/core';
import { LogoLight } from '../../../../../assets';

export default function Invoice() {
  const items: { sl: number; desc: string; price: number; quantity: number; total: number }[] = [
    { sl: 1, desc: 'Product 1', price: 10, quantity: 2, total: 20 },
    { sl: 2, desc: 'Product 2', price: 15, quantity: 1, total: 15 },
    { sl: 3, desc: 'Product 3', price: 8, quantity: 3, total: 24 },
    { sl: 4, desc: 'Product 4', price: 5, quantity: 4, total: 20 },
    { sl: 5, desc: 'Product 5', price: 12, quantity: 2, total: 24 },
  ];

  return (
    <>
      <Box>
        <Grid p={0} m={0}>
          <Grid.Col>
            <Flex gap={4} align='center'>
              <Image src={LogoLight} maw={60} />
              <Title order={1}>AidBy</Title>
            </Flex>
          </Grid.Col>
          <Grid.Col>
            <Divider
              label={
                <Text fw={700} fz={24}>
                  Invoice
                </Text>
              }
              labelPosition='right'
              my={12}
            />
          </Grid.Col>
          <Grid.Col lg={9}>
            <Flex direction='column'>
              <Title order={2}>Invoice to:</Title>
              <Text fz={18} fw={600}>
                Dwayne Clark
              </Text>
              <Text>24, Dummy Street Area</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col lg={3}>
            <Flex justify='space-between'>
              <Text fw={600}>Invoice #</Text>
              <Text>52148</Text>
            </Flex>
            <Flex justify='space-between'>
              <Text fw={600}>Date</Text>
              <Text>01 / 01 / 2020</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col>
            <Table verticalSpacing='md' striped>
              <thead>
                <tr>
                  <th>SL.</th>
                  <th>Item description</th>
                  <th>Price</th>
                  <th>Qty.</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {items.map((e) => (
                  <tr key={e.sl}>
                    <td>{e.sl}</td>
                    <td>{e.desc}</td>
                    <td>{e.price}</td>
                    <td>{e.quantity}</td>
                    <td>{e.total}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Grid.Col>

          <Grid.Col lg={6}>
            <Flex>
              <Text>Thank you for your business</Text>
            </Flex>
          </Grid.Col>

          <Grid.Col lg={6}>
            <Flex justify='flex-end' gap={16}>
              <Text fw={600}>Sub total:</Text>
              <Text>{items.reduce((acc, item) => acc + item.total, 0)}</Text>
            </Flex>
            <Flex justify='flex-end' gap={16}>
              <Text fw={600}>Tax:</Text>
              <Text>14.20%</Text>
            </Flex>
          </Grid.Col>

          <Grid.Col>
            <Flex justify='flex-end'>
              <Box bg='#ffc471' p={12} w='30%'>
                <Text fw={600} fz={22} ta='right'>
                  Total: {Number(items.reduce((acc, item) => acc + item.total, 0) * 1.142).toFixed(2)}
                </Text>
              </Box>
            </Flex>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
