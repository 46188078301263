// import { RtmClient, RtmMessage } from 'agora-rtm-sdk';
import { useContext, useEffect, useRef, useState } from 'react';
import { IMessage } from '../interface/IMessage';
import dayjs from 'dayjs';
import { channelName, useRTMClient } from '../setting';
import { useCookies } from 'react-cookie';
import {
  chatStore,
  generateToken,
  getChatHistory,
  getConversationsMessage,
  useConversationsMessage,
} from '../../services/chat.api';
import { RtmMessage, createChannel } from 'agora-rtm-react';
import { useUserOverview } from '../../services/userProfiles';
import { ThemeContext } from '../../context/themeContext';
import { sendNotification } from '../../services/websocket.api';
import { customerRole } from '../interface/RoleEnum';

const useAgoraRtm = (userName: string) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'user_Info',
    'user_Overview',
    'agora_token',
    'peerId',
    'bookingDetails',
    'role',
  ]);
  const { chatType, channelId, chatRecieverId, setChatRecieverId, selectedConversationId, setSelectedConversationId } =
    useContext(ThemeContext);
  const [messages, setMessages] = useState<any>([]);
  const [agoraToken, setAgoraToken] = useState<any>();

  const [currentMessage, setCurrentMessage] = useState<IMessage>();
  const [historyMessage, setHistoryMessage] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    data: chatUserData,
    refetch: refetchChatUserData,
    isLoading: userFetchLoading,
  } = useUserOverview(
    cookies?.user_Info?.id == +cookies?.bookingDetails?.service_from
      ? cookies?.bookingDetails?.service_to
      : cookies?.bookingDetails?.service_from || chatRecieverId,
  );
  const [loggedIn, setLoggedIn] = useState(false);
  const [chatSave, setChatSave] = useState(false);
  const client = useRTMClient();
  const channel: any = useRef(
    client.createChannel(channelName + `${chatType === 'userChat' ? channelId : cookies?.peerId}`),
  ).current;

  const [conversationId, setConversationId] = useState<any>();

  const {
    data: messageConversationData,
    refetch: messageRefetch,
    isLoading: isMessageLoading,
    isRefetchError: isMessageRefetchError,
    hasNextPage: hasMessageNextPage,
    isFetching: isMessageFetching,
    fetchNextPage: fetchNextMessage,
  } = useConversationsMessage(conversationId, conversationId ? true : false);

  //selectedConversationId;
  useEffect(() => {
    if (loggedIn) {
      refetchChatUserData();
      rejoin();
    }
  }, [chatType, channelId]);

  // After change user from user list, change channel name and rejoin channel.
  const rejoin = async () => {
    await channel
      .leave()
      .then(() => {
        console.log('Leave channel and join');
      })
      .catch((err: any) => {
        console.log('Leave err', err);
      });
    const updatedChannelName = channelName + `${chatType === 'userChat' ? channelId : cookies?.peerId}`;
    channel.channelId = updatedChannelName;
    await channel
      .join()
      .then(() => {
        console.log('channel join again');
      })
      .catch((err: any) => {
        console.log('err ', err);
      });
  };

  useEffect(() => {
    // selectedConversationId;
    console.log('chat user data', chatUserData, conversationId);
    if (chatUserData?.data && selectedConversationId) {
      setLoading(true);
      messageRefetch();
    }
    // selectedConversationId;
  }, [chatUserData]);

  // After refetch message conversation data, it update the message
  useEffect(() => {
    getPreviousChat();
  }, [messageConversationData, conversationId]);

  useEffect(() => {
    setLoading(true);
    const delayedFunction = () => {
      initRtm();
      setLoading(false); // for temporary
    };
    const timeoutId = setTimeout(delayedFunction, 2000);
    refetchChatUserData();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const channelMessageListener = (data: any, uid: any) => {
      handleMessageReceived(data, uid);
    };
    channel.on('ChannelMessage', channelMessageListener);

    return () => {
      console.log('channel leave call');
      setSelectedConversationId(null);
      removeCookie('peerId', { path: '/' });
      removeCookie('bookingDetails', { path: '/' });
      channel.off('ChannelMessage', channelMessageListener);
      setChatRecieverId(-1);
      client
        .logout()
        .then(() => {
          console.log('User logged out successfully.');
        })
        .catch((err: any) => {
          console.error('Error logging out:', err);
        });
      channel.leave();
    };
  }, []);

  const initRtm = async () => {
    try {
      const tokenData = await generateToken({
        channel_name: channelName + `${chatType === 'userChat' ? channelId : cookies?.peerId}`,
      });
      setAgoraToken(tokenData.data);
      setCookie('agora_token', tokenData.data, { path: '/' });
      if (tokenData.data) {
        loginWithRetry(tokenData.data);
      }
    } catch (error: any) {
      console.log('token err', error);
    }
  };

  const loginWithRetry = async (token: any) => {
    await client
      .login({ uid: cookies?.user_Info?.email, token: token?.rtm_token })
      .then(async () => {
        console.log('Logged in successfully', channel);
        setLoggedIn(true);
        await channel
          .join()
          .then(() => {
            console.log('channel join');
          })
          .catch((err: any) => {
            console.log('err ', err);
          });

        client.setLocalUserAttributes({
          uid: cookies?.user_Info?.email,
          name: userName,
          color: 'orange',
          time: dayjs(new Date()).format('HH:mm'),
          userId: '' + cookies?.user_Info?.id,
        });
      })
      .catch((error: any) => {
        console.error('Login error:', error);
      });
  };

  // ** This is old function to get previous chat using direct api call.
  // const getPreviousChat = async (conversationInfo?: any, pageNo?: any) => {
  //   setLoading(true);
  //   try {
  //     let previousChat: any;
  //     const chatHistory: any = [];
  //     console.log('prev chat', conversationInfo, chatType);
  //     if (conversationInfo?.conversation_id || chatType === 'userChat') {
  //       const messageData: any = await getConversationsMessage(conversationInfo?.conversation_id, pageNo);
  //       previousChat = messageData?.chats;
  //     } else {
  //       const chatData: any = await getChatHistory(cookies?.bookingDetails?.booking_id);
  //       previousChat = chatData?.messages;
  //     }
  //     if (previousChat?.data.length) {
  //       previousChat?.data?.map((msg: any, id: number) => {
  //         if (msg?.message_from == cookies?.user_Info?.id) {
  //           msg.name = 'You';
  //         } else {
  //           setCustomerId(msg?.message_from);
  //           msg.name = conversationInfo?.name || chatUserData?.data?.name;
  //         }
  //         // messages.push(msg);
  //         chatHistory.push(msg);
  //         return msg;
  //       });
  //       setMessages(chatHistory);
  //       // setMessages(messages.concat(...chatHistory));
  //       // setHistoryMessage(true);
  //     }
  //     setLoading(false);
  //   } catch (error: any) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // ** This is new function to get previous chat using react query.
  const getPreviousChat = async (conversationInfo?: any, pageNo?: any) => {
    const chatHistory: any = [];
    setLoading(true);
    console.log('conversationInfo', conversationInfo, conversationId, chatUserData?.data?.name);
    // selectedConversationId;
    if (conversationId) {
      messageConversationData?.pages?.map((page: any) => {
        page?.chats?.data.map((item: any, id: number) => {
          if (item?.message_from == cookies?.user_Info?.id) {
            item.name = 'You';
          } else {
            setCustomerId(item?.message_from);
            item.name = conversationInfo?.name ? conversationInfo?.name : chatUserData?.data?.name;
          }

          chatHistory.push(item);
          return item;
        });
      });
      // remove duplicate chat as per message id.
      setMessages(
        chatHistory?.filter((obj: Record<string, any>, index: number) => {
          return index === chatHistory.findIndex((current: any) => obj.id === current.id);
        }),
      );
      setLoading(false);
      console.log('prev chat', messageConversationData, chatHistory);
    } else {
      setLoading(false);
      console.log('No conversation');
    }
  };

  const handleMessageReceived = async (data: RtmMessage, uid: string) => {
    const user = await client.getUserAttributes(uid);
    if (data.messageType === 'TEXT') {
      const newMessageData = { user, message: data.text };
      setCurrentMessage(newMessageData);
    }
  };

  const sendChannelMessage = async (text: string) => {
    await channel
      .sendMessage({ text })
      .then(async () => {
        setCurrentMessage({
          user: {
            uid: cookies?.user_Info?.email,
            message_from: cookies?.user_Info?.id,
            message: text,
            name: 'You',
            color: 'orange',
            time: dayjs(new Date()).format('HH:mm'),
          },
          message: text,
        });

        let storeParams = {};
        if (chatType === 'bookingChat') {
          if (customerRole.includes(+cookies.role)) {
            storeParams = {
              // booking_id: cookies?.bookingDetails?.booking_id,
              receiver: cookies?.bookingDetails?.service_from,
              message: text,
            };
          } else {
            storeParams = {
              // booking_id: cookies?.bookingDetails?.booking_id,
              receiver: cookies?.bookingDetails?.service_to,
              message: text,
            };
          }
        } else {
          storeParams = {
            receiver: chatRecieverId,
            message: text,
          };
        }

        const store: any = await chatStore(storeParams);
        if (store?.success) {
          setChatSave(store?.success);
          getChannelMembers();
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  // Notification send, if member user not join in the same channel.
  const getChannelMembers = () => {
    channel.getMembers().then(async (memberNames: any) => {
      const loggedInUser = [cookies?.user_Info?.email];
      const memberUser = memberNames?.filter((val: any) => !loggedInUser.includes(val));
      console.log(memberNames, 'memberNames', memberUser, chatRecieverId);
      if (memberUser.length === 0) {
        const formData = {
          to_user: chatRecieverId,
          message: `Hello, you have a new text message from ${cookies?.user_Info?.name}.`,
          type: 'chat',
        };
        await sendNotification(formData);
      } else {
        const user = await client.getUserAttributes(memberUser[0].toString());
        console.log(user, 'user'); // To find more details of joined user
      }
    });
  };

  useEffect(() => {
    if (currentMessage) {
      setMessages([...messages, currentMessage]);
    }
  }, [currentMessage, historyMessage]);

  return {
    sendChannelMessage,
    messages,
    loading,
    chatUserData,
    getPreviousChat,
    chatSave,
    fetchNextMessage,
    isMessageFetching,
    isMessageLoading,
    hasMessageNextPage,
    isMessageRefetchError,
    userFetchLoading,
    messageRefetch,
    setConversationId,
    currentMessage,
  };
};

export default useAgoraRtm;
