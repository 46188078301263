import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Flex,
  Grid,
  Indicator,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  UnstyledButton,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconArrowLeft, IconCircleCheck, IconPhonePlus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import PaymentModalRefactored from '../../../../components/PaymentModalRefactored';
import { useBookingDetails, useSlotList } from '../../../../services/booking';
import { generateInvoices } from '../../../../services/payment.api';
import { useServiceById } from '../../../../services/service';
import { useUserOverview } from '../../../../services/userProfiles';
import { sendNotification } from '../../../../services/websocket.api';
import { covertTimeFormat } from '../../../../utils/helpers';
import { toastMessageUpdate } from '../../../../utils/helpers/tosterMessage';
import PaymentSuccessMoadal from './components/PaymentSuccessMoadal';
import {
  useConfirmBookingScrollAreaStylesIfPaymentModalIsClosed,
  useConfirmBookingScrollAreaStylesIfPaymentModalIsOpened,
} from './style';

export default function ConfirmBooking() {
  const [cookie] = useCookies(['user_Info']);
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [searchParams] = useSearchParams();
  const [slotInfo, setSlotInfo] = useState<Record<string, any> | null>(null);
  const [paymentModalOpened, { open: openPaymentModal, close: closePaymentModal }] = useDisclosure(false);

  const { data, isLoading } = useBookingDetails(searchParams.get('id') || '', true);
  const { data: userData } = useUserOverview(data?.data?.service_from);
  const { data: slotData, isLoading: isSlotLoading } = useSlotList();
  const { data: serviceData } = useServiceById(data?.data?.service_id);
  console.log('bookingservicesData', data);

  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  // handle slot list data and its functionality.
  useEffect(() => {
    if (slotData?.data) {
      const slotIn = slotData.data?.findIndex((item: any) => item?.slot_id === data?.data?.slot_id);
      if (slotIn > -1) {
        setSlotInfo(slotData.data[slotIn]);
      }
    }
  }, [slotData, data]);

  const [paymentIsLoading, setPaymentIsloading] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState(false);
  const [invoiceMessage, setInvoiceMessage] = useState<string>('');

  const sendNotifications = async () => {
    const formData = {
      to_user: data?.data.service_from,
      message: 'Hello, Your service are booked successfully.',
      type: 'service',
    };
    const notificationdata = await sendNotification(formData);
  };

  // booking payment functionality and invoice api call
  const paymentHandler = async () => {
    const formData = {
      currency: '$',
      services: '',
      booking_id: data?.data?.booking_id,
      service_id: data?.data?.service_id,
      service_type_id: data?.data?.service_type_id,
      slot_id: data?.data?.slot_id,
      remarks: '',
    };
    try {
      setPaymentIsloading(true);
      const res: any = await generateInvoices(formData);
      if (res?.success) {
        sendNotifications();
      }
      toastMessageUpdate('success', res?.message);
      setInvoiceStatus(res?.success);
      setInvoiceMessage(res?.message);
      openModal();
      setPaymentIsloading(false);
      closePaymentModal();
      // navigate('/my-bookings');
    } catch (error: any) {
      toastMessageUpdate('error', error?.data?.message);
      setPaymentIsloading(false);
      setInvoiceStatus(false);
    }
  };

  const { classes: confirmBookingScrollAreaClassesIfPaymentModalIsOpened } =
    useConfirmBookingScrollAreaStylesIfPaymentModalIsOpened();

  const { classes: confirmBookingScrollAreaClassesIfPaymentModalIsClosed } =
    useConfirmBookingScrollAreaStylesIfPaymentModalIsClosed();

  return (
    <>
      <Grid m={0} p={0}>
        <Grid.Col md={paymentModalOpened ? 6 : 12}>
          <ScrollArea.Autosize
            offsetScrollbars
            scrollbarSize={2}
            type='scroll'
            classNames={
              paymentModalOpened
                ? confirmBookingScrollAreaClassesIfPaymentModalIsOpened
                : confirmBookingScrollAreaClassesIfPaymentModalIsClosed
            }>
            <Grid p={0} m={0}>
              <Grid.Col md={paymentModalOpened ? 12 : 6}>
                <Paper withBorder shadow='xs' p='xs' h='100%'>
                  <Flex
                    c={data?.data?.invoice_status || invoiceStatus ? 'green.9' : 'yellow.9'}
                    align='center'
                    mt='md'
                    h={60}>
                    <IconCircleCheck size={rem(32)} />
                    <Title mb={-4} ml='xs' order={2}>
                      {data?.data?.invoice_status || invoiceStatus ? 'Payment Completed' : 'Payment Pending'}
                    </Title>
                  </Flex>
                  <Divider my='md' />
                  <Flex align='center'>
                    <Indicator mt={40} inline style={{ borderRadius: '50%' }} withBorder disabled size={40}>
                      <Avatar size={80} radius={100} src={userData?.data?.user_profile?.image} />
                    </Indicator>
                    <Text size='lg' ml='xs'>
                      {userData?.data?.user?.name} assigned.
                    </Text>
                  </Flex>
                  <Divider my='xl'></Divider>
                  <Flex align='flex-end' justify='space-between' mx='xs' mb='xl'>
                    <Flex direction='column'>
                      <Title order={3} mb='xs'>
                        Booking Time
                      </Title>
                      {!isSlotLoading ? (
                        <Text fw={500} size='md' c='dimmed'>
                          {covertTimeFormat(slotInfo?.time_from)} - {covertTimeFormat(slotInfo?.time_to)}{' '}
                          {dayjs(data?.data?.booking_date).format('MMM DD, YYYY')}
                        </Text>
                      ) : (
                        <Skeleton h={15} visible={true} />
                      )}
                    </Flex>
                  </Flex>
                </Paper>
              </Grid.Col>

              <Grid.Col md={paymentModalOpened ? 12 : 6}>
                <Paper withBorder shadow='xs' p='xs' h='100%'>
                  <Flex align='center' mt='md' h={60}>
                    <Title mb={-4} ml='xs' order={2}>
                      Booking Details
                    </Title>
                  </Flex>
                  <Divider my='md' />
                  {serviceData && (
                    <>
                      <Stack justify='space-between'>
                        <Box mx='xs'>
                          <Table>
                            <tbody>
                              <tr>
                                <td>Total</td>
                                <td>${serviceData?.data?.rate * (data?.data?.providers_quantity || 1)}</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Total Amount</th>
                                <th>${serviceData?.data?.rate * (data?.data?.providers_quantity || 1)}</th>
                              </tr>
                            </tfoot>
                          </Table>
                        </Box>

                        <>
                          {!(invoiceStatus || data?.data?.invoice_status) && (
                            <Box mt={64}>
                              <Button
                                loading={paymentIsLoading}
                                fullWidth
                                radius={4}
                                variant='gradient'
                                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                                size='md'
                                onClick={cookie.user_Info?.chargebee_card ? paymentHandler : openPaymentModal}>
                                {!paymentIsLoading && 'Proceed to payment'}
                              </Button>
                            </Box>
                          )}
                        </>
                      </Stack>
                    </>
                  )}
                </Paper>
              </Grid.Col>
              <Grid.Col md={12} mt='lg'>
                <Paper withBorder p='xl' shadow='xs'>
                  <Flex align='center' justify='space-between'>
                    <Text size='lg' fw={500}>
                      Helpline Number
                    </Text>
                    <Flex align='center'>
                      <IconPhonePlus />
                      <Text component='a' href='tel:+18008803896' size='lg' fw={500} c='dimmed' ml='xs'>
                        +1 (800) 880-3896
                      </Text>
                    </Flex>
                  </Flex>
                </Paper>
              </Grid.Col>
            </Grid>
          </ScrollArea.Autosize>
        </Grid.Col>
      </Grid>

      {paymentModalOpened && !isTablet && (
        <PaymentModalRefactored
          forbookingServices={true}
          onPayment={paymentHandler}
          state={paymentModalOpened}
          close={closePaymentModal}
        />
      )}

      {/* <PaymentModal onPayment={paymentHandler} state={paymentModalOpened} close={closePaymentModal} /> */}
      <PaymentSuccessMoadal message={invoiceMessage} success={invoiceStatus} state={modalOpened} close={closeModal} />

      <Drawer
        opened={isTablet && paymentModalOpened}
        onClose={closePaymentModal}
        position='bottom'
        size='90%'
        withCloseButton={false}
        overlayProps={{ opacity: 0.5, blur: 4 }}>
        <UnstyledButton onClick={closePaymentModal} style={{ color: '#888888' }}>
          <IconArrowLeft style={{ width: 20, height: 20 }} />
        </UnstyledButton>
        <PaymentModalRefactored
          forbookingServices={true}
          onPayment={paymentHandler}
          state={paymentModalOpened}
          close={closePaymentModal}
        />
      </Drawer>
    </>
  );
}
