import React, { useEffect, useState } from 'react';
import { Avatar, Button, Flex, Paper, Stack, Text, Title, Grid, Skeleton, Divider } from '@mantine/core';
import RequestCard from './components/RequestCard';
import { useUserOverview } from '../../../services/userProfiles';
import { bookingRequestDetails, useSlotList } from '../../../services/booking';
import { useParams } from 'react-router-dom';
import { useServiceTypeList } from '../../../services/service_types.api';
import { toastMessageUpdate } from '../../../utils/helpers/tosterMessage';
const BookingRequest = () => {
  const [customerId, setCustomerId] = useState('');
  const [requestDetails, setRequestDetails] = useState<any>();
  const { data: customerData, isLoading, refetch } = useUserOverview(customerId);
  const { id } = useParams();
  const fn = (data: any) => console.log(data);
  const { data: serviceType, error } = useServiceTypeList(fn);
  const [serviceInfo, setServiceInfo] = useState<any>();
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    getBookingDetails();
  }, [id, serviceType]);

  // get booking request details and handle the functionality.
  const getBookingDetails = async () => {
    const params = {
      booking_request_id: id,
    };
    try {
      const bookingData = await bookingRequestDetails(params);
      setCustomerId(bookingData?.data?.service_to);
      const serviceDetails = serviceType?.data?.filter(
        (item: any) => item.service_type_id === bookingData?.data?.service_type_id,
      );
      setServiceInfo(serviceDetails);
      setRequestDetails(bookingData.data);
      setShowSkeleton(false);
      refetch();
    } catch (error: any) {
      setShowSkeleton(false);
      toastMessageUpdate('error', error?.data?.error);
    }
  };

  return (
    <>
      <Title order={3}>Booking Requests</Title>
      <Divider my='lg' />
      <Grid gutter='xl' my='xl'>
        {showSkeleton ? (
          <Grid.Col md={10}>
            <Paper withBorder shadow='xs'>
              <Skeleton visible={true} h={135} />
            </Paper>
          </Grid.Col>
        ) : (
          <Grid.Col md={10}>
            {requestDetails && serviceInfo ? (
              <RequestCard
                avatar={customerData?.data?.user_profile?.image}
                userAddress={customerData?.data?.has_address?.address}
                userInfo={customerData?.data?.user}
                bookingRequest={requestDetails}
                serviceInfo={serviceInfo}
              />
            ) : (
              <Title order={3} mb='xs' ml='xs'>
                {' '}
                No Request Found.
              </Title>
            )}
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};

export default BookingRequest;
