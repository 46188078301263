import React, { useContext, useEffect, useState } from 'react';
import { ActionIcon, Anchor, Card, Flex, Group, Paper, Tabs, Text, rem, Box, Menu, createStyles } from '@mantine/core';
import { IconHeart, IconHome, IconMessageDots, IconMessages, IconSearch, IconUser } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { MobileMenuStyle } from './styles';
import { RoleType } from '../../../utils';
import { useCookies } from 'react-cookie';
import { ProfileMenu } from './HeaderTop';
import EndVideoCall from '../../../components/EndVideoCall';
import { useDisclosure } from '@mantine/hooks';
import Notification from './Notification';
import { ThemeContext } from '../../../context';
import MessageNotification from './MessageNotification';

const MobileMenu = () => {
  const navigate = useNavigate();
  const { classes: mobilemenuClasses, cx } = MobileMenuStyle();
  const [cookies, setCookie] = useCookies(['role']);
  const [selected, setSelected] = useState('profiles');
  const [videoCallOn, setVideoCallOn] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const { setChatType, setChannelId } = useContext(ThemeContext);

  // To check any ongoing video call is running or not.
  // If any video call is running then set it on a state.
  const ongoingVideoCall = (params: any) => {
    if (params) {
      setVideoCallOn(params);
    } else {
      setVideoCallOn(false);
    }
  };

  const handleNavigation = (page: string) => {
    // As per page type string, navigate to user specific page.
    if (videoCallOn) {
      open();
      return;
    } else {
      setSelected(page);
      switch (page) {
        // case 'search':
        //   return;
        //   navigate('/');
        //   break;
        case 'favourites':
          navigate('/favourites');
          break;
        case 'message':
          if (location.pathname !== '/chat') {
            setChatType('userChat');
            setChannelId(null);
            navigate('/chat');
          }
          break;
        case 'profile':
          // If user role type is customer or organization service customer then navigate to
          // my-profile page, otherwise navigate to partner/profile page.
          if ([RoleType.customer, RoleType.organization_service_consumer].includes(+cookies.role)) {
            navigate('/my-profile');
          } else {
            navigate('/partner/profile');
          }
          break;
        default:
          navigate('/');
      }
    }
  };

  const [scrollDir, setScrollDir] = useState<'up' | 'down'>('up');

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);
    // console.log(scrollDir);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDir]);

  return (
    <>
      <Paper
        className={cx(mobilemenuClasses.mobileMenu, {
          [mobilemenuClasses.mobileMenuWhileScrollUp]: scrollDir === 'up',
          [mobilemenuClasses.mobileMenuWhileScrollDown]: scrollDir === 'down',
        })}>
        <Flex gap='lg' justify='space-evenly' align='center'>
          <Anchor
            className={mobilemenuClasses.footerCommon}
            onClick={() => {
              handleNavigation('favourites');
            }}
            underline={false}>
            <ActionIcon>
              <IconHeart size={rem(20)} />
            </ActionIcon>
          </Anchor>

          <MessageNotification />

          <Notification />
          <ProfileMenu ongoingVideoCall={ongoingVideoCall} />
        </Flex>
      </Paper>

      <EndVideoCall opened={opened} close={close} />
    </>
  );
};

export default MobileMenu;
