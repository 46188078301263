import { Box, Button, Flex, Text, Title, createStyles, rem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const useContentNotFoundStyles = createStyles((theme) => ({
  container: {
    minHeight: '100svh',
  },
  box: {
    border: '1px dashed #e1e1e1',
    borderRadius: rem(10),
    padding: rem(50),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    userSelect: 'none',
  },
}));

export default function ContentNotFound() {
  const { classes } = useContentNotFoundStyles();
  const navigate = useNavigate();
  return (
    <>
      <Flex className={classes.container} justify='center' align='center'>
        <Box className={classes.box}>
          <Title order={2} ta='center' mb={20}>
            No content found
          </Title>
          <Button onClick={() => navigate('/')}>Take me back to home page</Button>
        </Box>
      </Flex>
    </>
  );
}
