import { createStyles } from '@mantine/core';

export const useIconStyles = createStyles((theme) => ({
  okay: {
    color: theme.colors.green[6],
  },

  cancel: {
    color: theme.colors.red[6],
  },

  edit: {
    color: theme.colors.red[6],
  },
}));

export const useCancelButtonStyles = createStyles((theme) => ({
  root: {
    borderColor: '#e1e1e1',
  },
}));

export const useUpdateNameStyles = createStyles((theme) => ({
  header: {
    fontSize: 20,
  },
}));

export const useUserOverviewStyles = createStyles((theme) => ({
  editButton: {
    position: 'relative',
    top: -4,
    right: -4,

    svg: {
      width: 17,
      height: 17,
      stroke: '#fe7720',
    },

    [theme.fn.smallerThan(769)]: {
      top: -4,
      right: -4,
    },
  },

  flexContainerOne: {
    [theme.fn.smallerThan(577)]: {
      flexDirection: 'column',
    },
  },

  flexContainerTwo: {
    justifyContent: 'center',
  },

  flexContainerThree: {
    flexDirection: 'column',
    marginTop: 16,
    marginLeft: 8,

    [theme.fn.smallerThan(577)]: {
      margin: 0,
      alignItems: 'center',
    },
  },
}));
