import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, Grid, Group, Select, Text, TextInput, Title, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconEdit, IconTrash } from '@tabler/icons-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useForm } from 'react-hook-form';
import { profileImageFile } from '../../../../../assets';
import GlobalLoader from '../../../../../components/GlobalLoader';
import ImageCropper, { OnSelectArgType } from '../../../../../components/ImageCropper';
import ProfilePhoto from '../../../../../components/ProfilePhoto';
import { updateUserProfile, useUserProfile } from '../../../../../services/user.api';
import { createUserOverview, updateUserOverview } from '../../../../../services/userProfiles';
import { RoleType } from '../../../../../utils';
import onlyAllowNumbersInput from '../../../../../utils/helpers/onlyAllowNumbersInput';
import setFileWithValidation from '../../../../../utils/helpers/setFileWithValidation';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { customerProfileSchema, orgCustomerProfileSchema } from '../../../../../utils/schema/formSchema';
import { ValidationPattern } from '../../../../../utils/schema/validationMessage';
import { UserImageType } from '../../../../../utils/types/ImageType';
import Address from '../../../Partner/Profile/components/Address';
import CustomButton from '../../../Partner/Profile/components/CustomButton';
import { useGridColStyle } from '../../../Partner/Profile/components/style';
import ModalOrDrawer from './ModalOrDrawer';
import UpdateNameRefactored from './UpdateNameRefactored';
import { useUserOverviewStyles } from './style';
import { chargebeeUserCreate } from '../../../../../services/payment.api';
import { createSubscription } from '../../../../../services/organization.api';
import { subscriptionLimit, useSubscriptionList } from '../../../../../services/subscription.api';
import { ThemeContext } from '../../../../../context';
import DeleteMyAccount from '../../../Partner/Profile/components/DeleteMyAccount';

export default function UserOverviewRefactored() {
  const { data: userData, isLoading, refetch } = useUserProfile();
  const [cookies, setCookie] = useCookies(['user_Overview', 'user_Info', 'role', 'subscription_status']);
  const [profileNeedsToBe, setProfileNeedsToBe] = useState<'created' | 'updated'>('created');
  const [profileUpdateIsEnabled, setProfileUpdateIsEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [profileImageFileToBeUpdated, setProfileImageFileToBeUpdated] = useState<File | null>(null);
  const [profileImageSourceURL, setProfileImageSourceURL] = useState('');
  const profileImageFileToBeUpdatedResetRef = useRef<() => void>(null);
  const [profileImageUploadIsLoading, setProfileImageUploadIsLoading] = useState(false);

  const [enableAddress, setEnableAddress] = useState(false);
  const [updateNameIsOpened, { open: openUpdateName, close: closeUpdateName }] = useDisclosure(false);
  const [deleteAccountIsOpened, { open: openDeleteAccount, close: closeDeleteAccount }] = useDisclosure(false);

  const [imageCropperOpened, { open: openImageCropper, close: closeImageCropper }] = useDisclosure(false);
  const [imageFileToBeCropped, setImageFileToBeCropped] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(+cookies?.role !== 4 ? customerProfileSchema : orgCustomerProfileSchema),
    mode: 'all',
  });

  //Create for chargbee user
  const [subscriptionLoading, setSubscriptionLoading] = useState<boolean>(false);
  const { data: subscriptionList } = useSubscriptionList(cookies?.user_Info?.type);
  const { setSubscriptionExhausted } = useContext(ThemeContext);

  const createChargebeeUser = async () => {
    setSubscriptionLoading(true);
    try {
      const userParams = {
        first_name: cookies?.user_Info?.name?.split(' ')[0],
        last_name: cookies?.user_Info?.name.split(' ')[1],
      };
      const res = await chargebeeUserCreate(userParams);
      setSubscriptionLoading(false);
    } catch (error: any) {
      console.log(error);
      setSubscriptionLoading(false);
    }
  };
  useEffect(() => {
    if (subscriptionList?.data && !cookies?.user_Info?.chargebee_id) {
      const basicPlan = subscriptionList?.data?.filter((item: any) => +item?.amount === 0);
      if (basicPlan?.length) {
        subscribeBasicPlan(basicPlan);
      }
    }
  }, [subscriptionList]);

  const subscribeBasicPlan = async (basicPlan: any) => {
    setSubscriptionLoading(true);
    try {
      const userParams = {
        first_name: cookies?.user_Info?.name?.split(' ')[0],
        last_name: cookies?.user_Info?.name.split(' ')[1],
      };
      const subscriptionParams = {
        subcription_id: basicPlan[0]?.subcription_id,
      };
      const subFormData = {
        user_id: cookies?.user_Info?.id,
      };
      const res = await chargebeeUserCreate(userParams);
      const profile = await updateUserProfile(subscriptionParams);
      setCookie('user_Info', profile?.data, { path: '/' });
      const subscription = await createSubscription(subFormData);
      const response = await subscriptionLimit();
      setSubscriptionExhausted(response);
      setCookie('subscription_status', false, {
        path: '/',
      });
      setSubscriptionLoading(false);
      // toastMessageUpdate('success', 'Subscribed with basic plan');
    } catch (error: any) {
      toastMessageUpdate('error', error?.data?.message || error?.message || '');
      console.log(error);
      setSubscriptionLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.data?.user_profile?.image) {
      setProfileImageSourceURL(userData?.data?.user_profile?.image);
    } else {
      switch (+cookies.role) {
        case RoleType.customer:
          setProfileImageSourceURL(profileImageFile);
          break;
      }
    }

    reset({
      email: userData?.data?.user?.email,
      phoneNumber: userData?.data?.user?.mobile,
      gender: userData?.data?.user_profile?.gender,
    });

    if (userData?.data?.user_profile) {
      setProfileNeedsToBe('updated');
      setEnableAddress(true);
    } else {
      setProfileNeedsToBe('created');
      setEnableAddress(false);
    }
  }, [userData]);

  const setImageWithValidation = async (file: File | null, type: UserImageType) => {
    const result = setFileWithValidation({
      file: file,
      fileRef: profileImageFileToBeUpdatedResetRef,
      type: 'image',
      maxAllowedFileSize: ValidationPattern.Max_Profile_Image_Size,
    });

    if (result.status === 'success') {
      if (type === 'profile_image') {
        setImageFileToBeCropped(result.validatedFile);
        openImageCropper();
      }
    }
  };

  const handleImageUpload = async (type: UserImageType) => {
    setProfileImageUploadIsLoading(true);
    if (profileNeedsToBe === 'created') {
      toastMessageUpdate('info', 'Please create your profile');
      setProfileImageUploadIsLoading(false);
    } else {
      const fd = new FormData();
      if (profileImageFileToBeUpdated) {
        fd.append('image', profileImageFileToBeUpdated);
        try {
          const updatedProfile = await updateUserOverview(fd);
          setCookie('user_Overview', updatedProfile?.user_profile, { path: '/' });
          setProfileImageFileToBeUpdated(null);
          setProfileImageUploadIsLoading(false);
        } catch (error) {
          console.error(error);
          setProfileImageUploadIsLoading(false);
        }
      } else {
        toastMessageUpdate('info', 'No image to upload');
      }
    }
  };

  const onSubmit = async (data: any) => {
    const previousData = {
      email: userData?.data.user?.email,
      phoneNumber: userData?.data?.user?.mobile,
      gender: userData?.data?.user_profile?.gender,
    };
    // console.log(data);
    // console.log(previousData);

    if (data.phoneNumber === previousData.phoneNumber && data.gender === previousData.gender) {
      toastMessageUpdate('info', 'No need to update');
    } else {
      setLoading(true);
      const fd = new FormData();
      fd.append('mobile', data.phoneNumber);
      if (+cookies.role !== RoleType.organization_service_consumer) {
        fd.append('gender', data.gender);
      }

      if (profileNeedsToBe === 'created') {
        try {
          const createdProfile = await createUserOverview(fd);
          setCookie('user_Overview', createdProfile, { path: '/' });
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      } else if (profileNeedsToBe === 'updated') {
        try {
          const updatedProfile = await updateUserOverview(fd);
          console.log(updatedProfile);
          setProfileUpdateIsEnabled(false);
          setLoading(false);
          refetch();
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    }
  };

  const updateCustomerName = (data: any) => {
    console.log(data);
    refetch();
  };

  const { classes: GridColStyle } = useGridColStyle();
  const { classes, cx } = useUserOverviewStyles();

  if (isLoading) return <GlobalLoader />;

  const handleImageCropperSelect = (data: OnSelectArgType) => {
    if (data.status === 'success') {
      setProfileImageSourceURL(data.croppedImageBlobUrl);
      setProfileImageFileToBeUpdated(data.croppedImageFile);
      closeImageCropper();
    }
  };

  const handleImageCropperCancel = () => {
    closeImageCropper();
    setProfileImageFileToBeUpdated(null);
  };

  return (
    <>
      <Grid>
        <Grid.Col>
          <Flex gap={8} className={classes.flexContainerOne}>
            <Flex className={classes.flexContainerTwo}>
              <ProfilePhoto
                type='renderWithEdit'
                profilePhoto={profileImageFileToBeUpdated}
                resetRef={profileImageFileToBeUpdatedResetRef}
                src={profileImageSourceURL}
                setProfilePhoto={setImageWithValidation}
                uploadProfilePhoto={() => handleImageUpload('profile_image')}
                loading={profileImageUploadIsLoading}
                showGradientBackground
                sizes={{ xs: 145, sm: 150, md: 155, lg: 155, xl: 160 }}
              />
            </Flex>

            <Flex direction='column' className={classes.flexContainerThree}>
              <Flex>
                <Title order={2}>{userData?.data?.user?.name}</Title>
                <UnstyledButton className={cx(classes.editButton)} onClick={openUpdateName}>
                  <IconEdit />
                </UnstyledButton>
              </Flex>
              <Group spacing={2}>
                <Text c='dimmed' fw={600}>
                  Identity Verification:
                </Text>
                <Text
                  c={
                    cookies.user_Info.onfido_result === 'clear'
                      ? 'green'
                      : cookies.user_Info.onfido_check_id
                      ? 'red'
                      : 'dimmed'
                  }>
                  {cookies.user_Info.onfido_result === 'clear'
                    ? 'Verified'
                    : cookies.user_Info.onfido_result === 'consider'
                    ? 'N/A'
                    : cookies.user_Info.onfido_check_id
                    ? 'Pending'
                    : 'N/A'}
                </Text>
              </Group>
            </Flex>
          </Flex>
        </Grid.Col>

        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Grid.Col>
            <Grid>
              <Grid.Col lg={6}>
                <TextInput
                  size='md'
                  disabled
                  {...register('email')}
                  placeholder='Enter email'
                  label='Email'
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col lg={6}>
                <TextInput
                  type='number'
                  onKeyDown={onlyAllowNumbersInput}
                  size='md'
                  disabled={!profileUpdateIsEnabled}
                  {...register('phoneNumber')}
                  placeholder='Enter contact number'
                  label='Contact Number'
                  withAsterisk
                />
              </Grid.Col>
              {+cookies.role !== RoleType.organization_service_consumer && (
                <Grid.Col lg={6}>
                  <Controller
                    control={control}
                    name='gender'
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Gender'
                        size='md'
                        disabled={!profileUpdateIsEnabled}
                        rightSection={<IconChevronDown size='1rem' />}
                        placeholder='Pick one'
                        rightSectionWidth={30}
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        data={['Male', 'Female', 'Others']}
                        withAsterisk
                      />
                    )}
                  />
                </Grid.Col>
              )}
            </Grid>
          </Grid.Col>

          <Grid.Col>
            <Grid>
              {profileUpdateIsEnabled ? (
                <>
                  <Grid.Col sm={1.5} xs={3} className={GridColStyle.gridCol}>
                    <CustomButton variant='gradient' loading={loading} type='submit'>
                      Save
                    </CustomButton>
                  </Grid.Col>

                  {cookies.user_Overview !== 'null' && (
                    <Grid.Col sm={1.5} xs={3} className={GridColStyle.gridCol}>
                      <CustomButton
                        variant='outline'
                        onClick={() => {
                          setProfileUpdateIsEnabled(false);
                        }}>
                        Cancel
                      </CustomButton>
                    </Grid.Col>
                  )}
                </>
              ) : (
                <>
                  <Grid.Col sm={1.5} xs={3} className={GridColStyle.gridCol}>
                    <Button
                      size='sm'
                      radius={4}
                      fullWidth
                      onClick={(e) => {
                        e.preventDefault();
                        setProfileUpdateIsEnabled(true);
                      }}
                      variant='gradient'
                      gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}>
                      Edit
                    </Button>
                  </Grid.Col>
                </>
              )}
            </Grid>
          </Grid.Col>
        </form>

        <Grid.Col>
          <Address enable={enableAddress} />
        </Grid.Col>
      </Grid>

      <Flex justify='flex-end' mt={12}>
        <Button variant='outline' c='red' compact onClick={openDeleteAccount} radius={4}>
          <Group spacing={4}>
            <Text>Delete my account</Text>
            <IconTrash size={18} />
          </Group>
        </Button>
      </Flex>

      <ImageCropper
        opened={imageCropperOpened}
        onClose={closeImageCropper}
        onSelect={handleImageCropperSelect}
        onCancel={handleImageCropperCancel}
        imageFileToBeCropped={imageFileToBeCropped}
      />

      <ModalOrDrawer
        opened={updateNameIsOpened}
        onClose={closeUpdateName}
        drawerProps={{ dz: '250', drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <UpdateNameRefactored close={closeUpdateName} updateUserName={updateCustomerName} />
      </ModalOrDrawer>

      <ModalOrDrawer
        opened={deleteAccountIsOpened}
        onClose={closeDeleteAccount}
        closeOnClickOutside={true}
        drawerProps={{ dz: '250', drawerBackButton: true }}
        modalProps={{ modalCloseButton: true }}>
        <DeleteMyAccount closeDeleteAccount={closeDeleteAccount} />
      </ModalOrDrawer>
    </>
  );
}
