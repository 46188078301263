import styled from '@emotion/styled';
import { createStyles, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

// const mantinetheme = useMantineTheme();
// const laptop = useMediaQuery(`(max-width: ${mantinetheme.breakpoints.lg})`);

export const useChatCardStyles = createStyles((theme) => ({
  card: {
    userSelect: 'none',

    '&:hover': {
      border: `0.1em solid ${theme.colors.orange[8]}`,
      cursor: 'pointer',
      backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
      transition: 'all 0.4s',
    },
  },

  active: {
    userSelect: 'none',
    cursor: 'pointer',
    // transform: 'scale(1.05)',
    border: `0.2em solid ${theme.colors.orange[8]}`,
    transition: 'all 0.2s',
    background: 'red',
    boxShadow: `0px 0px 6px 1px ${theme.colors.orange[8]}`,

    '&:hover': {
      border: `0.12em solid ${theme.colors.orange[8]}`,
      cursor: 'pointer',
      // backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],
      transition: 'all 0.4s',
    },
  },

  mobileHeaderBox: {
    border: `1px solid #ececec`,
    borderRadius: `10px`,
    padding: `10px 10px 0px 10px`,
    backgroundColor: theme.colors.orange[0],
  },

  messageBox: {
    border: '1px solid #ced4da',
    borderRadius: '5px',
  },
}));

export const useAvatarStyles = createStyles((theme) => ({
  root: {
    borderRadius: `50%`,
    width: theme.breakpoints.xs ? `2.5rem` : theme.breakpoints.md ? `5.3rem` : `7em`,
    height: theme.breakpoints.xs ? `2.5rem` : `5.3rem`,
  },
  placeholderIcon: {
    height: '1.4rem !important',
    width: '1.4rem !important',
  },
}));

export const useChatStyles = createStyles(() => ({
  messageBox: {
    marginRight: '-18px',
  },
  rightSection: {
    right: '17px',
  },
}));

export const useScrollStyles = createStyles((theme) => ({
  scrollbar: {
    height: `0rem`,
  },
}));

export const MessageListScroll = styled.div(({ theme }) => ({
  overflowY: 'auto',
  height: window.innerHeight - 350,
  '&::-webkit-scrollbar': {
    width: 2,
  },
  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
  },
}));
