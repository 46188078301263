import { catchHandler } from '../utils/helpers';
import { axiosTokenInst } from './axios.config';

export const chargebeeUserCreate = catchHandler((formData: any) => {
  return axiosTokenInst.post(`chargebee/create-user`, formData);
});

export const generateInvoices = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`/invoices`, formData);
});

export const updateChargebeeUserCard = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`chargebee/update-user-cards`, formData);
});

export const chargebeeSubscriptionUpdate = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`chargebee/update-subscription`, formData);
});

export const generateVerificationInvoices = catchHandler(async (formData: any) => {
  return await axiosTokenInst.post(`/invoices/verification-invoices`, formData);
});
