import { useState, useEffect, useContext } from 'react';
import {
  Button,
  Modal,
  Stack,
  TextInput,
  Textarea,
  FileButton,
  Group,
  Text,
  rem,
  Select,
  Avatar,
  Title,
  Box,
  Drawer,
} from '@mantine/core';
import { IconChevronDown, IconCurrencyDollar, IconUpload } from '@tabler/icons-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { serviceUpdateSchema } from '../../../../../utils/schema/formSchema';
import { FileInputStyle, TextInputStyle, uploadButtonStyle } from '../../Profile/components/style';
import { ServiceContext } from '../servicesContext';
import { toastMessageUpdate } from '../../../../../utils/helpers/tosterMessage';
import { createService, deleteService, updateService } from '../../../../../services/service';
import { HeaderAvatarIcon } from '../../../../../layouts/authLayout/component/styles';
import { useMediaQuery } from '@mantine/hooks';
import { useCancelButtonStyles } from '../../../customer/Profile/components/style';

// { serviceId, serviceTypeId, serviceRate, description }: any
const DeleteModal = ({ setUserServices }: any) => {
  const { close, opened, selectedService, buttonType, deleteClose, deleteOpened } = useContext(ServiceContext);
  const { classes: fileInputClass } = FileInputStyle();
  const { classes: textInputClass } = TextInputStyle();
  const { classes: uploadClass } = uploadButtonStyle();
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(serviceUpdateSchema),
    mode: 'all',
  });
  const [selectedServiceInfo, setSelectedServiceInfo] = useState<any>(selectedService);
  const { classes: AvatarStyle } = HeaderAvatarIcon();
  const [createdServiceData, setCreatedServiceData] = useState<any>();

  const { classes: CancelButtonStyle } = useCancelButtonStyles();

  useEffect(() => {
    setSelectedServiceInfo(selectedService);
  }, [selectedService]);

  useEffect(() => {
    console.log(selectedServiceInfo, createdServiceData);
    reset();
    setFile(selectedServiceInfo?.service_type_id || '');
    reset({
      description: selectedServiceInfo?.description,
      rate_per_hour: selectedServiceInfo?.rate,
      service_type_id: selectedServiceInfo?.service_type_id,
      serviceTitle:
        selectedServiceInfo?.has_service_type?.service ||
        selectedServiceInfo?.service ||
        createdServiceData?.serviceTitle,
    });
  }, [selectedServiceInfo]);

  const submitServiceHandler = async (data: any) => {
    setLoading(true);
    const serviceData: [string, any] = {
      ...data,
      image: file || '',
      completion_time: '',
      cover_image: '',
      service_id: selectedServiceInfo?.service_id || null,
      rate_per_hour: data.rate_per_hour,
      description: data.description,
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(serviceData)) {
      formData.append(key, value);
    }

    if (selectedServiceInfo?.service_id) {
      try {
        const updateServiceData = await updateService(formData);
        toastMessageUpdate('success', 'Service updated successfully.');
        setUserServices((prev: any) => {
          if (prev.length) {
            prev.map((item: any, index: number) => {
              if (item.service_id === updateServiceData.data?.service_id) {
                item.rate = +updateServiceData.data?.rate;
                item.description = updateServiceData.data?.description;
                item.image = updateServiceData.data?.image;
              }
              return item;
            });
          }
          return prev;
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      try {
        const addServiceData = await createService(serviceData);
        // serviceResponse(addServiceData.data);
        setCreatedServiceData(serviceData);
        setUserServices((prev: any) => [...prev, addServiceData.data]);
        toastMessageUpdate('success', 'Service created successfully.');
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    close();
  };

  const deleteServiceHandler = async () => {
    setLoading(true);
    try {
      if (selectedServiceInfo?.service_id) {
        await deleteService(selectedServiceInfo.service_id);
        toastMessageUpdate('success', 'Service deleted successfully.');
        setUserServices((prev: any) => prev.filter((item: any) => item.service_id !== selectedServiceInfo.service_id));
        setLoading(false);
        deleteClose();
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      toastMessageUpdate('error', error?.data?.message || error.statusText || 'Something went wrong.');
      deleteClose();
    }
  };

  const isMobile = useMediaQuery('(max-width: 576px)');

  return (
    <>
      {/* {buttonType === 'delete' && (
        <Modal opened={deleteOpened} size='md' centered onClose={deleteClose} closeOnClickOutside={false}>
          <Box py={20}>
            <Title order={2} ta='center'>
              Delete service
            </Title>
            <Text ta='center' size='md' mt='lg'>
              Are you sure you want to delete this service?
            </Text>
            <Group pt={20} spacing={5} position='center'>
              <Button
                loading={loading}
                w={isMobile ? `49%` : 150}
                size='md'
                radius={4}
                variant='gradient'
                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                onClick={deleteServiceHandler}>
                {!loading && 'Yes'}
              </Button>
              <Button
                disabled={loading}
                size='md'
                variant='light'
                className={CancelButtonStyle.root}
                w={isMobile ? `49%` : 150}
                radius={4}
                onClick={deleteClose}>
                No
              </Button>
            </Group>
          </Box>
        </Modal>
      )} */}

      {buttonType === 'delete' && isMobile ? (
        <Drawer
          opened={deleteOpened}
          onClose={deleteClose}
          size='30%'
          position='bottom'
          overlayProps={{ opacity: 0.5, blur: 4 }}>
          <Box py={20}>
            <Title order={2} ta='center'>
              Delete service
            </Title>
            <Text ta='center' size='md' mt='lg'>
              Are you sure you want to delete this service?
            </Text>
            <Group pt={20} spacing={5} position='center'>
              <Button
                loading={loading}
                w={isMobile ? `49%` : 150}
                size='md'
                radius={4}
                variant='gradient'
                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                onClick={deleteServiceHandler}>
                {!loading && 'Yes'}
              </Button>
              <Button
                disabled={loading}
                size='md'
                variant='light'
                className={CancelButtonStyle.root}
                w={isMobile ? `49%` : 150}
                radius={4}
                onClick={deleteClose}>
                No
              </Button>
            </Group>
          </Box>
        </Drawer>
      ) : (
        <Modal
          closeOnClickOutside={false}
          closeOnEscape={false}
          opened={deleteOpened}
          size='md'
          centered
          onClose={deleteClose}>
          <Box py={20}>
            <Title order={2} ta='center'>
              Delete service
            </Title>
            <Text ta='center' size='md' mt='lg'>
              Are you sure you want to delete this service?
            </Text>
            <Group pt={20} spacing={5} position='center'>
              <Button
                loading={loading}
                w={isMobile ? `49%` : 150}
                size='md'
                radius={4}
                variant='gradient'
                gradient={{ to: '#fda223', from: '#fe7720', deg: 270 }}
                onClick={deleteServiceHandler}>
                {!loading && 'Yes'}
              </Button>
              <Button
                disabled={loading}
                size='md'
                variant='light'
                className={CancelButtonStyle.root}
                w={isMobile ? `49%` : 150}
                radius={4}
                onClick={deleteClose}>
                No
              </Button>
            </Group>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default DeleteModal;
