import { useQuery } from '@tanstack/react-query';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';
import { axiosTokenInst } from './axios.config';
import { catchHandler } from '../utils/helpers';

export const userProfile = catchHandler(async () => {
  return await axiosTokenInst.get('/user');
});

export const useUserProfile = () => {
  return useQuery({
    queryKey: ['user-info'],
    queryFn: userProfile,
  });
};

export const similarProfile = async (formData: any) => {
  return await axiosTokenInst.get(`/profiles/list`, {
    params: formData,
  });
};

export const useUserInfo = () => {
  return useQuery({
    queryKey: ['userInf'],
    queryFn: userProfile,
    onError: (error: any) => {
      toastMessageUpdate('error', error?.response?.data?.error || '');
      return error;
    },
  });
};

export const useSimilarProfile = (data: Record<string, any>) => {
  return useQuery({
    queryKey: ['similar-profile', data['service_type_id']],
    queryFn: async () => {
      return await similarProfile(data);
    },
  });
};

export const updateUserProfile = async (userDetails: any) => {
  try {
    return await axiosTokenInst.post('/user/basic-info', userDetails);
  } catch (error: any) {
    toastMessageUpdate('error', error?.message);
    throw error;
  }
};

export const deleteUserProfile = catchHandler(async () => {
  return await axiosTokenInst.delete('/user/delete-account');
});
