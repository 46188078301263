import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { toastMessageUpdate } from '../utils/helpers/tosterMessage';
interface ILocation {
  country_id?: number;
  state_id?: string;
}
export const locationList = async (data?: ILocation) => {
  return await axios.post(`https://ao0fu32m6c.execute-api.me-south-1.amazonaws.com/stage/user/city/list`, data);
};

export const useLocationList = (data: any, enable = true) => {
  return useQuery({
    queryKey: ['location-list', data],
    enabled: enable,
    queryFn: async () => {
      const resData = await locationList(data);
      // console.log('resData', resData);
      if (resData.data?.data?.state?.length) {
        resData.data.data.state = resData.data.data.state.map((item: any) => {
          return {
            ...item,
            label: item?.state_name,
            value: `${item?.state_id}`,
          };
        });
      }
      if (resData.data?.data?.city?.length) {
        resData.data.data.city = resData.data.data.city.map((item: any) => {
          return {
            ...item,
            label: item?.city_name,
            value: `${item?.city_name}`,
          };
        });
      }
      return resData;
    },
  });
};

export const stateList = async () => {
  const response = await axios.get(`/location.json`);
  response.data.label = response.data.iso2;
  response.data.value = response.data.id;
  return response;
};

export const useStateList = (enable = true) => {
  return useQuery({
    queryKey: ['state-list'],
    enabled: enable,
    queryFn: async () => {
      const resStateData = await stateList();
      console.log('stateData', resStateData.data);
      if (resStateData.data?.states?.length) {
        resStateData.data.states.map((item: any, index: number) => {
          item.label = item.name;
          item.value = item.state_code;
          if (item?.cities?.length) {
            item.cities?.map((city: any) => {
              city.label = city.name;
              city.value = city.name;
            });
          }
          return item;
        });
      }

      return resStateData;
    },
  });
};
