import { createStyles, rem } from '@mantine/core';

export const useFreelanceStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  footer: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    // borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
    marginTop: 'auto',
  },
  startIcon: {
    color: theme.fn.primaryColor(),
  },
  button: {
    borderColor: theme.colors.orange[6],
    backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[6],

    '&:not(:first-of-type)': {
      border: '1px solid black',
    },
  },

  avatar_button: {
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

export const useSearchButtonStyles = createStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#fda223',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&[data-disabled]': {
      backgroundColor: 'transparent',
      cursor: 'not-allowed',
      pointerEvents: 'all',
      color: theme.colorScheme === 'light' ? '#cfcccc' : '#534e4e',
    },
  },
}));

export const useSearchFilterStyle = createStyles((theme) => ({
  label: {
    fontSize: '0.875rem',
    color: theme.colorScheme === 'light' ? '#212529' : '#ced4da',
    fontWeight: 700,
  },
}));

export const useListCardRefactoredStyles = createStyles((theme) => ({
  avatar_group: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '5px',
    '@media (max-width: 1440px)': {
      whiteSpace: 'nowrap',
      marginRight: '15px',
    },
  },

  avatar_flex: {
    justifyContent: 'space-between',
  },

  km_text: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  avatar_button: {
    alignItems: 'center',
    '@media (max-width: 750px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  button_profile: {
    borderColor: theme.colors.orange[7],
    backgroundColor: theme.colorScheme === 'light' ? 'white' : theme.colors.dark[5],

    '@media (max-width: 375px)': {
      fontSize: 11,
      padding: 0,
      width: `80px`,
    },
  },

  booking_class: {
    flexDirection: 'column',
  },

  booking_button: {
    color: '#b0b3b7',
    backgroundColor: '#0000000a',
  },

  booking_text: {
    fontSize: '17px',
  },

  flexContainerOne: {
    flexDirection: 'column',

    '.routeIcon': {
      width: 16,
      height: 16,
      stroke: '#767676',
    },

    '.currencyIcon': {
      width: 19,
      height: 19,
      stroke: '#767676',
    },

    '.distanceText': {
      fontSize: 14,
      color: theme.colorScheme === 'light' ? '#333333' : '#c3c3c3',
    },

    '.priceRateText': {
      fontSize: 16,
      color: theme.colorScheme === 'light' ? '#333333' : '#c3c3c3',
    },

    '.profileTypeText': {
      color: theme.colorScheme === 'light' ? '#333333' : '#c3c3c3',
    },
  },
}));

export const useGridCardStyles = createStyles((theme) => ({
  flexContainerOne: {
    flexDirection: 'column',

    '.routeIcon': {
      width: 16,
      height: 16,
      stroke: '#767676',
    },

    '.currencyIcon': {
      width: 19,
      height: 19,
      stroke: '#767676',
    },

    '.distanceText': {
      fontSize: 14,
      color: theme.colorScheme === 'light' ? '#333333' : '#c3c3c3',
    },

    '.priceRateText': {
      fontSize: 16,
      color: theme.colorScheme === 'light' ? '#333333' : '#c3c3c3',
    },

    '.profileTypeText': {
      color: theme.colorScheme === 'light' ? '#333333' : '#c3c3c3',
    },
  },
}));
